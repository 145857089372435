// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: [
        'flexCommonClass',
        'headerWrapper',
        'primaryNavigationListitem',
        'secondaryNavigationListItemTitleLinkWrapper',
        'countryWrapperInner',
        'currentCountry',
        'countryItemLinkWrapper',
        'countryItemLink',
      ],
      class: ['flex', 'flex-row'],
    },
  ],
  slots: {
    base: ['bg-components-header-color-bg', 'block', 'component header', 'w-full', 'md:hidden'],
    headerWrapper: [
      'header-wrapper',
      'relative',
      'pt-components-header-spacing-small-padding-top',
      'pb-components-header-spacing-small-padding-bottom',
      'pl-components-header-spacing-small-padding-left',
      'pr-components-header-spacing-small-padding-right',
      'justify-between',
      'border-components-header-color-bottom-stroke',
      'border-b',
    ],
    mobileHeaderLeft: ['mobile-header-left', 'flex', 'flex-row'],
    logoWrapper: ['logo-wrapper'],
    logo: ['logo-mobile', 'w-full', 'mr-margin-large-4'],
    mobileHeaderRight: ['mobile-header-right', 'flex', 'flex-row', 'gap-4', 'items-center'],
    searchWrapper: ['search-wrapper'],
    searchForm: ['w-full', 'flex', 'justify-between', 'items-start'],
    searchFormInput: [
      'hidden',
      'font-semibold',
      'text-heading-medium-xxsmall',
      'font-brand-1-600',
      'navlink',
      'placeholder-blue-700',
      'leading-[18px]',
      'font-semibold',
      'hover:text-blue-950',
      'mb-margin-large-1',
    ],
    searchFormButton: [
      'basicFocus',
      'duration-300',
      'ease-in-out',
      'font-medium',
      'h-[40px]',
      'mt-[-3px]',
      'text-white',
      'text-xl',
      'w-[40px]',
    ],
    searchFormButtonIcon: [
      'fill-components-header-color-icon-icon-default',
      '[&>svg]:h-[40px]',
      '[&>svg]:w-[40px]',
    ],
    hamburgerWrapper: ['flex', 'h-[40px]', 'w-[40px]', 'relative'],
    toggleMenuWrapper: ['toggle-menu', 'flex', 'justify-center', 'items-center'],
    toggleMenuCheckbox: [
      'checkbox',
      'cursor-pointer',
      'block',
      'h-[40px]',
      'opacity-0',
      'relative',
      'right-0',
      'w-[40px]',
      'z-20',
    ],
    hamburgerLinesWrapper: [
      'hamburger-lines',
      'h-[20px]',
      'absolute',
      'flex-col',
      'flex',
      'justify-between',
      'right-0',
      'w-[30px]',
      'z-10',
    ],
    hamburgerLine1: [
      'bg-components-header-color-icon-icon-default',
      'block',
      'duration-300',
      'ease-in-out',
      'h-[3px]',
      'line',
      'line1',
      'origin-[0%_0%]',
      'w-full',
    ],
    hamburgerLine2: [
      'bg-components-header-color-icon-icon-default',
      'block',
      'duration-300',
      'ease-in-out',
      'h-[3px]',
      'line',
      'line2',
      'w-full',
    ],
    hamburgerLine3: [
      'bg-components-header-color-icon-icon-default',
      'block',
      'duration-300',
      'ease-in-out',
      'h-[3px]',
      'line',
      'line3',
      'origin-[0%_100%]',
      'w-full',
    ],
    mobileNavigation: ['mobile-navigation'],
    navigation: ['navigation'],
    navigationInner: ['navigation-inner'],
    primaryNavigation: ['primary-navigation', 'flex', 'flex-col'],
    primaryNavigationListitemWrapper: ['primary-navigation-listitem'],
    primaryNavigationListitem: [
      'justify-between',
      'items-center',
      'py-components-header-spacing-small-nav-main-padding-y',
      'pl-components-header-spacing-small-nav-main-padding-left',
      'pr-components-header-spacing-small-nav-main-padding-right',
      'border-components-header-color-bottom-stroke',
      'border-b',
      'min-h-components-header-spacing-small-nav-main-minheight',
    ],
    primaryNavigationListItemLink: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'navlink',
      'text-components-header-color-links-nav-main-link-default',
      'hover:text-components-header-color-links-nav-main-link-hover',
    ],
    primaryNavigationListItemLinkActive: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'navlink',
      '!text-components-header-color-links-nav-main-link-active',
      'hover:text-components-header-color-links-nav-main-link-hover',
    ],
    primaryNavigationListItemIcon: ['fill-components-header-color-links-nav-main-link-default'],
    primaryNavigationListItemIconActive: [
      'fill-components-header-color-links-nav-main-link-pressed',
    ],
    secondaryNavigationWrapper: [
      'secondary-navigation-wrapper',
      'top-full',
      'flex',
      'flex-col',
      'z-10',
      'bg-brand-1-100',
      'w-full',
      'justify-between',
    ],
    secondaryNavigation: [
      'secondary-navigation',
      'flex',
      'flex-col',
      'bg-components-header-color-mobile-mobile-expanded-bg',
    ],
    secondaryNavigationListItemTitleLinkWrapper: [
      'justify-between',
      'items-center',
      'py-components-header-spacing-small-nav-main-padding-y',
      'pl-components-header-spacing-small-nav-category-padding-left',
      'pr-components-header-spacing-small-nav-category-padding-right',
      'min-h-components-header-spacing-small-nav-main-minheight',
    ],
    secondaryNavigationListItemTitleLinkActive: [
      'block',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-header-color-links-nav-category-link-category-active',
      'hover:text-components-header-color-links-nav-category-link-category-hover',
    ],
    secondaryNavigationListItemTitleLink: [
      'block',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-header-color-links-nav-category-link-category-default',
      'hover:text-components-header-color-links-nav-category-link-category-hover',
    ],
    secondaryNavigationListItemLinkIconActive: ['fill-components-header-color-icon-icon-pressed'],
    secondaryNavigationListItemLinkIcon: ['fill-components-header-color-icon-icon-default'],
    secondaryNavigationItemsWrapper: ['secondary-navigation-items-wrapper', 'flex', 'flex-col'],
    secondaryNavigationItem: [
      'py-components-header-spacing-small-nav-main-padding-y',
      'pl-components-header-spacing-small-nav-menu-link-padding-left',
      'pr-components-header-spacing-small-nav-menu-link-padding-right',
      'min-h-components-header-spacing-small-nav-main-minheight',
    ],
    secondaryNavigationItemLink: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'text-components-header-color-links-nav-main-link-default',
      'hover:text-text-components-header-color-links-nav-main-link-hover',
    ],
    featuredContentWrapper: [
      'featured-content-wrapper',
      'bg-components-header-color-mega-menu-featured-bg',
      'px-components-header-spacing-small-mega-menu-featured-padding-x',
      'py-components-header-spacing-small-mega-menu-featured-padding-y',
      'w-auto',
      'md:w-[444px]',
    ],
    featuredContentTitleWrapper: ['featured-content-title-wrapper'],
    featuredContentTitle: [
      'text-header-large-xSmall',
      'leading-header-large-xSmall',
      'font-header-large-xSmall',
      'text-components-header-color-mega-menu-featured-title',
    ],
    featuredContentImageWrapper: [
      'featured-content-image',
      'object-cover',
      'overflow-hidden',
      'rounded-themes-spacing-radius-small-image',
      'h-auto',
      'w-full',
      'md:w-[380px]',
      'relative',
      'my-components-header-spacing-small-mega-menu-featured-image-margin-bottom',
    ],
    featuredContentImage: ['h-full', 'w-full', 'object-cover'],
    featuredContentLinksWrapper: ['featured-content-links'],
    featuredContentLinks: ['featured-links'],
    featuredContentLinkItem: [
      'font-bodySans-medium-semibold',
      'mr-margin-medium-4',
      'inline-block',
    ],
    countryWrapper: ['country-wrapper', 'bg-components-header-color-mega-menu-bg'],
    countryWrapperInner: [
      'country-wrapper-inner',
      'cursor-pointer',
      'w-full',
      'justify-between',
      'py-components-header-spacing-small-language-selector-padding-y',
      'px-components-header-spacing-small-language-selector-padding-x',
    ],
    currentCountry: ['current-country'],
    currentCountryInner: [
      'flex',
      'flex-row',
      'items-start',
      'gap-components-header-spacing-large-language-selector-space-between',
    ],
    flagIconWrapper: ['flag-icon'],
    logoPlaceholder: [
      'logo-placeholder',
      'w-[18px]',
      'h-[13px]',
      'bg-components-header-color-mega-menu-featured-bg',
    ],
    countryInformation: [
      'country-information',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
      'items-start',
    ],
    currentCountryName: [
      'current-country-name',
      'text-components-header-color-language-selector-country',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
    ],
    currentCountryLanguage: [
      'current-country-language',
      'text-components-header-color-language-selector-language',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
    ],
    countryDropDownIcon: ['fill-components-header-color-links-nav-main-link-default'],
    countryListWrapper: [
      'country-list-wrapper',
      'top-full',
      'bg-components-header-color-mega-menu-bg w-full',
    ],
    countryListInnerWrapper: ['country-list-inner-wrapper', 'p-spacing-padding-large-2'],
    countryListTop: ['country-list-top'],
    countryListHeading: [
      'country-list-heading',
      'text-components-header-color-mega-menu-category-title',
      'font-header-small-xSmall',
      'text-header-small-xSmall',
      'leading-header-small-xSmall',
      'mb-components-header-spacing-small-mega-menu-title-margin-bottom',
    ],
    countryListBottom: ['country-list-bottom'],
    countryListCols: ['country-list-cols'],
    countryListCol: ['country-list-col'],
    countryName: [
      'country-name',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-header-color-links-nav-category-link-category-default',
      'mb-components-header-spacing-large-mega-menu-category-link-margin-bottom',
    ],
    countryList: ['country-list'],
    countryItemLinkWrapper: [
      'country-list-item',
      'group',
      'px-spacing-padding-large-2',
      'py-spacing-padding-large-1',
      'mb-components-header-spacing-large-mega-menu-link-margin-bottom',
      'rounded-components-header-spacing-large-language-selector-radius',
      'cursor-pointer',
      'border',
      'hover:bg-components-header-color-language-selector-bg',
      'hover:border-components-header-color-language-selector-stroke',
      'border-transparent',
    ],
    countryItemLink: ['items-center', 'justify-between', 'w-full'],
    countryListItemLinkleft: ['country-list-item-link-left', 'flex', 'flex-row', 'items-start'],
    countryListItemLinkleftInfo: [
      'country-info',
      'ml-components-header-spacing-large-language-selector-space-between',
      'flex',
      'flex-col',
      'gap-components-header-spacing-large-language-selector-country-space-between',
    ],
    countryListItemLinkleftInfoTitle: [
      'text-components-header-color-language-selector-country',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
    ],
    countryListItemLinkleftInfoSubTitle: [
      'text-components-header-color-language-selector-language',
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
    ],
    countryListRight: ['country-list-right'],
    countrySelection: ['country-selection'],
    countryListRightIcon: [
      'fill-colors-grayscale-400',
      '[&_svg]:group-hover:opacity-100',
      '[&_svg]:opacity-0',
    ],
    flexCommonClass: [],
  },
  variants: {
    toggleMobileMenu: {
      true: {
        base: ['overflow-y-scroll', 'fixed', 'left-0', 'right-0', 'top-0', 'bottom-0', 'z-50'],
        searchFormButton: ['opacity-0', 'pointer-events-none'],
      },
      false: {
        searchFormButton: ['opacity-100'],
      },
    },
    showCountryBg: {
      true: {
        countryItemLinkWrapper: ['bg-components-header-color-language-selector-bg'],
      },
      false: {
        countryItemLinkWrapper: [],
      },
    },
    languageLength: {
      true: {
        countryWrapperInner: ['cursor-pointer'],
      },
      false: {
        countryWrapperInner: [
          '!cursor-default',
          '!justify-start',
          'gap-components-header-spacing-small-language-selector-space-between',
        ],
      },
    },
    isActive: {
      false: {},
      true: {
        primaryNavigationListItemLink: [
          'isActive',
          'relative',
          'pb-2',
          '!text-components-header-color-links-nav-main-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        primaryNavigationListItemLinkActive: [
          'isActive',
          'relative',
          'pb-2',
          '!text-components-header-color-links-nav-main-link-active',
          'before:absolute',
          'before:bg-components-header-color-links-nav-main-active-bar',
          'before:bottom-0.5',
          'before:h-0.5',
          'before:left-0',
          'before:w-full',
        ],
        primaryNavigationListItemIcon: [
          '[&>svg]:!fill-components-header-color-links-nav-main-link-active',
        ],
      },
    },
    brand: {
      ...themeVariants,
      Pyrel: {
        logoWrapper: ['w-[72px]', 'h-[73px]'],
      },
      Duck: {
        logo: ['absolute', 'top-[8px]', '!w-[97px]'],
      },
    },
  },
});
