// Global
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide } from '@splidejs/react-splide';
import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Carousel from 'components/authorable/Carousel/Carousel';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Label from 'helpers/Label/Label';
import SVG from 'helpers/SVG/SVG';
import { stripHtml } from 'lib/utils/regex';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import fallback from 'lib/fallback/fallback';
import {
  tailwindVariantsArticleCard,
  tailwindVariantsArticleSingleCard,
} from './ArticleCardTailwind';

export type ArticleCard = ItemEx & CardComponents.ArticleCard.ArticleCardItem & NumOfCards;

interface NumOfCards {
  numOfCards: number | undefined;
}

export type ArticleCardProps = ComponentProps & CardComponents.ArticleCard.ArticleCardsList;

type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;

type CardOrientation = 'TwoColumns' | 'ThreeColumns' | undefined;

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const ArticleSingleCard = (props: ArticleCard): JSX.Element => {
  const { themeName } = useTheme();
  const {
    card,
    cardWrapper,
    cardHead,
    cardBody,
    cardContentWrapper,
    labelContainer,
    labelbg,
    cardTitleText,
    cardSubtitleText,
    cardDescriptionText,
    cardCtaWrapper,
    cardPrimaryCTA,
    cardSecondaryCTA,
    cardMedia,
  } = tailwindVariantsArticleSingleCard({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const {
    description,
    image,
    primaryCTA,
    secondaryCTA,
    subTitle,
    headline,
    label,
    primaryCTAColor,
    primaryCTAType,
    secondaryCTAColor,
    secondaryCTAType,
  } = props?.fields || {};

  const { id, name } = props || {};

  const labelLink = label?.fields?.link;
  const i18n = useI18n();
  const About = i18n.t('AccessibilityAbout') ? i18n.t('AccessibilityAbout') : 'about';
  const ariaLabel = stripHtml(headline?.value as string);

  const generateButtonTitle = (text: string, title?: string) =>
    title || `${text} ${About} ${ariaLabel}`;

  return (
    <React.Fragment>
      <div className={cardWrapper()} data-component="authorable/cards/articlecard">
        <div className={card()}>
          {image?.value?.src && (
            <div className={cardHead()}>
              <ImageWrapper className={cardMedia()} field={image} layout="intrinsic" />
            </div>
          )}
          <div className={cardBody()}>
            <div className={cardContentWrapper()}>
              {labelLink?.value?.href && (
                <Label
                  className={labelContainer()}
                  color={'white'}
                  backgroundColor={labelbg()}
                  link={label?.fields?.link as LinkField}
                />
              )}
              {headline?.value && (
                <div className={cardTitleText()}>
                  <Text encode={false} field={headline} tag="h2" />
                </div>
              )}
              {subTitle?.value && (
                <div className={cardSubtitleText()}>
                  <Text encode={false} field={subTitle} tag="span" />
                </div>
              )}
              {description?.value && (
                <div className={cardDescriptionText()}>
                  <RichTextA11yWrapper field={description} />
                </div>
              )}
            </div>
            <div className={cardCtaWrapper()}>
              {primaryCTA?.value?.text && (
                <div className={cardPrimaryCTA()}>
                  <Button
                    srOnlyText={stripHtml(headline?.value as string)}
                    href={primaryCTA?.value?.href}
                    target={primaryCTA?.value?.target}
                    label={primaryCTA?.value?.text}
                    title={generateButtonTitle(primaryCTA?.value?.text, primaryCTA?.value?.title)}
                    tag="a"
                    fullWidth
                    // As the design requires a filled CTA, we do not need to add a fallback since
                    // the button component has a default variant that displays a filled CTA
                    type={primaryCTAType?.value as string}
                    color={primaryCTAColor?.value}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'primary',
                      'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': id,
                      'gtm.element.dataset.gtmDatasourceName': name,
                    }}
                  />
                </div>
              )}
              {secondaryCTA?.value?.text && (
                <div className={cardSecondaryCTA()}>
                  <Button
                    href={secondaryCTA?.value?.href}
                    target={secondaryCTA?.value?.target}
                    label={secondaryCTA?.value?.text}
                    title={generateButtonTitle(
                      secondaryCTA?.value?.text,
                      secondaryCTA?.value?.title
                    )}
                    tag="a"
                    fullWidth
                    // The design requires an outline CTA, so for that we have added a fallback as an outline value,
                    // so if there is no value in sitecore field, it will take the outline value.
                    type={secondaryCTAType?.value || fallbackComponentVariantType}
                    color={secondaryCTAColor?.value || fallbackComponentVariantColor}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'secondary',
                      'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': id,
                      'gtm.element.dataset.gtmDatasourceName': name,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ArticleCard = (props: ArticleCardProps): JSX.Element => {
  const { componentName, dataSource } = props?.rendering || {};
  const {
    articleCards,
    description,
    primaryCTA,
    title,
    enablePattern,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};
  const { alignCTA, autoPlay } = props?.params || {};

  const isBrowser = typeof window !== 'undefined';

  const [breakpoints, setBreakpoints] = useState('xs');
  const [windowSize, setWindowSize] = useState({
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0,
  });

  const { themeName } = useTheme();

  useEffect(() => {
    if (!isBrowser) {
      return; // Exit if it's not running in the browser
    }

    // Handle window resize
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Listen Event
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    if (isBrowser) {
      if (windowSize.width >= 1440) {
        setBreakpoints('lg');
      } else if (windowSize.width >= 996) {
        setBreakpoints('md');
      } else if (windowSize.width >= 320) {
        setBreakpoints('sm');
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isBrowser, breakpoints, windowSize.width]); // Include isBrowser in the dependency array

  if (!props.fields) return <></>;

  // Ref for the slider
  const contentRef = React.createRef<Splide>();
  const cardOrientationType =
    articleCards && articleCards?.length <= 2 ? 'TwoColumns' : 'ThreeColumns';

  // Default slide per page
  let slidePerPage = 1;

  // If breakpoint is md or lg then set the slider per page to 3
  if (breakpoints === 'md' || breakpoints === 'lg') {
    slidePerPage = 3;
  }

  const {
    componentBG,
    base,
    contentWrapper,
    titleAndDescriptionWrapperOuter,
    titleAndDescriptionWrapperInner,
    titleText,
    descriptionText,
    ctaWrapper,
    svgClass,
    svgWrapper,
    articleCardCarousel,
    contentCarousel,
    contentCarouselTrack,
    controlContainerClasses,
    buttonClasses,
    iconClasses,
    playPauseFill,
    iconPrev,
    iconNext,
    articleCardListing,
    articleCard,
    disclaimerClasses,
  } = tailwindVariantsArticleCard({
    alignCTA: alignCTA as AlignCTA,
    cardOrientation: cardOrientationType as CardOrientation,
  });

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <div className={componentBG()} id={id ? id : undefined} tabIndex={id ? -1 : 1}>
      <Container>
        <div
          className={base()}
          data-component="authorable/general/articlecard"
          data-testid="articlecard"
        >
          <div className={contentWrapper()}>
            {title?.value && primaryCTA?.value?.href && (
              <>
                <div className={titleAndDescriptionWrapperOuter()}>
                  <div className={titleAndDescriptionWrapperInner()}>
                    {title?.value && (
                      <Text className={titleText()} encode={false} field={title} tag="p" />
                    )}
                    {description?.value && (
                      <Text
                        className={descriptionText()}
                        encode={false}
                        field={description}
                        tag="p"
                      />
                    )}
                  </div>
                  {primaryCTA?.value?.text && alignCTA == 'TopRight' && (
                    <div className={ctaWrapper()}>
                      <Button
                        href={primaryCTA?.value?.href}
                        label={primaryCTA?.value.text}
                        title={primaryCTA?.value?.title}
                        target={primaryCTA?.value?.target}
                        tag="a"
                        // The design requires an outline CTA but field name is primaryCTA,
                        // so for that we have added a fallback as an outline value,
                        // so if there is no value in sitecore field, it will take the outline value.
                        type={primaryCTAType?.value || fallbackComponentVariantType}
                        color={primaryCTAColor?.value || fallbackComponentVariantColor}
                        gtmEvent={{
                          event: 'cta_click',
                          type: 'primary',
                          'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                          'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                          'gtm.element.dataset.gtmDatasourceId': dataSource,
                          'gtm.element.dataset.gtmComponentName': componentName,
                        }}
                      />
                    </div>
                  )}
                </div>
                {enablePattern?.value && (
                  <div className={svgWrapper()}>
                    <SVG
                      className={svgClass()}
                      svg={`rule-lines/Breakpoint=Large,Brand=${themeName}`}
                    />
                  </div>
                )}
              </>
            )}
            <div className={articleCardCarousel()}>
              {(isArray(articleCards) && breakpoints === 'sm' && articleCards?.length > 1) ||
              (isArray(articleCards) && articleCards?.length > 3) ? (
                <Carousel
                  autoPlay={autoPlay}
                  hasTrack={false}
                  ref={contentRef}
                  carouselClasses={contentCarousel()}
                  trackClasses={contentCarouselTrack()}
                  gtmEvent={{
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  options={{
                    arrows: true,
                    type: 'slide',
                    autoplay: true,
                    drag: true,
                    isNavigation: false,
                    rewind: false,
                    interval: 3600,
                    perPage: slidePerPage,
                    pagination: false,
                    trimSpace: false,
                    gap: 24,
                    padding: { left: 0, right: '6.5%' },
                    mediaQuery: 'max',
                    breakpoints: {
                      1400: {
                        perPage: 2,
                        gap: 10,
                        padding: { left: 0, right: 24 },
                      },
                      991: {
                        perPage: 1,
                        gap: 10,
                        padding: { left: 0, right: 24 },
                      },
                    },
                  }}
                  carouselControls={{
                    controlContainerClasses: controlContainerClasses(),
                    buttonClasses: buttonClasses(),
                    buttons: {
                      classes: iconClasses(),
                      prev: {
                        icon: iconPrev(),
                      },
                      next: {
                        icon: iconNext(),
                      },
                      playPause: playPauseFill(),
                    },
                    paginationVariant: 'white',
                  }}
                >
                  {articleCards?.map((item: ArticleCard, key) => {
                    return (
                      <React.Fragment key={key}>
                        <ArticleSingleCard {...item} numOfCards={articleCards?.length} />
                      </React.Fragment>
                    );
                  })}
                </Carousel>
              ) : (
                <div className={articleCardListing()}>
                  {articleCards?.map((item: ArticleCard, key) => {
                    return (
                      <React.Fragment key={key}>
                        <div className={articleCard()}>
                          <ArticleSingleCard {...item} numOfCards={3} />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
            {primaryCTA?.value?.text && alignCTA == 'BottomCenter' && (
              <div className={ctaWrapper()}>
                <Button
                  label={primaryCTA?.value.text}
                  title={primaryCTA?.value?.title}
                  // The design requires an outline CTA but field name is primaryCTA,
                  // so for that we have added a fallback as an outline value,
                  // so if there is no value in sitecore field, it will take the outline value.
                  type={primaryCTAType?.value || fallbackComponentVariantType}
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  target={primaryCTA?.value?.target}
                  tag="a"
                  href={primaryCTA?.value?.href}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  fullWidth={breakpoints === 'sm' && true}
                />
              </div>
            )}
          </div>
          {disclaimerText?.value != '' && (
            <LegalDisclaimer
              disclaimerText={disclaimerText}
              disclaimerClasses={disclaimerClasses()}
            />
          )}
        </div>
      </Container>
    </div>
  );
};

export default ArticleCard;
