// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    componentBG: [],
    base: [
      'flex',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
    contentContainer: [
      'flex',
      'flex-col',
      'md:flex-1',
      'gap-components-contact-us-promo-spacing-small-title-margin-bottom',
    ],
    headlineText: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
    ],
    descriptionText: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'md:font-bodySans-large',
      'md:leading-bodySans-large',
      'md:text-bodySans-large',
    ],
    ctaContainer: [
      'flex',
      'flex-col-reverse',
      'gap-themes-general-card-spacing-small-button-space-between',
      'md:gap-themes-general-card-spacing-large-button-space-between',
      'md:items-center',
    ],
  },
  variants: {
    background: {
      BrandColor: {
        componentBG: ['bg-components-contact-us-promo-color-brand-bg'],
        headlineText: ['text-components-contact-us-promo-color-brand-title'],
        descriptionText: ['text-components-contact-us-promo-color-brand-body'],
      },
      Default: {
        componentBG: ['bg-components-contact-us-promo-color-default-bg'],
        headlineText: ['text-components-contact-us-promo-color-default-title'],
        descriptionText: ['text-components-contact-us-promo-color-default-body'],
      },
      Light: {
        componentBG: ['bg-components-contact-us-promo-color-light-bg'],
        headlineText: ['text-components-contact-us-promo-color-light-title'],
        descriptionText: ['text-components-contact-us-promo-color-light-body'],
      },
    },
    alignment: {
      Center: {
        base: [
          'flex-col',
          'gap-y-themes-general-surface-spacing-small-button-margin-top',
          'md:gap-y-themes-general-surface-spacing-large-button-margin-top',
        ],
        contentContainer: [
          'md:items-center',
          'md:gap-y-components-promo-spacing-large-title-margin-bottom',
        ],
        ctaContainer: ['md:flex-row', 'md:justify-center'],
        descriptionText: ['md:text-center'],
      },
      Left: {
        base: [
          'left-base',
          'flex-col',
          'md:flex-row',
          'gap-y-themes-general-surface-spacing-small-button-margin-top',
          'md:gap-x-themes-general-surface-spacing-large-body-margin-right',
        ],
        contentContainer: [
          'items-start',
          'md:gap-y-themes-general-surface-spacing-large-title-margin-bottom',
        ],
        ctaContainer: ['md:flex-row-reverse', 'md:justify-end'],
      },
    },
  },
});
