// Global
import { ImageField, Item, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import React, { useEffect, createRef, useState } from 'react';

// Lib
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import fallback from 'lib/fallback/fallback';
import { tailwindVariants } from './MediaGalleryCarouselTailwind';

interface MediaGalleryCarouselProps {
  slides: CompositeComponents.MediaGallery.MediaGallerySlideList[] & Item[];
  slideIndex: number;
}

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const MediaGalleryCarousel = (props: MediaGalleryCarouselProps) => {
  const { slideIndex, slides } = props;
  const carouselRef = createRef<Splide>();
  const contentRef = createRef<Splide>();
  const prevRef = createRef<Splide>();
  const nextRef = createRef<Splide>();
  const {
    base,
    centerSplide,
    centerSplidePagination,
    centerSplidePaginationContainer,
    centerSplidePaginationItems,
    centerSplideSlide,
    centerSplideTrack,
    detailsSplide,
    detailsSplideSlide,
    prevNextButtons,
    prevNextSplide,
    prevNextSplideButtons,
    prevNextSplideSlide,
    prevNextSplideTrack,
    slideDescription,
    slideTitle,
    imageBorder,
  } = tailwindVariants();
  const [prevSlideIndex, setPrevSlideIndex] = useState(slideIndex - 1);
  const [nextSlideIndex, setNextSlideIndex] = useState(slideIndex + 1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(slideIndex);

  const navigate = (dir: number) => {
    if (dir === -1 && carouselRef?.current?.splide) {
      carouselRef.current.splide?.Components.Controller.go('<');

      if (prevRef?.current?.splide && currentSlideIndex !== 0) {
        prevRef.current.splide?.Components.Controller.go('<');
      }

      if (
        nextRef?.current?.splide &&
        currentSlideIndex > 0 &&
        currentSlideIndex < slides.length - 1
      ) {
        nextRef.current.splide?.Components.Controller.go('<');
      }
    }

    if (dir === 1 && carouselRef?.current?.splide) {
      carouselRef.current.splide?.Components.Controller.go('>');

      if (
        prevRef?.current?.splide &&
        currentSlideIndex > 0 &&
        currentSlideIndex < slides.length - 1
      ) {
        prevRef.current.splide?.Components.Controller.go('>');
      }

      if (nextRef?.current?.splide && currentSlideIndex < slides.length - 1) {
        nextRef.current.splide?.Components.Controller.go('>');
      }
    }
  };

  useEffect(() => {
    setPrevSlideIndex(slideIndex - 1);
    setNextSlideIndex(slideIndex + 1);
    setCurrentSlideIndex(slideIndex);
  }, [slideIndex]);

  useEffect(() => {
    if (carouselRef.current) {
      if (prevRef?.current?.splide) {
        prevRef.current.splide.go(currentSlideIndex - 1);
      }

      if (nextRef?.current?.splide) {
        nextRef.current.splide.go(currentSlideIndex + 1);
      }
    }
  }, [currentSlideIndex, carouselRef, prevRef, nextRef]);

  useEffect(() => {
    if (
      contentRef.current &&
      contentRef.current.splide &&
      carouselRef.current &&
      carouselRef.current.splide
    ) {
      contentRef.current.splide.sync(carouselRef.current.splide);
    }
  }, [carouselRef, contentRef]);

  const prevNextSplideConfigOptions = {
    pagination: false,
  };

  const sharedSplideConfigOptions = {
    arrows: false,
    easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    drag: false,
    perPage: 1,
    rewind: false,
    type: 'fade',
  };

  return (
    <>
      <div className={base()}>
        {/* Previous */}
        <Splide
          ref={prevRef}
          hasTrack={false}
          options={{
            ...prevNextSplideConfigOptions,
            ...sharedSplideConfigOptions,
            start: prevSlideIndex,
          }}
          className={prevNextSplide()}
        >
          <div className="flex justify-center items-center">
            <SplideTrack className={prevNextSplideTrack()}>
              {slides.map((slide: CompositeComponents.MediaGallery.MediaGallerySlide & Item) => (
                <SplideSlide className={prevNextSplideSlide({ isPrev: true })} key={slide.id}>
                  <ImageWrapper
                    className={imageBorder()}
                    field={slide.fields.image as ImageField}
                  />
                </SplideSlide>
              ))}
            </SplideTrack>
            <Button
              childClass={prevNextSplideButtons({ prev: true })}
              type="filled"
              iconLeft="chevron_left"
              disabled={currentSlideIndex === 0}
              onClick={() => navigate(-1)}
              onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') {
                  navigate(-1);
                }
              }}
              title="Previous"
            />
          </div>
        </Splide>
        {/* /Previous */}
        <Splide
          ref={carouselRef}
          hasTrack={false}
          options={{
            ...sharedSplideConfigOptions,
            autoplay: false,
            focus: 'center',
            pagination: true,
            width: '100%',
            start: slideIndex,
          }}
          onMove={(splide) => {
            setCurrentSlideIndex(splide.index);
          }}
          onPaginationUpdated={(splide) => {
            setCurrentSlideIndex(splide.index);
            splide.Components.Pagination.items.forEach((item) => {
              item.button.tabIndex = -1;
            });
          }}
          onPaginationMounted={(splide) => {
            splide.Components.Pagination.items.forEach((item) => {
              const centerSplidePaginationItemClasses = centerSplidePaginationItems().split(' ');
              item.button.classList.add(...centerSplidePaginationItemClasses);
              item.button.tabIndex = -1;
            });
          }}
          className={centerSplide()}
        >
          <SplideTrack className={centerSplideTrack()}>
            {slides.map((slide: CompositeComponents.MediaGallery.MediaGallerySlide & Item) => (
              <SplideSlide key={slide.id} className={centerSplideSlide()}>
                <ImageWrapper field={slide.fields.image as ImageField} />
              </SplideSlide>
            ))}
          </SplideTrack>
          <div className={centerSplidePaginationContainer()}>
            <Button
              childClass={prevNextButtons()}
              type="filled"
              iconLeft="chevron_left"
              disabled={currentSlideIndex === 0}
              onClick={() => navigate(-1)}
              title="Previous"
            />
            <ul className={centerSplidePagination()} />
            <Button
              childClass={prevNextButtons()}
              type="filled"
              iconLeft="chevron_right"
              disabled={currentSlideIndex === slides.length - 1}
              onClick={() => navigate(1)}
              title="Next"
            />
          </div>
        </Splide>
        {/* Next */}
        <Splide
          hasTrack={false}
          ref={nextRef}
          options={{
            ...prevNextSplideConfigOptions,
            ...sharedSplideConfigOptions,
            start: nextSlideIndex,
          }}
          className={prevNextSplide()}
        >
          <div className="flex justify-center items-center">
            <SplideTrack className={prevNextSplideTrack()}>
              {slides.map((slide: CompositeComponents.MediaGallery.MediaGallerySlide & Item) => (
                <SplideSlide className={prevNextSplideSlide({ isPrev: false })} key={slide.id}>
                  <ImageWrapper
                    className={imageBorder()}
                    field={slide.fields.image as ImageField}
                  />
                </SplideSlide>
              ))}
            </SplideTrack>
            <Button
              childClass={prevNextSplideButtons({ prev: false })}
              type="filled"
              iconLeft="chevron_right"
              disabled={currentSlideIndex === slides.length - 1}
              onClick={() => navigate(1)}
              onKeyDown={(e) => {
                if (e.key === 'ArrowRight') {
                  navigate(1);
                }
              }}
              title="Next"
            />
          </div>
        </Splide>
        {/* /Next */}
      </div>
      {/* details */}
      <Splide
        hasTrack={false}
        ref={contentRef}
        options={{
          ...sharedSplideConfigOptions,
          pagination: false,
          width: '100%',
          start: slideIndex,
        }}
        className={detailsSplide()}
      >
        <SplideTrack>
          {slides.map((slide: CompositeComponents.MediaGallery.MediaGallerySlide & Item) => {
            const { description, title, primaryCTA, primaryCTAColor, primaryCTAType } =
              slide.fields;
            return (
              <SplideSlide key={slide.id} className={detailsSplideSlide()}>
                {title?.value && (
                  <Text className={slideTitle()} encode={false} field={title} tag="p" />
                )}
                {description?.value && (
                  <RichTextA11yWrapper className={slideDescription()} field={description} />
                )}
                {primaryCTA?.value?.href && (
                  <Button
                    href={primaryCTA?.value?.href}
                    label={primaryCTA?.value?.text}
                    tag="a"
                    target={primaryCTA?.value?.target}
                    // The design requires an outline CTA but field name is primaryCTA,
                    // so for that we have added a fallback as an outline value,
                    // so if there is no value in sitecore field, it will take the outline value
                    type={primaryCTAType?.value || fallbackComponentVariantType}
                    color={primaryCTAColor?.value || fallbackComponentVariantColor}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'primary',
                      'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    }}
                  />
                )}
              </SplideSlide>
            );
          })}
        </SplideTrack>
      </Splide>
      {/* /details */}
    </>
  );
};

export default MediaGalleryCarousel;
