// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    video: [
      'aspect-video',
      'group-[.layoutWrapper]:m-0',
      'h-auto',
      'mb-spacing-spacing-6',
      'md:mb-spacing-spacing-9',
      'w-full',
    ],
  },
});
