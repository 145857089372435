// Global
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSearchParams } from 'next/navigation';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { SearchComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import SVG from 'helpers/SVG/SVG';
import SearchPreview from 'src/widgets/SearchPreview';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { tailwindVariants } from './SearchHeroTailwind';

export type SearchHeroProps = ComponentProps & SearchComponents.SearchHero.SearchHero;

type ShowPattern = true | false | undefined;

const SearchHero = (props: SearchHeroProps): JSX.Element => {
  const {
    title,
    searchPlaceholderText,
    enablePattern,
    autoSuggestionItems,
    resultSuggestionItems,
  } = props?.fields || {};
  const showPattern = enablePattern?.value ? true : false;

  const { themeName } = useTheme();

  const SearchPreviewProps = {
    autoSuggestionItems: autoSuggestionItems?.value || 5,
    resultSuggestionItems: resultSuggestionItems?.value || 5,
    searchPlaceholderText: searchPlaceholderText?.value,
    defaultValue: '',
  };

  const {
    base,
    searchHeroWrapper,
    searchHeroSvg,
    svgDecor,
    searchHeroTitleWrapper,
    searchHeroTitle,
    searchHeroInputWrapper,
  } = tailwindVariants({
    showPattern: showPattern as ShowPattern,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const searchParams = useSearchParams();
  const searchQuery = searchParams.get('q') || '';

  const componentVariants = ComponentVariants();

  if (!props?.fields) return <></>;
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <Container fullWidth>
      <div
        className={base()}
        data-component="authorable/heroes/searchhero"
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        <div className={searchHeroWrapper()}>
          {componentVariants?.heroInternal?.hasDecor &&
            (themeName == 'Off' || themeName == 'Autan') && (
              <SVG className={svgDecor()} svg={`hero-internal/Decor,Brand=${themeName}`} />
            )}
          <SVG className={searchHeroSvg()} svg={`hero-internal/BG,Brand=${themeName}`} />
          <div className={searchHeroTitleWrapper()}>
            <Text className={searchHeroTitle()} encode={false} field={title} tag="h1" />
          </div>
          <div className={searchHeroInputWrapper()}>
            <SearchPreview
              rfkId={'rfkid_11'}
              key={`${searchQuery}-search-preview`}
              {...SearchPreviewProps}
              defaultValue={searchQuery}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default SearchHero;
