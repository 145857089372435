// Global
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';
import React from 'react';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import { tailwindVariants } from './WriteAReviewTailwind';

const WriteAReview = () => {
  const context = useSitecoreContext();
  const language = context.sitecoreContext.language?.replace('-', '_');
  const merchantID = '';
  const merchantGroupID = '';
  const powerReviewsApiKey = '';
  const pageID = '';
  const { base } = tailwindVariants();

  return (
    <>
      <Script id="power-review-js" suppressHydrationWarning>
        {`
        
          window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments); 
           };
           pwr('render', {
             ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false, 
             api_key: '${powerReviewsApiKey}',
             locale: '${language}',
             merchant_group_id: '${merchantGroupID}',
             merchant_id: '${merchantID}',
             page_id: '${pageID}',
             on_submit:function(config, data){
              window.scrollTo(0,0);
              },
            components: {
              Write: 'pr-write',
            }
          });
        `}
      </Script>
      <Container>
        <div id="pr-write" className={base()} />
      </Container>
    </>
  );
};

export default WriteAReview;
