// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: ['mainNavItemLink', 'secondaryNavItemLink'],
      class: [
        'cursor-pointer',
        'flex',
        'group',
        'items-center',
        'self-stretch',
        'md:self-auto',
        'min-h-components-header-spacing-small-nav-main-min-height',
        'px-components-footer-brand-spacing-small-footer-link-padding-x',
        'py-components-footer-brand-spacing-small-footer-link-padding-y',
        'mb-0',
        'md:min-h-0',
        'md:px-0',
        'md:py-0',
        'md:mb-components-footer-brand-spacing-large-link-margin-bottom',
        'md:active:text-components-footer-brand-color-category-link-default',
        'md:hover:text-components-footer-brand-color-category-link-hover',
        'md:focus:text-components-footer-brand-color-category-link-default',
        'md:hover:focus:text-components-footer-brand-color-category-link-default',
        '[&>div]:grow',
      ],
    },
  ],
  slots: {
    base: ['flex', 'flex-col', 'items-center', 'justify-end'],
    brandContainer: [
      'bg-components-footer-brand-color-bg',
      'flex',
      'flex-col',
      'items-center',
      'justify-end',
      'md:flex-row',
      'md:justify-between',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'md:py-spacing-padding-large-6',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'gap-components-footer-brand-spacing-large-column-margin-bottom',
      'md:gap-x-components-footer-brand-spacing-large-column-margin-right',
    ],
    brandNavContainer: ['flex', 'flex-col', 'items-start', 'self-stretch', 'md:basis-2/3'],
    brandSocialContainer: [
      'flex',
      'flex-col',
      'md:items-start',
      'gap-components-footer-brand-spacing-large-link-margin-bottom',
      'items-center',
      'self-stretch',
    ],
    copyRightTextContainer: [
      'color-components-footer-global-color-disclaimer-text',
      'text-bodySans-xSmall',
      'leading-bodySans-xSmall',
      'font-bodySans-xSmall',
    ],
    footerLinkContainer: [
      'flex',
      'gap-spacing-space-between-large-1',
      'group',
      'items-center',
      'md:max-w-none',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-footer-global-color-link-default',
    ],
    footerLinkIcon: [
      'opacity-0',
      'transition-opacity',
      'duration-300',
      'group-hover:opacity-100',
      'group-active:opacity-100',
      'mt-0.5',
    ],
    footerLinkWrapper: [
      'flex',
      'font-bodySans-small',
      'group',
      'items-center',
      'text-bodySans-small',
    ],
    footerRawHtmlWrapper: ['cursor-pointer'],
    footerTextItem: ['[&>div]:!cursor-default', '!self-stretch'],
    footerTextItemWidth: ['w-full'],
    globalContainer: [
      'bg-components-footer-global-color-bg',
      'flex',
      'flex-col',
      'md:flex-row',
      'md:justify-between',
      'max-md:gap-components-footer-brand-spacing-small-column-margin-bottom',
      'md:items-start',
      'px-themes-general-surface-spacing-small-padding-x',
      'py-themes-general-surface-spacing-small-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'md:py-spacing-padding-large-5',
    ],
    globalContainerLeft: [
      'flex',
      'flex-col',
      'max-md:gap-components-footer-global-spacing-small-logo-margin-bottom',
      'justify-between',
      'items-center',
      'md:self-stretch',
      'md:items-start',
    ],
    globalContainerRight: [
      'flex',
      'md:gap-components-footer-global-spacing-large-column-margin-right',
    ],
    globalContainerRightColumn: [
      'flex',
      'flex-col',
      'gap-components-footer-global-spacing-small-link-margin-bottom',
      'md:gap-components-footer-global-spacing-large-link-margin-bottom',
      'md:items-start',
      'max-md:flex-1',
    ],
    linkContainer: ['group', 'flex', 'flex-row', 'items-center'],
    linkIcon: [
      'opacity-0',
      'group-hover:opacity-100',
      'group-active:opacity-100',
      'md:group-active:fill-components-footer-brand-color-category-link-default',
      'fill-components-footer-brand-color-category-link-default',
      'md:fill-components-footer-brand-color-category-link-hover',
      'md:group-hover:group-focus:fill-components-footer-brand-color-category-link-default',
    ],
    mainNavContainer: [
      'w-full',
      'md:flex',
      'md:flex-1',
      'pr-components-footer-brand-spacing-small-footer-link-padding-right',
      'pl-components-footer-brand-spacing-small-footer-link-padding-left',
      'md:px-0',
      'justify-end',
    ],
    mainNavItemContainer: ['flex', 'flex-col', 'items-start', 'stretch-self', 'md:basis-1/4'],
    mainNavItemLink: [
      'bg-components-footer-brand-color-bg',
      'border-b',
      'border-components-footer-brand-color-bg',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'self-stretch',
      'text-components-footer-brand-color-category-link-default',
      'md:pt-0',
      'md:self-auto',
    ],
    navItemLinkIcon: [
      'fill-components-footer-brand-color-icon-icon-default',
      'float-right',
      'md:group-active:fill-components-footer-brand-color-icon-icon-default',
      'md:group-hover:fill-components-footer-brand-color-icon-icon-default',
      'md:group-hover:fill-components-footer-brand-color-category-link-hover',
      'md:group-focus:fill-components-footer-brand-color-icon-icon-default',
      'md:group-hover:group-focus:fill-components-footer-brand-color-icon-icon-default',
      'md:float-none',
      'md:hidden',
      'h-[32px]',
      'w-[32px]',
      'flex',
      'items-center',
      'justify-center',
    ],
    secondaryNavContainer: ['items-start', 'w-full'],
    tertiaryNavContainer: ['items-start', 'md:hidden'],
    secondaryNavItemContainer: [
      'flex',
      'flex-col',
      'items-start',
      'stretch-self',
      'text-components-footer-brand-color-category-link-default',
    ],
    secondaryNavItemLink: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'md:font-bodySans-small',
      'md:leading-bodySans-small',
      'md:text-bodySans-small',
    ],
    socialIcon: [
      'fill-components-footer-brand-color-social-icon-default',
      'active:fill-components-footer-brand-color-social-icon-pressed',
      'hover:fill-components-footer-brand-color-social-icon-hover',
      'duration-300',
    ],
    socialIconsContainer: [
      'content-center',
      'flex',
      'flex-wrap',
      'gap-4',
      'items-center',
      'justify-center',
    ],
    socialTitleContainer: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-footer-brand-color-title-text',
    ],
  },
  variants: {
    isMainOpen: {
      false: {
        secondaryNavContainer: ['hidden', 'md:block'],
      },
      true: {
        secondaryNavContainer: ['block'],
      },
    },
    isSecondaryOpen: {
      false: {
        tertiaryNavContainer: ['hidden'],
      },
      true: {
        tertiaryNavContainer: ['block'],
      },
    },
  },
});
