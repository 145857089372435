// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    wrapper: ['bg-components-video-color-bg'],
    base: [
      'grid',
      'md:gap-spacing-space-between-large-6',
      'gap-spacing-space-between-large-5',
      'md:p-spacing-spacing-10',
      'p-spacing-spacing-5',
    ],
    captionContainer: ['flex', 'flex-col', 'p-spacing-spacing-5', 'gap-spacing-spacing-4'],
    caption: [
      'text-components-media-caption-color-text',
      'font-header-small-small',
      'leading-header-small-small',
      'text-header-small-small',
    ],
    subtitle: [
      'text-components-media-caption-color-text',
      'font-header-large-xxSmall',
      'leading-header-large-xxSmall',
      'text-header-large-xxSmall',
    ],
    description: [
      'text-components-media-caption-color-text',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
    ],
    button: [
      'absolute',
      'top-[-80px]',
      'md:right-[-70px]',
      'right-[20px]',
      'bg-components-button-color-outline-white-default-bg',
      '!z-[99999]',
      'md:w-[48px]',
      'w-[50px]',
      'max-md:!px-[5px]',
      'max-md:!py-[10px]',
    ],
    playButton: [
      'h-[40px]',
      'md:w-[48px]',
      'md:h-[48px]',
      'w-[40px]',
      '!border-0',
      '!rounded-[50%]',
      'absolute',
      'left-1/2',
      'top-1/2',
      'transform',
      '-translate-x-1/2',
      '-translate-y-1/2',
    ],
    mediaCard: [
      'w-full',
      '[&_img]:!rounded-t-themes-spacing-radius-large-image',
      '[&_img]:!rounded-b-none',
      'bg-components-media-caption-color-bg',
      'rounded-themes-spacing-radius-large-image',
    ],
    mediaWrapper: ['relative', 'cursor-pointer'],
  },
  variants: {
    width: {
      'full-width': {
        base: ['grid-cols-1'],
        mediaCard: ['text-small'],
      },
      'half-width': {
        base: ['md:grid-cols-2', 'grid-cols-1'],
        mediaCard: ['flex', 'flex-col'],
      },
      'one-third-width': {
        base: ['md:grid-cols-3', 'grid-cols-1'],
        mediaCard: ['flex', 'flex-col'],
      },
    },
  },
});
