// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// Global
import { useSitecoreContext, Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { BaseFields } from 'lib/templates/Foundation.Dart.model';

// Local
import CtaBorderAnimation from 'helpers/CTA/CtaBorderAnimation';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import Search from 'src/widgets/Search';
import { useRouter } from 'next/router';
import { useTheme } from 'lib/context/ThemeContext';
import { tailwindVariants } from './HeaderDesktopTailwind';

export type NameDefault = {
  nameDefault?: { jsonValue: Field<string> };
};

export type Countrylist = DartComponents.Navigation.RegionJson &
  NameDefault &
  DartComponents.Navigation.CountryJson;

export type FeaturedLinks = BaseFields.CtaJson;

export type HeaderDesktopProps = ComponentProps &
  DartComponents.HeaderJson & {
    staticProps?: {
      header?: DartComponents.HeaderJson;
    };
  };

export type MainNavigations = DartComponents.Navigation.SecondaryNavigationJson &
  DartComponents.Navigation.PrimaryHeaderNavigationJson &
  BaseFields.FeaturedJson;

export type SecondaryNavigations = DartComponents.Navigation.SecondaryNavigationJson;

export type SearchPage = DartPages.SearchPage;

const HeaderDesktop = (props: HeaderDesktopProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { themeName } = useTheme();
  const {
    enableSearch,
    logoImage,
    logoUrl,
    mainNavigations,
    regions,
    title,
    autoSuggestionItems,
    searchPlaceholderText,
    resultSuggestionItems,
  } = props?.staticProps?.header || {};

  const navLength = (mainNavigations?.targetItems?.length as number) > 4;
  const languageLength = (regions?.targetItems?.length as number) > 1;

  const {
    base,
    countryDropDownIcon,
    countryInformation,
    countryItemLink,
    countryList,
    countryListBottom,
    countryListCol,
    countryListCols,
    countryListHeading,
    countryListInnerWrapper,
    countryListItemLinkleft,
    countryListItemLinkleftInfo,
    countryListItemLinkleftInfoSubTitle,
    countryListItemLinkleftInfoTitle,
    countryListRight,
    countryListRightIcon,
    countryListTop,
    countryListWrapper,
    countryName,
    countrySelection,
    countryWrapper,
    countryWrapperInner,
    currentCountry,
    currentCountryLanguage,
    currentCountryName,
    featuredContentImage,
    featuredContentImageWrapper,
    featuredContentLinkItem,
    featuredContentLinks,
    featuredContentLinksWrapper,
    featuredContentTitle,
    featuredContentTitleWrapper,
    featuredContentWrapper,
    flagIconWrapper,
    headerLeft,
    headerRight,
    headerWrapper,
    logo,
    logoPlaceholder,
    logoWrapper,
    navigation,
    navigationInner,
    primaryNavigation,
    secondaryDropdownItem,
    secondaryDropdownItemLink,
    secondaryDropdownListItem,
    secondaryDropdownWrapper,
    secondaryNavigation,
    secondaryNavigationItemsWrapper,
    secondaryNavigationListItemLinkIcon,
    secondaryNavigationListItemLinkIconWrapper,
    secondaryNavigationListItemWrapper,
    secondaryNavigationWrapper,
  } = tailwindVariants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
    navLength,
    languageLength,
  });

  const { componentName, dataSource } = props?.rendering || {};

  const SearchProps = {
    autoSuggestionItems: autoSuggestionItems?.jsonValue.value || 5,
    resultSuggestionItems: resultSuggestionItems?.jsonValue.value || 5,
    searchPlaceholderText: searchPlaceholderText?.jsonValue?.value,
    defaultValue: '',
    mainNavigationsLength: mainNavigations?.targetItems?.length as number,
  };

  const router = useRouter();

  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [matchingCountry, setMatchingCountry] = useState<Countrylist | null | undefined>();
  const [openCountryWrapper, setOpenCountryWrapper] = useState<boolean>(false);
  const [pathname, setPathname] = useState(`${router?.locale}/${router?.asPath}`);

  useEffect(() => {
    router && setPathname(`/${router.locale}${router.asPath}`);
    setHoveredItem(null);
    setOpenCountryWrapper(false);
  }, [router]);

  const handleMouseEnter = (index: number) => {
    setHoveredItem(index);
    setOpenCountryWrapper(false);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const showCountryWrapper = () => {
    setOpenCountryWrapper(!openCountryWrapper);
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLElement>,
    secondaryNavExists: boolean
  ) => {
    if (e.key === 'Enter') {
      if (secondaryNavExists && e?.preventDefault) e.preventDefault();
      setHoveredItem(hoveredItem === index ? null : index);
      if (hoveredItem !== index) setOpenCountryWrapper(false);
    }
  };

  useEffect(() => {
    if (!regions) return;

    const foundCountry = regions?.targetItems
      .flatMap((region: Countrylist) => {
        return region?.countries?.targetItems;
      })
      .find(
        (country?: Countrylist) =>
          country?.languageCode?.jsonValue?.value === sitecoreContext.language
      );

    setMatchingCountry(foundCountry);
  }, [props, regions, sitecoreContext]);

  return (
    <div className={base()} data-component="authorable/landmarks/header">
      <div className={headerWrapper()}>
        <div className={headerLeft()}>
          <div className={logoWrapper()}>
            <div className={logo()}>
              {pathname !== '/' ? (
                <LinkWrapper
                  aria-label="Link to homepage"
                  field={logoUrl?.jsonValue}
                  gtmEvent={{
                    event: 'navigation_click',
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                  title="Link to homepage"
                >
                  <ImageWrapper field={logoImage?.jsonValue} />
                </LinkWrapper>
              ) : (
                <ImageWrapper field={logoImage?.jsonValue} />
              )}
            </div>
          </div>
          <nav className={navigation()}>
            <div className={navigationInner()}>
              <ul className={primaryNavigation()}>
                {mainNavigations?.targetItems?.map((ListItem: MainNavigations, index: number) => {
                  const {
                    primaryNavigationListItem,
                    primaryNavigationListItemArrowDropDown,
                    primaryNavigationListItemLink,
                    primaryNavigationListItemLinkWrapper,
                    primaryNavigationListItemWrapper,
                  } = tailwindVariants({
                    hasHref:
                      (ListItem?.navigationLink?.jsonValue?.value?.href &&
                        ListItem?.navigationLink?.jsonValue?.value?.href?.length < 0) ||
                      false,
                    isActive:
                      (ListItem?.navigationLink?.jsonValue?.value?.href &&
                        ListItem?.navigationLink?.jsonValue?.value?.href === pathname) ||
                      false,
                    isDropdown: ListItem?.isDropdown?.jsonValue?.value,
                    /* eslint-disable  @typescript-eslint/ban-ts-comment */
                    // @ts-ignore
                    brand: themeName as string,
                  });

                  return (
                    <li
                      className={primaryNavigationListItemWrapper()}
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      onKeyDown={(e) =>
                        handleKeyDown(
                          index,
                          e,
                          ListItem?.secondaryNavigations?.targetItems?.length !== 0
                        )
                      }
                    >
                      <div className={primaryNavigationListItem()}>
                        <div
                          className={primaryNavigationListItemLinkWrapper()}
                          tabIndex={
                            ListItem?.secondaryNavigations?.targetItems?.length !== 0 ? 0 : -1
                          }
                        >
                          {ListItem?.navigationLink?.jsonValue?.value?.href ? (
                            <LinkWrapper
                              className={primaryNavigationListItemLink()}
                              field={ListItem?.navigationLink?.jsonValue}
                              gtmEvent={{
                                event: 'navigation_click',
                                type: 'header',
                                'gtm.element.dataset.gtmLinkName':
                                  ListItem?.navigationLink?.jsonValue?.value.text,
                                'gtm.element.dataset.gtmLinkUrl':
                                  ListItem?.navigationLink?.jsonValue?.value?.href,
                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                'gtm.element.dataset.gtmComponentName': componentName,
                              }}
                              tabIndex={
                                ListItem?.secondaryNavigations?.targetItems?.length !== 0 ? -1 : 0
                              }
                              aria-haspopup={
                                ListItem?.secondaryNavigations?.targetItems?.length !== 0 &&
                                hoveredItem === index
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          ) : (
                            <Text
                              aria-label={ListItem?.navigationTitle?.jsonValue.value}
                              className={primaryNavigationListItemLink()}
                              encode={false}
                              field={ListItem?.navigationTitle?.jsonValue}
                              onFocus={() => {
                                handleMouseEnter(index);
                              }}
                              tag="span"
                              tabIndex={-1}
                            />
                          )}
                          {ListItem?.secondaryNavigations?.targetItems?.length !== 0 && (
                            <GoogleMaterialSymbol
                              className={primaryNavigationListItemArrowDropDown()}
                              icon="arrow_drop_down"
                            />
                          )}
                        </div>
                      </div>
                      {hoveredItem === index &&
                        ListItem?.secondaryNavigations?.targetItems?.length !== 0 &&
                        !openCountryWrapper &&
                        (!ListItem?.isDropdown?.jsonValue?.value ? (
                          // Mega Menu
                          <div className={secondaryNavigationWrapper()}>
                            <ul className={secondaryNavigation()}>
                              {ListItem?.secondaryNavigations?.targetItems?.map(
                                (ListIteminner: SecondaryNavigations, index: number) => {
                                  const {
                                    secondaryNavigationListItemLinkIcon,
                                    secondaryNavigationListItemTitleLink,
                                    secondaryNavigationListItemTitleLinkWrapper,
                                  } = tailwindVariants({
                                    isActive:
                                      (ListIteminner?.navigationLink?.jsonValue?.value?.href &&
                                        ListIteminner?.navigationLink?.jsonValue?.value?.href ===
                                          pathname) ||
                                      false,
                                    isDropdown: ListItem?.isDropdown?.jsonValue?.value,
                                  });

                                  return (
                                    <li
                                      key={index}
                                      className={secondaryNavigationListItemWrapper()}
                                    >
                                      {ListIteminner?.navigationLink?.jsonValue?.value?.href && (
                                        <div
                                          className={secondaryNavigationListItemTitleLinkWrapper()}
                                        >
                                          <LinkWrapper
                                            className={secondaryNavigationListItemTitleLink()}
                                            field={ListIteminner?.navigationLink?.jsonValue}
                                            gtmEvent={{
                                              event: 'navigation_click',
                                              type: 'header',
                                              'gtm.element.dataset.gtmLinkName':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  .text,
                                              'gtm.element.dataset.gtmLinkUrl':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  ?.href,
                                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                                              'gtm.element.dataset.gtmComponentName': componentName,
                                            }}
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.stopPropagation();
                                              }
                                            }}
                                          />
                                          <div
                                            className={secondaryNavigationListItemLinkIconWrapper()}
                                          >
                                            {/* Added this as a wrapper placeholder as parent div of this
                                          only renders on hover of navItem and each time
                                          GoogleMaterialSymbol  */}
                                            <GoogleMaterialSymbol
                                              className={secondaryNavigationListItemLinkIcon()}
                                              icon="chevron_right"
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <ul className={secondaryNavigationItemsWrapper()}>
                                        {ListIteminner?.navigations?.targetItems?.map(
                                          (SecondaryItems: SecondaryNavigations, index: number) => {
                                            const {
                                              secondaryNavigationItemLink,
                                              secondaryNavigationListItemLinkIcon,
                                              secondaryNavigationListItemLinkIconWrapper,
                                              secondaryNavigationItem,
                                            } = tailwindVariants({
                                              isActive:
                                                (SecondaryItems?.navigationLink?.jsonValue?.value
                                                  ?.href &&
                                                  SecondaryItems?.navigationLink?.jsonValue?.value
                                                    ?.href === pathname) ||
                                                false,
                                              isDropdown: ListItem?.isDropdown?.jsonValue?.value,
                                            });

                                            return (
                                              SecondaryItems?.navigationLink?.jsonValue?.value
                                                ?.href && (
                                                <li
                                                  key={index}
                                                  className={secondaryNavigationItem()}
                                                >
                                                  <LinkWrapper
                                                    className={secondaryNavigationItemLink()}
                                                    field={
                                                      SecondaryItems?.navigationLink?.jsonValue
                                                    }
                                                    gtmEvent={{
                                                      event: 'navigation_click',
                                                      type: 'header',
                                                      'gtm.element.dataset.gtmLinkName':
                                                        SecondaryItems?.navigationLink?.jsonValue
                                                          ?.value.text,
                                                      'gtm.element.dataset.gtmLinkUrl':
                                                        SecondaryItems?.navigationLink?.jsonValue
                                                          ?.value?.href,
                                                      'gtm.element.dataset.gtmDatasourceId':
                                                        dataSource,
                                                      'gtm.element.dataset.gtmComponentName':
                                                        componentName,
                                                    }}
                                                    tabIndex={0}
                                                    onKeyDown={(e) => {
                                                      if (e.key === 'Enter') {
                                                        e.stopPropagation();
                                                      }
                                                    }}
                                                  />
                                                  <div
                                                    className={secondaryNavigationListItemLinkIconWrapper()}
                                                  >
                                                    <GoogleMaterialSymbol
                                                      className={secondaryNavigationListItemLinkIcon()}
                                                      icon="chevron_right"
                                                      variant="outlined"
                                                    />
                                                  </div>
                                                </li>
                                              )
                                            );
                                          }
                                        )}
                                      </ul>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            {ListItem?.featuredImage?.jsonValue?.value?.src && (
                              <div className={featuredContentWrapper()}>
                                <div className={featuredContentTitleWrapper()}>
                                  <Text
                                    className={featuredContentTitle()}
                                    encode={false}
                                    field={{ value: ListItem?.featuredTitle?.jsonValue?.value }}
                                    tag="p"
                                  />
                                </div>
                                <div className={featuredContentImageWrapper()}>
                                  <ImageWrapper
                                    field={ListItem?.featuredImage?.jsonValue}
                                    className={featuredContentImage()}
                                  />
                                </div>
                                <div className={featuredContentLinksWrapper()}>
                                  <ul className={featuredContentLinks()}>
                                    {ListItem?.featuredLinks?.targetItems?.map(
                                      (featuredLinkItem: FeaturedLinks, index: number) => {
                                        return (
                                          <React.Fragment key={index}>
                                            {featuredLinkItem?.ctaLink?.jsonValue?.value?.href && (
                                              <li className={featuredContentLinkItem()}>
                                                {featuredLinkItem?.ctaLink && (
                                                  <CtaBorderAnimation
                                                    fields={featuredLinkItem?.ctaLink?.jsonValue}
                                                  />
                                                )}
                                              </li>
                                            )}
                                          </React.Fragment>
                                        );
                                      }
                                    )}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          // Dropdown Menu
                          <div className={secondaryDropdownWrapper()}>
                            <ul className={secondaryDropdownListItem()}>
                              {ListItem?.secondaryNavigations?.targetItems?.map(
                                (ListIteminner: SecondaryNavigations, index: number) => {
                                  return (
                                    ListIteminner?.navigationLink?.jsonValue?.value?.href && (
                                      <li key={index}>
                                        <div className={secondaryDropdownItem()}>
                                          <LinkWrapper
                                            className={secondaryDropdownItemLink()}
                                            field={ListIteminner?.navigationLink?.jsonValue}
                                            gtmEvent={{
                                              event: 'navigation_click',
                                              type: 'header',
                                              'gtm.element.dataset.gtmLinkName':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  .text,
                                              'gtm.element.dataset.gtmLinkUrl':
                                                ListIteminner?.navigationLink?.jsonValue?.value
                                                  ?.href,
                                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                                              'gtm.element.dataset.gtmComponentName': componentName,
                                            }}
                                            tabIndex={0}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.stopPropagation();
                                              }
                                            }}
                                            onBlur={() => {
                                              const isLastLink =
                                                index ===
                                                (ListItem?.secondaryNavigations?.targetItems
                                                  ?.length ?? 0) -
                                                  1;
                                              if (isLastLink) {
                                                handleMouseLeave();
                                              }
                                            }}
                                          />
                                          <div
                                            className={secondaryNavigationListItemLinkIconWrapper()}
                                          >
                                            <GoogleMaterialSymbol
                                              className={secondaryNavigationListItemLinkIcon()}
                                              icon="chevron_right"
                                            />
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        ))}
                    </li>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>

        <div className={headerRight()}>
          {/* Search */}
          {enableSearch?.jsonValue?.value && <Search rfkId={'rfkid_11'} {...SearchProps} />}

          {/* Language Switcher */}
          {/* If there are missing content for language then hide countryWrapper */}
          {matchingCountry?.languageName?.jsonValue?.value && (
            <div className={countryWrapper()}>
              {/* If the regions have only 1 language added then hide dropdown */}
              {languageLength ? (
                <div
                  className={countryWrapperInner()}
                  role="button"
                  tabIndex={0}
                  aria-label={`Current Country and Language - ${
                    matchingCountry?.name?.jsonValue?.value
                      ? matchingCountry?.name?.jsonValue?.value
                      : matchingCountry?.nameDefault?.jsonValue?.value
                  } ${matchingCountry?.languageName?.jsonValue?.value}`}
                  onClick={showCountryWrapper}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      showCountryWrapper();
                    }
                  }}
                >
                  <div className={flagIconWrapper()}>
                    {matchingCountry?.flagIcon?.jsonValue?.value?.src ? (
                      <ImageWrapper field={matchingCountry?.flagIcon?.jsonValue} />
                    ) : (
                      <div className={logoPlaceholder()}></div>
                    )}
                  </div>
                  <div className={currentCountry()}>
                    <div className={countryInformation()}>
                      <div className={currentCountryName()}>
                        {matchingCountry?.name?.jsonValue?.value
                          ? matchingCountry?.name?.jsonValue?.value
                          : matchingCountry?.nameDefault?.jsonValue?.value}
                      </div>
                      <div className={currentCountryLanguage()}>
                        {matchingCountry?.languageName?.jsonValue?.value}
                      </div>
                    </div>
                    <GoogleMaterialSymbol
                      className={countryDropDownIcon()}
                      icon={openCountryWrapper ? 'arrow_drop_up' : 'arrow_drop_down'}
                    />
                  </div>
                </div>
              ) : (
                <div className={countryWrapperInner()}>
                  <div className={flagIconWrapper()}>
                    {matchingCountry?.flagIcon?.jsonValue?.value?.src ? (
                      <ImageWrapper field={matchingCountry?.flagIcon?.jsonValue} />
                    ) : (
                      <div className={logoPlaceholder()}></div>
                    )}
                  </div>
                  <div className={currentCountry()}>
                    <div className={countryInformation()}>
                      <div className={currentCountryName()}>
                        {matchingCountry?.name?.jsonValue?.value
                          ? matchingCountry?.name?.jsonValue?.value
                          : matchingCountry?.nameDefault?.jsonValue?.value}
                      </div>
                      <div className={currentCountryLanguage()}>
                        {matchingCountry?.languageName?.jsonValue?.value}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {openCountryWrapper && (
                <div className={countryListWrapper()}>
                  <div className={countryListInnerWrapper()}>
                    <div className={countryListTop()}>
                      <div className={countryListHeading()}>{title?.jsonValue?.value}</div>
                    </div>
                    <div className={countryListBottom()}>
                      {regions?.targetItems?.map(
                        (country: Countrylist & NameDefault, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              {country?.countries?.targetItems?.length !== 0 &&
                                (country?.name?.jsonValue || country?.nameDefault?.jsonValue) && (
                                  <div className={countryListCols()}>
                                    <div className={countryListCol()}>
                                      <Text
                                        className={countryName()}
                                        encode={false}
                                        field={
                                          country?.name?.jsonValue
                                            ? country?.name?.jsonValue
                                            : country?.nameDefault?.jsonValue
                                        }
                                        tag="p"
                                      />
                                    </div>
                                    <div className={countryList()}>
                                      {country?.countries?.targetItems?.map(
                                        (countryItem: Countrylist, index: number) => {
                                          const matchingCountryItemName = matchingCountry?.name
                                            ?.jsonValue
                                            ? matchingCountry?.name?.jsonValue
                                            : matchingCountry?.nameDefault?.jsonValue;
                                          const countryItemName = countryItem?.name?.jsonValue
                                            ? countryItem?.name?.jsonValue
                                            : countryItem?.nameDefault?.jsonValue;
                                          const isMatchingCountry =
                                            matchingCountryItemName?.value ===
                                              countryItemName?.value &&
                                            matchingCountry?.languageName?.jsonValue?.value ===
                                              countryItem?.languageName?.jsonValue?.value;

                                          const { countryItemLinkWrapper } = tailwindVariants({
                                            matchingCountry: isMatchingCountry,
                                          });

                                          // use interanl links to conrol languge switching. external links just redirect to sites we haven't built yet.
                                          const linkField = countryItem?.link?.jsonValue;
                                          if (linkField?.value?.linktype == 'internal') {
                                            linkField.value.href = '';
                                          }

                                          return (
                                            <>
                                              {countryItem?.link?.jsonValue?.value?.text && (
                                                <div
                                                  className={countryItemLinkWrapper()}
                                                  key={index}
                                                >
                                                  <LinkWrapper
                                                    aria-label={`Set Country and Language to ${countryItemName?.value} ${countryItem?.languageName?.jsonValue?.value}`}
                                                    className={countryItemLink()}
                                                    field={countryItem?.link?.jsonValue}
                                                    showLinkTextWithChildrenPresent={false}
                                                    target={
                                                      countryItem?.link?.jsonValue?.value?.target
                                                    }
                                                    locale={countryItem?.languageCode?.jsonValue?.value?.toLocaleLowerCase()}
                                                  >
                                                    <div className={countryListItemLinkleft()}>
                                                      {countryItem?.flagIcon?.jsonValue?.value
                                                        ?.src && (
                                                        <>
                                                          <ImageWrapper
                                                            field={countryItem?.flagIcon?.jsonValue}
                                                          />
                                                        </>
                                                      )}
                                                      <div
                                                        className={countryListItemLinkleftInfo()}
                                                      >
                                                        <Text
                                                          className={countryListItemLinkleftInfoTitle()}
                                                          encode={false}
                                                          field={countryItemName}
                                                          tag="p"
                                                        />
                                                        <span
                                                          className={countryListItemLinkleftInfoSubTitle()}
                                                        >
                                                          {
                                                            countryItem?.languageName?.jsonValue
                                                              ?.value
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className={countryListRight()}>
                                                      <div className={countrySelection()}>
                                                        <>
                                                          <GoogleMaterialSymbol
                                                            className={`${
                                                              isMatchingCountry
                                                                ? 'fill-color-system-success-400'
                                                                : countryListRightIcon()
                                                            } `}
                                                            icon="done"
                                                          />
                                                        </>
                                                      </div>
                                                    </div>
                                                  </LinkWrapper>
                                                </div>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderDesktop;
