// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import fallback from 'lib/fallback/fallback';
import { tailwindVariants } from './ContactUsPromoTailwind';

export type ContactUsPromoProps = ComponentProps & DartComponents.ContactUs.ContactUsPromo;

type AlignmentType = 'Center' | 'Left' | undefined;

type BgColorType = 'BrandColor' | 'Default' | 'Light' | undefined;

const ContactUsPromo = (props: ContactUsPromoProps): JSX.Element => {
  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.componentVariants?.value;
  const fallbackComponentVariantType = fallback?.componentVariants?.type;
  const {
    description,
    title,
    primaryCTA,
    secondaryCTA,
    secondaryCTAColor,
    secondaryCTAType,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};
  const { alignContent, backgroundColor } = props?.params || {};
  const { base, componentBG, contentContainer, headlineText, descriptionText, ctaContainer } =
    tailwindVariants({
      alignment: alignContent as AlignmentType,
      background: backgroundColor as BgColorType,
    });
  if (!props.fields) return <>ContactUsPromo Component</>;
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div
      className={componentBG()}
      data-component="authorable/contactuspromo"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container fullWidth>
        <div className={base()}>
          <div className={contentContainer()}>
            {title?.value && (
              <Text className={headlineText()} encode={false} tag="h2" field={title} />
            )}

            {description?.value && (
              <RichTextA11yWrapper className={descriptionText()} field={description} />
            )}
          </div>
          <div className={ctaContainer()}>
            {/* The design requires an outline CTA but field name is primaryCTA, 
                    so for that we have added a fallback as an outline value, 
                    so if there is no value in sitecore field, it will take the outline value */}
            {primaryCTA?.value?.text && primaryCTA?.value?.href && (
              <Button
                color={
                  backgroundColor === 'BrandColor'
                    ? primaryCTAColor?.value || 'white'
                    : primaryCTAColor?.value || fallbackComponentVariantColor
                }
                label={primaryCTA.value.text}
                title={primaryCTA?.value?.title}
                type={primaryCTAType?.value || fallbackComponentVariantType}
                tag="a"
                href={primaryCTA.value.href}
                target={primaryCTA?.value?.target}
              />
            )}
            {secondaryCTA?.value?.text && secondaryCTA?.value?.href && (
              <Button
                //As the design requires a filled CTA, we do not need to add a fallback since
                // the button component has a default variant that displays a filled CTA but here we have backgroundColor
                // condition so we need to add filled as a fallback
                color={
                  backgroundColor === 'BrandColor'
                    ? secondaryCTAColor?.value || 'white'
                    : secondaryCTAColor?.value
                }
                label={secondaryCTA.value.text}
                title={secondaryCTA?.value?.title}
                type={secondaryCTAType?.value || 'filled'}
                tag="a"
                href={secondaryCTA.value.href}
                target={secondaryCTA?.value?.target}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ContactUsPromo;
