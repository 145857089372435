// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

// Tailwind variables
export const tailwindVariants = tv({
  slots: {
    base: [
      'flex',
      'gap-components-promo-spacing-small-content-space-between',
      'items-center',
      'justify-start',
      'relative',
      'left',
      'pt-components-promo-spacing-small-padding-image-top',
      'pb-components-promo-spacing-small-padding-y',
      'md:gap-components-promo-spacing-large-content-space-between',
      'md:py-components-promo-spacing-large-padding-y',
      'md:px-components-promo-spacing-large-padding-x',
    ],
    componentBG: [],
    containerBrandSpecific: [],
    contentBlock: [
      'flex',
      'flex-col',
      'md:gap-components-promo-spacing-large-title-margin-bottom',
      'gap-components-promo-spacing-small-title-margin-bottom',
      'justify-start',
      'self-stretch',
      'shrink-0',
      'relative',
    ],
    contentContainer: [
      'flex',
      'flex-col',
      'gap-components-promo-spacing-large-margin-y',
      'items-start',
      'justify-start',
      'self-stretch',
      'shrink-0',
      'relative',
    ],
    ctaContainer: [
      'flex',
      'flex-row',
      'flex-wrap',
      'gap-components-promo-spacing-large-buttons-space-between',
      'md:gap-themes-general-card-spacing-small-button-space-between',
      'items-start',
      'justify-start',
      'shrink-0',
      'relative',
    ],
    dropdownContainer: ['flex', 'relative', 'w-full'],
    dropdownContainerDropdown: [
      'flex',
      'items-center',
      'justify-between',
      'w-full',
      'h-[48px]',
      'py-components-dropdown-input-padding-y',
      'pl-components-dropdown-input-padding-x',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-components-dropdown-label-text-default',
      'border',
      'border-components-dropdown-border-default',
      'bg-components-dropdown-bg',
      'rounded-themes-spacing-radius-large-form-field-input',
      'outline-none',
      'focus:border-2',
      'focus:border-components-dropdown-border-focused',
      'rounded-themes-spacing-radius-large-form-field-input',
    ],
    dropdownIcon: ['h-[48px]', 'w-[48px]', 'justify-center', 'flex', 'items-center'],
    dropdownMenuContainer: [
      'absolute',
      'top-full',
      'w-full',
      'bg-components-dropdown-bg',
      'rounded-components-header-spacing-large-dropdown-radius',
      'py-spacing-spacing-2',
      'z-10',
    ],
    dropdownMenuItem: [
      'px-components-dropdown-item-padding-x',
      'py-components-dropdown-item-padding-y',
      'cursor-pointer',
      'bg-components-dropdown-item-bg-default',
      'hover:bg-components-dropdown-item-bg-hover',
    ],
    descriptionContainer: ['text-left', 'relative', 'self-stretch'],
    descriptionContainerText: [
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
    ],
    headlineContainer: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'relative',
      'self-stretch',
      'text-header-small-medium',
      'text-left',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
    ],
    labelContainer: ['[&>div]:!mb-0'],
    mediaContainer: [
      'flex',
      'flex-col',
      'gap-0',
      'items-center',
      'justify-center',
      'md:w-1/2',
      'relative',
      'overflow-hidden',
      '[&>div]:!w-full',
      '[&>img]:rounded-tl-components-promo-spacing-small-image-radius-tl',
      '[&>img]:rounded-tr-components-promo-spacing-small-image-radius-tr',
      '[&>img]:rounded-bl-components-promo-spacing-small-image-radius-bl',
      '[&>img]:rounded-br-components-promo-spacing-small-image-radius-br',
      '[&>img]:md:rounded-tl-components-promo-spacing-large-image-radius-tl',
      '[&>img]:md:rounded-tr-components-promo-spacing-large-image-radius-tr',
      '[&>img]:md:rounded-bl-components-promo-spacing-large-image-radius-bl',
      '[&>img]:md:rounded-br-components-promo-spacing-large-image-radius-br',
      'w-full',
      'py-components-promo-spacing-small-padding-image-y',
      'px-components-promo-spacing-small-padding-image-x',
      'md:px-0',
      'box-border',
    ],
    wrapper: [
      'py-components-promo-spacing-small-padding-content-y',
      'px-components-promo-spacing-small-padding-content-x',
      'md:py-components-promo-spacing-large-padding-content-condensed-y',
      'md:px-components-promo-spacing-large-padding-content-condensed-x',
      'lg:py-components-promo-spacing-large-padding-content-y',
      'lg:px-components-promo-spacing-large-padding-content-x',
      'flex',
      'flex-col',
      'md:gap-components-promo-spacing-large-margin-y',
      'gap-components-promo-spacing-small-margin-y',
      'items-start',
      'justify-center',
      'relative',
      'box-border',
    ],
    disclaimerContent: [],

    // alternate
    altcontainerBrandSpecific: [
      'md:py-components-promo-spacing-large-padding-y',
      'md:px-components-promo-spacing-large-padding-x',
    ],
    altBase: [
      'flex',
      'gap-components-promo-spacing-small-content-space-between',
      'items-center',
      'justify-start',
      'relative',
      'left',
      'md:rounded-components-promo-spacing-large-content-radius',
      'md:gap-components-promo-spacing-large-content-space-between',
    ],
    altWrapper: [
      'py-components-promo-spacing-small-padding-content-y',
      'px-components-promo-spacing-small-padding-content-x',
      'md:py-components-promo-alternate-spacing-large-padding-y',
      'md:px-components-promo-alternate-spacing-large-padding-x',
      'flex',
      'flex-col',
      'md:gap-components-promo-spacing-large-margin-y',
      'gap-components-promo-spacing-small-margin-y',
      'items-start',
      'justify-center',
      'relative',
      'box-border',
    ],
    altMediaContainer: [
      'flex',
      'flex-col',
      'gap-0',
      'items-center',
      'justify-center',
      'md:w-1/2',
      'relative',
      'overflow-hidden',
      '[&>div]:!w-full',
      '[&>img]:rounded-tl-components-promo-alternate-spacing-small-image-radius-tl',
      '[&>img]:rounded-tr-components-promo-alternate-spacing-small-image-radius-tr',
      '[&>img]:rounded-bl-components-promo-alternate-spacing-small-image-radius-bl',
      '[&>img]:rounded-br-components-promo-alternate-spacing-small-image-radius-br',
      '[&>img]:md:rounded-tl-components-promo-alternate-spacing-large-image-radius-tl',
      '[&>img]:md:rounded-tr-components-promo-alternate-spacing-large-image-radius-tr',
      '[&>img]:md:rounded-bl-components-promo-alternate-spacing-large-image-radius-bl',
      '[&>img]:md:rounded-br-components-promo-alternate-spacing-large-image-radius-br',
      'w-full',
      'md:py-components-promo-alternate-spacing-large-padding-image-y',
      'md:px-components-promo-alternate-spacing-large-padding-image-y',
      'pt-components-promo-alternate-spacing-small-padding-image-top',
      'px-components-promo-alternate-spacing-small-padding-image-x',
      'py-components-promo-alternate-spacing-small-padding-image-y',
      'box-border',
    ],
    altComponentBG: [],
  },
  variants: {
    alignment: {
      Center: {
        base: ['flex-col-reverse'],
        contentBlock: ['md:items-center'],
        contentContainer: ['md:items-center'],
        ctaContainer: ['md:flex', 'md:justify-center', 'md:w-full'],
        descriptionContainer: ['md:text-center'],
        descriptionContainerText: ['md:text-center'],
        headlineContainer: ['md:text-center', 'md:justify-center'],
        labelContainer: ['md:flex', 'md:justify-center', 'md:w-full'],
        mediaContainer: ['md:!w-[41%]'],
        wrapper: ['w-full', 'md:items-center'],
        dropdownContainer: ['md:w-[25%]'],
        disclaimerContent: ['md:self-center', 'self-start'],

        // alternate
        altBase: ['flex-col-reverse'],
        altWrapper: [
          'w-full',
          'md:items-center',
          'md:!pt-0',
          'md:!py-components-promo-alternate-spacing-large-padding-content-y',
          'md:!px-components-promo-alternate-spacing-large-padding-content-x',
        ],
        altMediaContainer: ['md:!w-[41%]'],
      },
      Left: {
        base: ['md:flex-row', 'flex-col-reverse'],
        wrapper: ['md:w-1/2', 'w-full'],
        dropdownContainer: ['md:w-[50%]'],

        // alternate
        altBase: ['md:flex-row', 'flex-col-reverse'],
        altWrapper: [
          'md:w-1/2',
          'w-full',
          'md:!py-components-promo-alternate-spacing-large-padding-content-y',
          'md:!px-components-promo-alternate-spacing-large-padding-content-x',
        ],
      },
      Right: {
        base: ['md:flex-row-reverse', 'flex-col-reverse'],
        wrapper: ['md:w-1/2', 'w-full'],
        dropdownContainer: ['md:w-[50%]'],

        // alternate
        altBase: ['md:flex-row-reverse', 'flex-col-reverse'],
        altWrapper: [
          'md:w-1/2',
          'w-full',
          'md:!py-components-promo-alternate-spacing-large-padding-content-y',
          'md:!px-components-promo-alternate-spacing-large-padding-content-x',
        ],
      },
    },
    background: {
      BrandColor: {
        base: 'bg-components-promo-color-brand-bg',
        componentBG: 'bg-components-promo-color-brand-bg',
        descriptionContainerText: [
          'md:text-bodySans-medium',
          'text-bodySans-medium',
          'text-components-promo-color-brand-body',
        ],
        headlineContainer: ['text-components-promo-color-brand-title'],
        disclaimerContent: '!text-components-promo-color-brand-body',

        // alternate
        altComponentBG: [
          'bg-components-promo-color-brand-content-bg',
          'md:!bg-components-promo-color-default-bg',
        ],
        altBase: 'bg-components-promo-color-brand-content-bg',
      },
      Default: {
        base: 'bg-components-promo-color-default-bg',
        componentBG: 'bg-components-promo-color-default-bg',
        descriptionContainerText: [
          'md:text-bodySans-medium',
          'text-bodySans-medium',
          'text-components-promo-color-default-body',
        ],
        headlineContainer: ['text-components-promo-color-default-title'],

        // alternate
        altComponentBG: [
          'bg-components-promo-color-default-content-bg',
          'md:!bg-components-promo-color-default-bg',
        ],
        altBase: '!bg-components-promo-color-default-content-bg',
      },
      Light: {
        base: 'bg-components-promo-color-light-bg',
        componentBG: 'bg-components-promo-color-light-bg',
        descriptionContainerText: [
          'text-bodySans-medium',
          'md:text-bodySans-medium',
          'text-components-promo-color-light-body',
        ],
        headlineContainer: ['text-components-promo-color-light-title'],

        // alternate
        altComponentBG: [
          'bg-components-promo-color-light-content-bg',
          'md:!bg-components-promo-color-default-bg',
        ],
        altBase: 'bg-components-promo-color-light-content-bg',
      },
    },
    media: {
      video: {
        mediaContainer: ['aspect-video'],
        altMediaContainer: ['aspect-video'],
      },
      image: {
        mediaContainer: ['aspect-[4/3]'],
      },
    },
    brand: {
      ...themeVariants,
      Corporate: {
        containerBrandSpecific: [],
        wrapper: [
          'md:!py-spacing-padding-large-3',
          'md:!px-components-promo-spacing-large-padding-content-condensed-x',
        ],
        headlineContainer: [
          'md:!font-header-large-small',
          'md:!leading-header-large-small',
          'md:!text-header-large-small',
        ],
      },
      Baygon: {},
    },
  },
  compoundVariants: [
    {
      brand: 'Corporate',
      background: 'BrandColor',
      className: {
        base: [
          '!bg-components-promo-color-brand-content-bg',
          'md:!bg-components-promo-color-brand-bg',
        ],
        componentBG: [
          'md:bg-components-promo-color-brand-bg',
          'bg-components-promo-color-brand-content-bg',
        ],
        wrapper: [
          'md:rounded-components-promo-spacing-large-content-radius',
          'bg-components-promo-color-brand-content-bg',
        ],
        labelContainer: [
          'bg-components-promo-color-brand-content-bg',
          'hover:text-components-label-color-on-white-label-label-default',
        ],
        mediaContainer: [
          'md:rounded-components-promo-spacing-large-content-radius',
          'md:!bg-transparent',
          'bg-components-promo-color-brand-content-bg',
        ],
      },
    },
    {
      brand: 'Corporate',
      background: 'Default',
      className: {
        base: [
          '!bg-components-promo-color-default-content-bg',
          'md:!bg-components-promo-color-default-bg',
        ],
        componentBG: [
          'md:bg-components-promo-color-default-bg',
          'bg-components-promo-color-default-content-bg',
        ],
        wrapper: [
          'md:rounded-components-promo-spacing-large-content-radius',
          'bg-components-promo-color-default-content-bg',
        ],
        labelContainer: ['bg-components-promo-color-default-content-bg'],
        disclaimerContent: '!text-components-promo-color-default-body',
        mediaContainer: [
          'md:rounded-components-promo-spacing-large-content-radius',
          'md:!bg-transparent',
          'bg-components-promo-color-default-content-bg',
        ],
      },
    },
    {
      brand: 'Corporate',
      background: 'Light',
      className: {
        base: [
          '!bg-components-promo-color-light-content-bg',
          'md:!bg-components-promo-color-light-bg',
        ],
        componentBG: [
          'md:bg-components-promo-color-light-bg',
          'bg-components-promo-color-light-content-bg',
        ],
        wrapper: [
          'md:rounded-components-promo-spacing-large-content-radius',
          'bg-components-promo-color-light-content-bg',
        ],
        labelContainer: [
          'bg-components-promo-color-light-content-bg',
          'text-components-label-color-on-white-label-label-hover',
          '!border-components-label-color-on-color-label-label-default',
          'hover:!bg-components-label-color-on-color-background-bg-hover',
          'hover:!text-components-label-color-on-white-label-label-default',
        ],
        disclaimerContent: '!text-components-promo-color-brand-body',
        mediaContainer: [
          'md:rounded-components-promo-spacing-large-content-radius',
          'md:!bg-transparent',
          'bg-components-promo-color-light-content-bg',
        ],
      },
    },
    {
      brand: 'Corporate',
      alignment: 'Center',
      className: {
        mediaContainer: [
          'md:mb-components-promo-spacing-large-content-space-between',
          '[&>img]:!rounded-themes-radius-small-image-card',
        ],
        wrapper: [
          '!w-full',
          'md:!min-h-[47vh]',
          'md:!pt-components-promo-spacing-large-padding-content-condensed-x',
        ],
        disclaimerContent: ['md:self-center', 'self-start'],
      },
    },
    {
      brand: 'Corporate',
      media: 'image',
      className: {
        mediaContainer: ['[&>img]:!aspect-[5/4]', 'px-5'],
        altMediaContainer: ['[&>img]:!aspect-[5/4]', 'px-5'],
      },
    },
    {
      brand: 'Corporate',
      alignment: 'Left',
      className: {
        mediaContainer: [
          'md:ml-components-promo-spacing-large-content-space-between',
          '[&>img]:!rounded-themes-radius-small-image-card',
        ],
        wrapper: ['md:!min-h-[570px]', 'md:!min-w-[543px]'],

        // alternate
        altBase: ['md:flex-row', 'flex-col-reverse'],
      },
    },
    {
      brand: 'Corporate',
      alignment: 'Right',
      className: {
        mediaContainer: [
          'md:mr-components-promo-spacing-large-content-space-between',
          '[&>img]:!rounded-themes-radius-small-image-card',
        ],
        wrapper: ['md:!min-h-[570px]', 'md:!min-w-[543px]'],

        // alternate
        altBase: ['md:flex-row-reverse', 'flex-col-reverse'],
      },
    },
    {
      brand: 'Baygon',
      background: 'BrandColor',
      className: {
        labelContainer: [
          '!bg-components-label-color-on-white-background-bg-default',
          'hover:!bg-components-label-color-on-white-background-bg-hover',
          'hover:!text-components-label-color-on-white-label-label-hover',
        ],
      },
    },
    {
      brand: 'Baygon',
      background: 'Light',
      className: {
        labelContainer: [
          '!bg-components-label-color-on-white-background-bg-default',
          'hover:!bg-components-label-color-on-white-background-bg-hover',
        ],
      },
    },
    {
      brand: 'Baygon',
      background: 'Default',
      className: {
        labelContainer: [
          '!bg-components-label-color-on-white-background-bg-default',
          'hover:!bg-components-label-color-on-white-background-bg-hover',
        ],
      },
    },
  ],
});
