// Global
import { tv } from 'tailwind-variants';

// Tailwind variables
export const tailwindVariants = tv({
  slots: {
    componentBG: [],
    base: [
      // 'md:px-themes-general-surface-spacing-large-padding-x', // temporary fix
      'md:py-themes-general-surface-spacing-large-padding-x',
      'md:py-spacing-padding-medium-6', // temporary fix, will update this with above commented tokens
      // 'md:px-spacing-padding-medium-6', // temporary fix, will update this with above commented tokens
      'px-themes-general-surface-spacing-small-padding-x',
      'py-components-media-gallery-spacing-small-padding-y',
      'flex',
      'flex-col',
      'gap-2',
      'items-start',
      'justify-start',
      'relative',
      'overflow-hidden',
    ],
    contentWrapper: [
      'flex',
      'flex-col',
      'gap-themes-general-surface-spacing-large-title-margin-bottom',
      'items-start',
      'justify-start',
      'self-stretch',
      'relative',
    ],
    iconContainer: ['flex', 'flex-row', 'gap-spacing-spacing-5'],
    socialIcon: [
      'fill-components-button-color-filled-brand-default-bg',
      'active:fill-components-footer-brand-color-social-icon-pressed',
      'duration-300',
      'hover:!fill-components-share-list-color-icon-hover',
      '[&_svg]:!h-[32px]',
      '[&_svg]:!w-[32px]',
    ],
    headlineText: [
      'font-header-small-large',
      'leading-header-small-large',
      'md:text-header-large-small',
      'sm:font-header-large-large',
      'sm:font-header-large-large',
      'sm:leading-header-large-large',
      'text-header-large-xSmall',
    ],
    headlineAlignment: [
      'flex',
      'md:flex-row',
      'flex-col',
      'justify-between',
      'gap-spacing-spacing-6',
      'items-center',
      'md:px-spacing-padding-large-1',
      'sm:px-spacing-padding-large-7',
      'px-spacing-padding-large-1',
      'self-stretch',
    ],
  },
});
