// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { tailwindVariants } from './ArticleCalloutTailwind';

export type ArticleCalloutProps = ComponentProps &
  ProductAndArticleComponent.ArticleCallout.ArticleCallout;

const ArticleCallout = (props: ArticleCalloutProps): JSX.Element => {
  if (!props.fields) return <></>;

  const articleCallout = props.fields;
  const { title, description, wrapper } = tailwindVariants();
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <Container id={id ? id : undefined}>
      <div className={wrapper()} data-component="authorable/articlecallout" tabIndex={id ? -1 : 1}>
        <Text className={title()} encode={false} field={articleCallout.title} tag="h2" />
        <RichTextA11yWrapper className={description()} field={articleCallout.description} />
      </div>
    </Container>
  );
};

export default ArticleCallout;
