// Global
import { tv } from 'tailwind-variants';

export const tailwindVariantsBrandCard = tv({
  defaultVariants: {
    grid: '6column',
  },
  slots: {
    componentBG: ['bg-components-tab-bg-2'],
    base: [
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'px-themes-general-surface-spacing-small-padding-x',
      'py-components-media-gallery-spacing-small-padding-y',
    ],
    contentWrapper: ['flex', 'flex-col', 'gap-spacing-spacing-7'],
    titleAndDescriptionWrapperOuter: [
      'w-full',
      'flex',
      'flex-col',
      'justify-between',
      'items-start',
      'self-stretch',
      'md:flex-row',
      'gap-spacing-spacing-7',
      'md:gap-spacing-spacing-0',
    ],
    titleAndDescriptionWrapperInner: ['flex', 'flex-col', 'flex-1'],
    titleText: [
      'font-header-large-medium',
      'leading-header-large-medium',
      'text-header-large-medium',
      'text-themes-text-color-dark',
    ],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-components-article-card-listing-color-default-copy',
      'mb-themes-general-surface-spacing-small-body-margin-bottom',
      'md:mb-0',
    ],
    brandContainer: ['brand-Container', 'flex', 'flex-col', 'gap-spacing-spacing-7', 'md:flex-row'],
    brandTitle: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'rounded-themes-spacing-radius-large-button',
      'text-components-button-group-color-button-fg-default',
      'cursor-pointer',
      'text-nowrap',
      'content-center',
      'h-11',
      'hover:bg-components-button-group-color-button-bg-hover',
      'pl-spacing-spacing-4',
      'px-components-dropdown-item-padding-x',
      'py-components-dropdown-item-padding-y',
    ],
    brandContainerLeftWrapper: [
      'brand-information-left',
      'md:min-w-52',
      'md:min-w-40',
      'flex',
      'flex-row',
      'md:flex-col',
      'self-stretch',
      'overflow-x-auto',
      'md:pb-spacing-spacing-0',
      'gap-spacing-spacing-1',
    ],
    brandContainerRightWrapper: [
      'brand-information-right',
      'justify-items-center',
      'md:justify-items-start',
      'self-center',
      'md:self-baseline',
      'grid',
      'gap-x-components-media-gallery-large-container-grid-gap-x',
      'gap-y-components-media-gallery-large-container-grid-gap-y',
    ],
    cardImageWrapper: [
      'justify-items-center',
      'md:justify-items-start',
      'content-center',
      'rounded-themes-spacing-radius-large-card',
      'hover:border',
      'hover:border-themes-radius-large-card',
      'hover:border-themes-color-stroke-brand2',
      'cursor-pointer',
      'bg-color-system-brands-bg',
      'hover:bg-color-system-brands-bg-hover',
    ],
    gradientMobile: ['absolute', 'w-3', 'h-12', 'right-5', 'max-md:block', 'hidden'],
    // Filter Tab component
    filterTabcomponentBG: ['bg-components-product-results-list-with-search-color-bg'],
    filterTabcontentWrapper: ['flex', 'flex-col'],
    filterTabBrandTitle: [
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'text-components-button-group-color-button-fg-default',
      'text-nowrap',
      'cursor-pointer',
      'rounded-themes-spacing-radius-large-button',
      'hover:bg-components-button-group-color-button-bg-hover',
      'min-h-12',
      'content-center',
      'py-components-button-spacing-large-default-padding-y',
      'px-components-button-spacing-large-default-padding-x',
    ],
    filterTabButtons: [
      'flex',
      'flex-row',
      'overflow-x-auto',
      'md:gap-x-components-button-group-spacing-space-between',
      'pt-spacing-spacing-5',
      'pb-components-product-results-list-with-search-spacing-large-ruleline-margin-top',
    ],
    filterTabContainer: [
      'filterTab-Container',
      'flex',
      'flex-col',
      'md:flex-row',
      'gap-spacing-spacing-7',
      'pt-spacing-spacing-7',
      'md:pt-themes-general-surface-spacing-large-margin-bottom',
    ],
    filterTabContainerWrapper: [
      'filterTab-Container-Wrapper',
      'grid',
      'grid-rows-1',
      'md:grid-cols-4',
      'w-full',
      'pb-themes-general-surface-spacing-large-margin-bottom',
      'gap-x-components-media-gallery-large-container-grid-gap-x',
      'gap-y-components-media-gallery-large-container-grid-gap-y',
    ],
    svgClassLarge: [
      'block',
      'lg:hidden',
      '[&>*]:h-full',
      '[&>*]:w-full',
      'fill-colors-accent-1-400',
    ],
    svgClassExtraLarge: [
      'hidden',
      'lg:block',
      '[&>*]:h-full',
      '[&>*]:w-full',
      'fill-colors-accent-1-400',
    ],
    cardWrapper: [
      'flex',
      'flex-col',
      'md:h-auto',
      'cursor-pointer',
      'hover:border',
      'rounded-themes-spacing-radius-large-card',
      'bg-components-product-card-color-content-bg',
      'hover:border-themes-radius-large-card',
      'hover:border-themes-color-stroke-brand2',
      'hover:rounded-tr-themes-spacing-radius-large-card',
      'hover:rounded-tl-themes-spacing-radius-large-card',
      '[&_img]:hover:bg-color-system-brands-bg-hover',
      '[&_h2]:hover:!text-themes-color-text-brand1',
      '[&_h2]:hover:underline',
    ],
    imageTab: [
      'w-full',
      'h-auto',
      '!overflow-hidden',
      'rounded-tr-themes-spacing-radius-large-card',
      'rounded-tl-themes-spacing-radius-large-card',
      'bg-color-system-brands-bg',
    ],
    filterTabContent: [
      'flex',
      'flex-col',
      'gap-components-product-card-spacing-large-title-margin-bottom',
      'px-components-product-card-spacing-large-padding-content-x',
      'py-components-product-card-spacing-large-padding-content-y',
    ],
    cardTitleText: [
      'w-fit',
      'font-header-large-xxSmall',
      'text-header-large-xxSmall',
      'leading-header-large-xxSmall',
      'text-components-product-card-color-title',
    ],
    cardDescriptionText: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'pb-1',
      'line-clamp-2',
      'text-components-product-card-color-description',
    ],
  },
  variants: {
    grid: {
      '5column': {
        brandContainerRightWrapper: ['md:grid-cols-5', 'grid-cols-2'],
      },
      '6column': {
        brandContainerRightWrapper: ['md:grid-cols-6', 'sm:grid-cols-3', 'grid-cols-1'],
      },
    },
  },
});
