const fallback = {
  componentVariants: {
    value: 'brand',
    type: 'outline',
  },
  // Todo: Need to change the name topBar to some generic name.
  topBar: {
    value: 'white',
    type: 'outline',
  },
};
export default fallback;
