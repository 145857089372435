// Global
import { GetStaticComponentProps, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import React from 'react';

// Local
import { BreadcrumbDataType } from './Breadcrumb.types';
import BreadcrumbQuery from './Breadcrumb.graphql';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { tailwindVariants } from './BreadcrumbTailwind';

const Breadcrumb = (staticProps: BreadcrumbDataType): JSX.Element => {
  const { ancestors, breadcrumbTitle } = staticProps?.staticProps?.currentPage || {};

  const { componentName, dataSource } = staticProps?.rendering || {};
  const {
    base,
    currentPageContainer,
    currentPageText,
    link,
    linkIcon,
    linkContainer,
    wrapper,
    innerWrapper,
    componentBG,
    gradient,
  } = tailwindVariants();

  return (
    <div className={componentBG()}>
      <Container>
        <div data-component="authorable/landmarks/breadcrumbs" data-testid="breadcrumbs">
          <div className={base()}>
            <nav className={wrapper()} aria-label="Breadcrumb">
              <ol className={innerWrapper()}>
                {ancestors
                  ?.slice()
                  .reverse()
                  .map((itm, index: React.Key | null | undefined) => {
                    let hideBreadcrumb = false;

                    itm?.disabledLinkNames?.names?.map((disitm) => {
                      if (disitm?.field?.disabled?.value === 'breadcrumb') hideBreadcrumb = true;
                    });

                    if (hideBreadcrumb) return;

                    return (
                      itm?.breadcrumbTitle?.jsonValue?.value && (
                        <li className={linkContainer()} key={index}>
                          <LinkWrapper
                            className={link()}
                            field={{
                              value: {
                                href: itm?.pageUrl?.link,
                                text: itm?.breadcrumbTitle?.jsonValue?.value,
                                title: itm?.breadcrumbTitle?.jsonValue?.value,
                              },
                            }}
                            gtmEvent={{
                              event: 'link',
                              type: 'breadcrumb',
                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                              'gtm.element.dataset.gtmComponentName': componentName,
                            }}
                          />
                          <GoogleMaterialSymbol
                            className={linkIcon()}
                            icon="chevron_right"
                            aria-hidden={true}
                          />
                        </li>
                      )
                    );
                  })}
                <li className={currentPageContainer()}>
                  <Text
                    className={currentPageText()}
                    encode={false}
                    field={{
                      value: breadcrumbTitle?.jsonValue?.value,
                    }}
                    tag="span"
                  />
                </li>
              </ol>
            </nav>
            <div
              className={gradient()}
              style={{
                background:
                  'linear-gradient(90deg, rgba(255, 255, 255, 0.00) -4.17%, #FFF 104.17%)',
              }}
            ></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Breadcrumb;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const result = await graphQLClient.request<unknown>(BreadcrumbQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
    itemID: layoutData?.sitecore?.route?.itemId,
  });
  return {
    staticProps: result,
  };
};
