// Global
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents, SearchComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { GetStaticComponentProps, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import SDSListingItemQuery from './SDSResultsListwithSearch.graphql';
import { SDSResultsListingItemName } from './SDSResultsListwithSearch.types';
import { sendGTMEvent } from '@next/third-parties/google';
import SDSListWithSearchWidget from 'components/SDSListWithSearch';

export type SDSResultsListProps = ComponentProps &
  SearchComponents.SdsResultsListWithSearch.SdsResultsListWithSearch & {
    SDSResultsListingItem: SDSResultsListingItemName;
  };

export type Badge = ItemEx & CardComponents.ProductCard.Badge;

const SDSResultsListwithSearch = (props: SDSResultsListProps): JSX.Element => {
  const [count, setCount] = useState(0);
  const { sitecoreContext } = useSitecoreContext();
  const router = useRouter();
  const path = useRealPathName();

  useEffect(() => {
    setCount((c) => c + 1);
  }, [router]);

  // Add ref for the GTM Event
  const hasFired = useRef(false);

  useEffect(() => {
    // Function to send the GTM event
    sendGTMEvent({ event: 'switcher', type: 'click', value: 'list' });
    hasFired.current = true;
  }, []); // Empty dependency array ensures it runs only once on mount

  if (!props?.fields) return <></>;

  const SDSResultsData = {
    resultsPerPage: props?.fields?.resultsPerPage,
    noSearchResult: props?.fields?.noSearchResult,
    filterLabel: props?.fields?.filterLabel,
    sortLabel: props?.fields?.sortLabel,
    filterButtonLabel: props?.fields?.filterButtonLabel,
    sortByLabel: props?.fields?.sortByLabel,
    primaryCTAColor: props?.fields?.primaryCTAColor,
    primaryCTAType: props?.fields?.primaryCTAType,
    listingPageName:
      sitecoreContext?.route?.templateId === 'de293f96-ac18-4eab-9d18-75c40703b1cd'
        ? sitecoreContext?.route?.name
        : props?.SDSResultsListingItem?.layout?.item?.ancestors[0]?.name,
  };

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <div>
      <Container>
        <div
          data-component="layout/sdsresultslistwithsearch"
          id={id ? id : undefined}
          tabIndex={id ? -1 : 1}
        >
          <SDSListWithSearchWidget {...SDSResultsData} key={path + count} rfkId={'rfkid_13'} />
        </div>
      </Container>
    </div>
  );
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const replacementString =
    layoutData?.sitecore?.context?.itemPath?.toString().substring(0, 7) || '';
  const currentLanguage = `/${layoutData?.sitecore?.context?.language?.toLocaleLowerCase()}/`;
  const result = await graphQLClient.request<unknown>(SDSListingItemQuery, {
    routePath:
      replacementString === currentLanguage
        ? layoutData?.sitecore?.context?.itemPath?.toString().replace(replacementString, '/')
        : layoutData?.sitecore?.context?.itemPath,
    language: layoutData?.sitecore?.context?.language,
    site: layoutData?.sitecore?.context?.site?.name,
  });
  return {
    SDSResultsListingItem: result,
  };
};

export default SDSResultsListwithSearch;
