import React, { useState, useEffect } from 'react';
import { tv } from 'tailwind-variants';

interface YouTubeThumbnailProps {
  videoId: string; // Ensure videoId is a string
}

const tailwindVariants = tv({
  slots: {
    mediaCard: ['w-full'],
  },
});

const YouTubeThumbnail: React.FC<YouTubeThumbnailProps> = ({ videoId }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    if (videoId) {
      const hdThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      const fallbackThumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

      // Check if HD thumbnail exists
      const img = new Image();
      img.src = hdThumbnail;
      // If the HD thumbnail is not available, the width of the image is 120px.
      // If the HD thumbnail is available, the width of the image is greater than 120px.
      img.onload = () => {
        if (img.width === 120) {
          const hqdefault = new Image();
          hqdefault.src = fallbackThumbnail;
          hqdefault.onload = () => {
            setThumbnailUrl(fallbackThumbnail);
          };
        } else {
          setThumbnailUrl(hdThumbnail);
        }
      }; // Use HD thumbnail if available
      img.onerror = () => setThumbnailUrl(fallbackThumbnail); // Fallback to hqdefault if not
    }
  }, [videoId]);

  const { mediaCard } = tailwindVariants();

  return (
    <div>
      {thumbnailUrl && (
        <img src={thumbnailUrl} alt="YouTube Video Thumbnail" className={mediaCard()} />
      )}
    </div>
  );
};

export default YouTubeThumbnail;
