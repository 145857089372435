// Global
import { sendGTMEvent } from '@next/third-parties/google';
import { Item, Placeholder, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import graphQLClientFactory from 'lib/graphql-client-factory';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents, Ingredients } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Video from 'helpers/Video/Video';
import { stripHtml } from 'lib/utils/regex';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import Button from 'helpers/Button/Button';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { IngredientQueryService } from 'lib/ingredient-service';
import IngredientQuery from '../GlossaryTab/Ingredient.graphql';
import { useTheme } from 'lib/context/ThemeContext';
import { tailwindVariants } from './AccordionTailwind';

type Alignment = 'left' | 'right' | undefined;

export type AccordionListProps = ComponentProps &
  CompositeComponents.Accordion.AccordionList & { staticProps?: RawGqlData[] };
export type AccordionList = CompositeComponents.Accordion.Accordion;

export type IngredientsDataType = Ingredients.Ingredient &
  Ingredients.Allergen &
  Ingredients.Fragrance;

export type IngredientIndex = Ingredients.IngredientIndex;

export type RawGqlData = {
  ingredients: IngredientData[];
};

export type IngredientData = {
  title: {
    value: string;
  };
  definition: {
    value: string;
  };
  active: {
    value: boolean;
  };
};

const Accordion = (props: AccordionListProps): JSX.Element => {
  const { themeName } = useTheme();

  /**
   * Check the configuration for the Accordion
   * Read Rendering parameter for option
   * 1. Accordion Can open at a time
   * 2. One Accordion Open at a time
   * 3. Multiple Accordion can open at the same Time
   */

  const { accordions, description, title, disclaimerText, CTA } = props?.fields ?? {};
  const ingredientLength = props?.staticProps || '';

  const {
    enableRTL: alignmentParam,
    expandedFirstAccordionOnPageLoad: expandedFirstAccordionOnPageLoadParam,
    openMultipleAccordionAtSameTime,
  } = props?.params ?? {};

  // Check Rendering Parameter for Accordion Configuration
  const alignment = alignmentParam === '1' ? 'right' : 'left';
  const expandedFirstAccordionOnPageLoad = expandedFirstAccordionOnPageLoadParam ? 0 : -1;

  const {
    base,
    componentHeaderContentWrapper,
    componentDescription,
    componentTitle,
    pane,
    paneBody,
    paneHeader,
    paneBodyMediaWrapper,
    paneTitle,
    paneTitleButton,
    paneTitleIcon,
    paneTitleText,
    paneWrapper,
    videoClasses,
    wrapper,
    backgroundBG,
    disclaimerContentClasses,
    disclaimerClasses,
    themeBasedContainerClass,
    glossaryTabContainer,
    ingredientContainer,
    accordionTabContentHeader,
    ingredientTitle,
  } = tailwindVariants({
    alignment: alignment as Alignment,
    ingredientLength: (ingredientLength?.length > 0) as boolean,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  // Set State
  const [activeIndexes, setActiveIndexes] = useState<number[]>([expandedFirstAccordionOnPageLoad]);

  /**
   * handleAccordionClick function is to handle Accordion.
   * 1. It can open one at a time
   * 2. It can open multiple at a time
   * @param index : to manage accordion item
   * @param openMultipleAccordionAtSameTime : pass accordion configuration
   */

  const handleAccordionClick = (
    index: number,
    openMultipleAccordionAtSameTime: string | undefined,
    id: string | undefined,
    name: string | undefined
  ) => {
    // Set Indexes for Accordion
    if (openMultipleAccordionAtSameTime) {
      setActiveIndexes([...activeIndexes, index]);
    } else {
      setActiveIndexes([index]);
    }

    // Filter indexes for Accordion
    if (activeIndexes.includes(index)) {
      sendGTMEvent({
        event: 'accordion',
        type: 'close',
        'gtm.element.dataset.gtmDatasourceId': id,
        'gtm.element.dataset.gtmComponentName': name,
      });
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
    } else {
      sendGTMEvent({
        event: 'accordion',
        type: 'open',
        'gtm.element.dataset.gtmDatasourceId': id,
        'gtm.element.dataset.gtmComponentName': name,
      });
    }
  };

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      className={backgroundBG()}
      data-component="authorable/accordion"
      id={id ? id : undefined}
      tabIndex={-1}
    >
      <Container className={themeBasedContainerClass()}>
        <div
          data-component="authorable/general/accordion"
          data-testid="accordion"
          className={base()}
        >
          <div className={wrapper()}>
            <div className={paneWrapper()}>
              {(title?.value || description?.value) && (
                <div className={paneHeader()}>
                  <div className={componentHeaderContentWrapper()}>
                    {title?.value && (
                      <div className={componentTitle()}>
                        <Text encode={false} field={title} tag="h2" />
                      </div>
                    )}
                    {description?.value && (
                      <div className={componentDescription()}>
                        <Text encode={false} field={description} tag="p" />
                      </div>
                    )}
                  </div>
                  {CTA?.value?.text &&
                    CTA?.value?.href &&
                    ((themeName as string) === 'Pyrel' ? (
                      <Button
                        label={CTA.value.text}
                        title={CTA?.value?.title}
                        type="outline"
                        color="tonal"
                        tag="a"
                        href={CTA.value.href}
                        target={CTA?.value?.target}
                      />
                    ) : (
                      <Button
                        label={CTA.value.text}
                        title={CTA?.value?.title}
                        type="outline"
                        tag="a"
                        href={CTA.value.href}
                        target={CTA?.value?.target}
                      />
                    ))}
                </div>
              )}
              {accordions?.map((accordion: AccordionList, index: number) => {
                const { paneBodyWrapper } = tailwindVariants({
                  isOpen: activeIndexes.includes(index) as boolean,
                });

                const { bottomDescription, topDescription, image, video, usePlaceholder } =
                  accordion?.fields ?? {};
                const { id, name } = (accordion as Item) || {};
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                const { useIngredients } = accordion?.fields as any;

                const phKey = `dartaccordionrow-${index}-{*}`;
                return (
                  <React.Fragment key={index}>
                    {/* TODO: components-accordion-spacing-large-item-item-radius is missing */}
                    <div className={pane()} data-id={index} key={index}>
                      <div className={paneTitle()}>
                        <button
                          aria-label={stripHtml(accordion?.fields?.accordionLabel?.value as string)}
                          type="button"
                          className={paneTitleButton()}
                          onClick={() =>
                            handleAccordionClick(index, openMultipleAccordionAtSameTime, id, name)
                          }
                          aria-expanded={activeIndexes.includes(index)}
                        >
                          <div className={paneTitleText()}>
                            <RichTextA11yWrapper field={accordion?.fields?.accordionLabel} />
                          </div>
                          <GoogleMaterialSymbol
                            className={paneTitleIcon()}
                            icon={activeIndexes.includes(index) ? 'expand_less' : 'expand_more'}
                          />
                        </button>
                      </div>
                      <div
                        id={`accordion-panel-${index}-${id}`}
                        aria-labelledby={`accordion-panel-${index}-${id}-label`}
                        aria-expanded={activeIndexes.includes(index)}
                        className={paneBodyWrapper()}
                      >
                        {/* For Glossary Component, added this condition when we have Ingredients available then render Ingredients else render usePlaceholder content used in general Accordion Content. */}
                        {useIngredients?.value ? (
                          <div className={glossaryTabContainer()}>
                            {accordion?.fields?.ingredients &&
                              accordion?.fields?.ingredients?.map(
                                (ingredientData: IngredientIndex) => {
                                  const ingredientIndexTitle = ingredientData?.fields?.title
                                    ?.value as string;
                                  return (
                                    <>
                                      {/* Display the folder title */}
                                      {ingredientIndexTitle && (
                                        <div className={accordionTabContentHeader()}>
                                          <RichTextA11yWrapper
                                            field={{
                                              value: ingredientIndexTitle,
                                            }}
                                          />
                                        </div>
                                      )}
                                      <div key={index} className="ingredientWrapper">
                                        {props?.staticProps &&
                                          props?.staticProps?.map(
                                            (item: RawGqlData, itemIndex: number) => {
                                              const filteredIngredients = item?.ingredients?.filter(
                                                (ingredient: IngredientData) => {
                                                  const ingredientTitle = ingredient?.title?.value;
                                                  if (!ingredientTitle || !ingredientIndexTitle)
                                                    return false;

                                                  // If ingredientIndexTitle is "0", match ingredients starting with 0-9
                                                  if (ingredientIndexTitle === '0') {
                                                    return /^[0-9]/.test(ingredientTitle);
                                                  }

                                                  // If ingredientIndexTitle is "Hashtag", match ingredients starting with any special character
                                                  if (ingredientIndexTitle === '#') {
                                                    return /^[^a-zA-Z0-9]/.test(ingredientTitle); // Regex to match titles starting with non-alphanumeric characters
                                                  }
                                                  // Otherwise, match ingredients that start with the ingredientIndexTitle alphabet
                                                  return ingredientTitle
                                                    .toLowerCase()
                                                    .startsWith(ingredientIndexTitle.toLowerCase());
                                                }
                                              );

                                              if (filteredIngredients.length === 0) return null;

                                              return (
                                                <ul
                                                  key={itemIndex}
                                                  className={`${ingredientContainer()} ${
                                                    filteredIngredients.length > 1
                                                      ? 'md:columns-3'
                                                      : 'md:columns-1'
                                                  }`}
                                                >
                                                  {filteredIngredients.map(
                                                    (ingredient: IngredientData, index: number) => (
                                                      <div
                                                        className={ingredientTitle()}
                                                        key={index}
                                                      >
                                                        <Text
                                                          field={{
                                                            value: ingredient?.title?.value,
                                                          }}
                                                          tag="li"
                                                        />
                                                      </div>
                                                    )
                                                  )}
                                                </ul>
                                              );
                                            }
                                          )}
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            {/* Disclamier */}
                            {accordion?.fields?.disclaimerText?.value != '' && (
                              <LegalDisclaimer
                                disclaimerText={accordion?.fields?.disclaimerText}
                                disclaimerClasses={disclaimerContentClasses()}
                              />
                            )}
                          </div>
                        ) : (
                          <div className={paneBody()}>
                            {usePlaceholder?.value ? (
                              <Placeholder key={index} name={phKey} rendering={props.rendering} />
                            ) : (
                              <>
                                {/* General Accordion throughout Brands */}
                                <RichTextA11yWrapper field={topDescription} />
                                {image?.value?.src ? (
                                  <div className={paneBodyMediaWrapper()}>
                                    <ImageWrapper field={image} />
                                  </div>
                                ) : (
                                  <>
                                    {video?.value && (
                                      <div className={paneBodyMediaWrapper()}>
                                        <Video class={videoClasses()} responsive field={video} />
                                      </div>
                                    )}
                                  </>
                                )}
                                <RichTextA11yWrapper field={bottomDescription} />
                                {accordion?.fields?.disclaimerText?.value !== '' && (
                                  <LegalDisclaimer
                                    disclaimerText={accordion?.fields?.disclaimerText}
                                    disclaimerClasses={disclaimerContentClasses()}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={disclaimerClasses()}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Accordion;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const fields = rendering?.fields?.accordions as CompositeComponents.Accordion.Accordion[];

  const isIngredients = fields[0].fields?.ingredients;

  if (isIngredients && isIngredients?.length > 0) {
    const graphQLClient = graphQLClientFactory({});
    const ingredientService = new IngredientQueryService<RawGqlData>(graphQLClient);
    const results = await ingredientService.fetch(IngredientQuery, {
      datasource: rendering.dataSource,
      language: layoutData?.sitecore?.context?.language,
    });
    return {
      staticProps: results,
    };
  } else {
    return {
      staticProps: [],
    };
  }
};
