// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import SVG from 'helpers/SVG/SVG';

export type MosquitoForecastNewsletterPopupProps = ComponentProps &
  DartComponents.MosquitoForecastNewsletterPopup.MosquitoForecastNewsletterPopup;

const tailwindVariants = tv({
  slots: {
    base: [
      'flex',
      'flex-col',
      'pt-spacing-spacing-4',
      'pr-spacing-spacing-5',
      'pb-spacing-spacing-4',
      'pl-spacing-spacing-4',
      'gap-spacing-margin-large-2',
      'justify-center',
      'items-start',
      'self-stretch',
      'rounded-themes-spacing-radius-large-image-card',
      'bg-components-promo-color-light-content-bg',
      'fixed',
      'right-0',
      'w-[354px]',
      'z-50',
      'bottom-4',
      'md:bottom-6',
    ],
    titleText: [
      'font-header-large-xSmall',
      'text-header-large-xSmall',
      'leading-header-large-xSmall',
      'text-components-promo-color-light-title',
      'text-balance',
    ],
    closeIcon: [
      '[&_svg]:fill-components-button-color-outline-brand-default-text',
      '[&_svg]:w-10',
      '[&_svg]:h-10',
      'absolute',
      'right-2',
      'top-2',
    ],
    bottomRightBug: ['absolute', 'bottom-0', 'right-0'],
  },
  variants: {
    showPromo: {
      false: {
        base: ['hidden'],
      },
      true: {
        base: ['block'],
      },
    },
  },
});

const MosquitoForecastNewsletterPopup = (
  props: MosquitoForecastNewsletterPopupProps
): JSX.Element => {
  const { title, primaryCTA, primaryCTAColor, primaryCTAType } = props?.fields || {};

  const [showPromo, setShowPromo] = useState(false);
  const { themeName } = useTheme();
  const { base, titleText, closeIcon, bottomRightBug } = tailwindVariants({ showPromo: showPromo });

  useEffect(() => {
    if (!localStorage.getItem('hidePromo')) setTimeout(() => setShowPromo(true), 1000);
  }, []);

  const onClosePromo = () => {
    setShowPromo(false);
    localStorage.setItem('hidePromo', 'true');
  };

  if (!props.fields) return <>MosquitoForecastPromo Popup Component</>;

  const id = props?.params?.RenderingIdentifier;

  return title?.value || primaryCTA?.value.href ? (
    <div
      className={base()}
      data-component="authorable/mosquitoforecastthankyouprops"
      id={id || undefined}
      tabIndex={id ? -1 : 1}
    >
      <button className={closeIcon()} onClick={onClosePromo} aria-label="Close Button">
        <GoogleMaterialSymbol icon="cancel" />
      </button>

      {title?.value && (
        <div className={titleText()}>
          <Text field={title} tag="h2" />
        </div>
      )}

      {primaryCTA?.value && (
        <LinkWrapper field={primaryCTA?.value} showLinkTextWithChildrenPresent={false}>
          <Button
            //As the design requires a filled CTA, we do not need to add a fallback since
            //the button component has a default variant that displays a filled CTA.
            label={primaryCTA?.value.text}
            target={primaryCTA?.value?.target}
            type={primaryCTAType?.value as string}
            color={primaryCTAColor?.value}
          />
        </LinkWrapper>
      )}

      <SVG className={bottomRightBug()} svg={`FloatingPromo/Bg=BottomRight,Brand=${themeName}`} />
    </div>
  ) : (
    <></>
  );
};

export default MosquitoForecastNewsletterPopup;
