// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  defaultVariants: {
    grid: '4column',
    iconRatio: 'Small',
  },
  slots: {
    base: [
      'flex-col',
      'flex',
      'items-center',
      'justify-center',
      'relative',
      'px-themes-general-surface-spacing-small-padding-x',
      'py-themes-general-surface-spacing-small-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
    ],
    componentBG: ['bg-components-icon-card-listing-color-bg', 'bg-no-repeat', 'bg-cover'],
    cardBodyContainer: ['flex', 'flex-col', 'h-full'],
    cardBodyTextContainer: [
      'flex',
      'flex-col',
      'flex-1',
      'gap-themes-general-card-spacing-large-title-margin-bottom',
      'mb-themes-general-card-spacing-large-body-margin-bottom',
    ],
    cardContainer: ['flex', 'flex-col'],
    cardCtaContainer: [
      'flex',
      'flex-wrap',
      'gap-themes-general-card-spacing-large-button-space-between',
    ],
    cardCtaPrimaryContainer: [],
    cards: ['flex-1'],
    cardIcon: [
      '[&>svg]:w-components-icon-card-dimensions-large-big-bg-width',
      '[&>svg]:h-components-icon-card-dimensions-large-big-bg-height',
    ],
    cardIconContainer: [
      'flex',
      'justify-center',
      'items-center',
      'mb-components-icon-card-spacing-small-icon-margin',
      'md:mb-components-icon-card-spacing-large-icon-margin',
      'min-h-components-icon-card-dimensions-large-big-bg-height',
      'w-components-icon-card-dimensions-large-big-bg-width',
      'rounded-themes-spacing-radius-large-icon',
    ],
    cardListContainer: [
      'grid',
      'w-full',
      'gap-themes-general-surface-spacing-large-cards-space-between',
      'md:gap-x-themes-general-surface-spacing-large-cards-space-between',
      'md:gap-y-components-icon-card-listing-spacing-large-card-margin-bottom',
      'sm:grid-cols-2',
    ],
    cardTitleText: [
      'font-header-small-xSmall',
      'text-header-small-xSmall',
      'text-components-icon-card-color-title',
      'leading-header-small-xSmall',
      'md:font-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'md:text-header-large-xSmall',
    ],
    cardSubtitleText: [
      'font-header-small-xxSmall',
      'text-header-small-xxSmall',
      'text-components-icon-card-color-subtitle',
      'leading-header-small-xxSmall',
      'md:font-header-large-xxSmall',
      'md:leading-header-large-xxSmall',
      'md:text-header-large-xxSmall',
      //'line-clamp-2', // Removing as of now as per https://horizontal.atlassian.net/browse/SJ-1120
      // line-clamp conflicts with the Capsize leading trim
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'pb-[3px]',
      'mb-[-3px]',
    ],
    cardDescriptionText: [
      'font-bodySans-small',
      'text-bodySans-small',
      'text-components-icon-card-color-description',
      'leading-bodySans-small',
      //'line-clamp-4', // Removing as of now as per https://horizontal.atlassian.net/browse/SJ-1120
      'flex-1',
      // line-clamp conflicts with the Capsize leading trim
      // these 2 classes resolve the issue and can be removed
      // when leading-trim gets implemented in browser spec
      // https://caniuse.com/?search=leading-trim
      'pb-[4px]',
      'mb-[-4px]',
    ],
    container: [],
    bottomCTAWrapper: [],
    ctaContainer: ['flex', 'items-center'],
    headerContainer: [
      'flex-wrap',
      'md:flex-nowrap',
      'flex',
      'justify-between',
      'mb-themes-general-surface-spacing-small-margin-bottom',
      'w-full',
      'md:mb-themes-general-surface-spacing-large-margin-bottom',
    ],
    headerTitleContainer: [
      'flex-col',
      'flex',
      'lg:mb-0',
      'mb-themes-general-surface-spacing-small-body-margin-bottom',
    ],
    headerTitleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-icon-card-listing-color-title',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
    ],
    headerDescriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-icon-card-listing-color-copy',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
    ],
    legalDisclaimerText: ['pt-spacing-spacing-5', 'self-start'],
    svgBgLeft: ['absolute', 'hidden', 'left-0', 'top-0', 'md:block'],
    svgBgRight: ['absolute', 'hidden', 'right-0', 'top-0', 'md:block'],
    wrapper: ['flex', 'flex-col', 'items-center', 'self-stretch', '!z-[1]'],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        bottomCTAWrapper: ['max-md:w-full'],
        ctaContainer: [
          'pt-themes-general-surface-spacing-small-button-margin-top',
          'md:pt-themes-general-surface-spacing-large-button-margin-top',
        ],
        headerContainer: ['!justify-center', 'text-center'],
      },
      TopRight: {
        headerContainer: ['md:gap-themes-general-surface-spacing-large-body-margin-right'],
      },
    },
    bgImageVariant: {
      true: {
        wrapper: [
          'bg-components-icon-card-listing-color-inner-bg',
          'rounded-themes-radius-small-general',
          'sm:rounded-themes-radius-large-general',
          'md:py-components-icon-card-listing-spacing-large-padding-inner-y',
          'md:px-components-icon-card-listing-spacing-large-padding-inner-x',
          'py-components-icon-card-listing-spacing-small-padding-inner-y',
          'px-components-icon-card-listing-spacing-small-padding-inner-x',
        ],
      },
      false: {},
    },
    grid: {
      '2column': {
        cardListContainer: ['md:grid-cols-2'],
      },
      '3column': {
        cardListContainer: ['md:grid-cols-3'],
      },
      '4column': {
        cardListContainer: ['md:grid-cols-4'],
      },
    },
    iconLocation: {
      SideLeft: {
        cardContainer: ['sm:flex-row', 'md:pr-components-icon-card-spacing-large-margin-right'],
        cardIconContainer: [
          'mr-components-icon-card-spacing-large-icon-margin',
          'sm:min-w-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:max-w-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:max-h-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:min-h-0',
        ],
        cardIcon: [
          'sm:[&>svg]:w-components-icon-card-dimensions-large-tiny-bg-width',
          'sm:[&>svg]:h-components-icon-card-dimensions-large-tiny-bg-height',
        ],
        cardListContainer: ['md:!grid-cols-2'],
      },
      TopCenter: {
        cardBodyContainer: ['justify-top', '[&>*]:text-center'],
        cardCtaContainer: ['justify-center', '[&>*]:text-center'],
        cardIconContainer: ['justify-center', 'self-center'],
        componentBG: ['bg-center', 'bg-no-repeat'],
      },
      TopLeft: {
        cardContainer: ['md:pr-components-icon-card-spacing-large-margin-right'],
      },
    },
    theme: {
      Dark: {
        componentBG: ['bg-components-icon-card-listing-color-brand-bg'],
        wrapper: [
          'bg-components-icon-card-listing-color-inner-bg',
          'px-components-icon-card-listing-spacing-small-padding-inner-x',
          'py-components-icon-card-listing-spacing-small-padding-inner-y',
          'md:px-components-icon-card-listing-spacing-large-padding-inner-x',
          'md:py-components-icon-card-listing-spacing-large-padding-inner-y',
          'rounded-themes-spacing-radius-small-general',
          'md:rounded-themes-spacing-radius-large-general',
        ],
        legalDisclaimerText: ['!text-components-icon-card-listing-color-inner-legal-text'],
      },
      Light: {
        legalDisclaimerText: ['text-components-promo-color-brand-body'],
      },
    },
    overrideSVGColor: {
      true: {
        cardIcon: '[&>svg>path]:fill-colors-brand-1-400',
      },
    },
    iconRatio: {
      Small: {
        cardIcon: [
          '[&>svg]:w-components-icon-card-dimensions-large-big-bg-width',
          '[&>svg]:h-components-icon-card-dimensions-large-big-bg-height',
        ],
      },
      Large: {
        cardIcon: [
          '[&>svg]:!w-[calc(theme(width.components-icon-card-dimensions-large-big-bg-width)*2)]',
        ],
        cardIconContainer: [
          '!w-[calc(theme(width.components-icon-card-dimensions-large-big-bg-width)*2)]',
          'min-h-[calc(theme(height.components-icon-card-dimensions-large-big-bg-height)*2)',
        ],
      },
    },
    brand: {
      ...themeVariants,
      Glade: {
        svgBgRight: ['!top-auto', '!bottom-0'],
      },
    },
  },
});
