// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    base: [
      'top-bar',
      'flex',
      'md:py-spacing-spacing-4',
      'py-spacing-spacing-3',
      'px-themes-general-surface-spacing-small-padding-x',
      'gap-components-banner-spacing-small-space-between',
    ],
    contentContainer: [
      'grow',
      'flex',
      'md:flex-row',
      'flex-col',
      'md:items-center',
      'items-start',
      'justify-center',
      'md:px-components-banner-spacing-large-content-padding-x',
      'gap-components-banner-spacing-small-content-margin-bottom',
    ],
    headlineText: [
      'font-bodySans-small-semibold',
      'leading-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'md:bodySans-medium-semibold',
      'md:leading-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
    ],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
    ],
    iconTitileWrapper: [
      'icon-titile-wrapper',
      'flex-row',
      'flex',
      'gap-components-banner-spacing-icon-margin-left',
      'items-center',
    ],
    closeButtonStyle: [
      'flex-none',
      'cursor-pointer',
      'ml-auto',
      'md:py-components-button-spacing-large-icon-only-padding-y',
      'md:px-components-button-spacing-large-icon-only-padding-x',
      'md:hover:rounded-themes-radius-large-button',
      'md:hover:bg-components-button-color-text-brand-hover-bg-icon-only',
    ],
    topBarIcon: [
      'icon',
      '[&_svg]:w-components-banner-spacing-icon-dimensions',
      '[&_svg]:h-components-banner-spacing-icon-dimensions',
    ],
    ctaSpacing: [
      '!py-components-button-spacing-compressed-default-padding-y',
      'pr-components-button-spacing-compressed-icon-right-padding-right',
      'pl-components-button-spacing-compressed-default-padding-x',
    ],
  },
  variants: {
    theme: {
      Dark: {
        base: ['bg-components-banner-color-bg-alert'],
        headlineText: ['text-components-banner-color-title-alert'],
        descriptionText: ['text-components-banner-color-body-alert'],
        topBarIcon: ['[&_svg_*]:fill-components-banner-color-icon-alert'],
      },
      Light: {
        base: ['bg-components-banner-color-bg-default'],
        headlineText: ['text-components-banner-color-title-default'],
        descriptionText: ['text-components-banner-color-body-default'],
        topBarIcon: ['[&_svg_*]:fill-components-banner-color-icon-default'],
      },
    },
  },
});
