// Global
import { tv } from 'tailwind-variants';

export const tailwindVariantsBrandCard = tv({
  defaultVariants: {},
  slots: {
    base: [
      'md:py-themes-general-surface-spacing-large-padding-x',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'py-components-accordion-spacing-small-padding-y',
      'px-components-accordion-spacing-small-padding-x',
    ],
    filterTabButtons: [
      'flex',
      'flex-row',
      'overflow-x-auto',
      'gap-components-button-group-spacing-space-between',
      'pb-spacing-spacing-7',
    ],
    filterTabBrandTitle: [
      'filterTabBrandTitle',
      'min-h-12',
      'content-center',
      'cursor-pointer',
      'text-components-button-group-color-button-fg-default',
      'text-bodySans-medium-semibold',
      'font-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'hover:bg-components-button-group-color-button-bg-hover',
      'rounded-components-button-group-spacing-border-radius',
      'py-components-button-spacing-large-default-padding-y',
      'px-components-button-spacing-large-default-padding-x',
    ],
    filterTabContainer: ['flex', 'flex-col', '[&>div>div>div]:!p-0'],
    svgClassLarge: ['block', 'lg:hidden'],
    svgClassExtraLarge: ['hidden', 'lg:block'],
  },
});
