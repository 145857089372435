// Global
import { Field, ImageField, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { stripHtml } from 'lib/utils/regex';
import { tailwindVariants } from './ArticleRelatedProductsTailwind';

export type ArticleRelatedProductsProps = ItemEx &
  ProductAndArticleComponent.Articles.ArticleInformation;

export type LabelInfo = ItemEx & Common.Label.Label;

const ArticleRelatedProducts = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const articleInfo = sitecoreContext?.route?.fields
    ?.articleInformation as ArticleRelatedProductsProps;
  const { base, componentTitle, contentWrapper, headlineText, image, labelText, textWrapper } =
    tailwindVariants();

  if (!articleInfo) return <></>;

  const articleRelatedProducts = articleInfo.fields.articleRelatedProducts;
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div
      className={base()}
      id={id ? id : undefined}
      tabIndex={-1}
      data-component="authorable/articlerelatedproducts"
    >
      <h2 className={componentTitle()}>
        <Text encode={false} field={articleInfo?.fields?.articleRelatedProductsHeadline} />
      </h2>
      <div>
        {articleRelatedProducts &&
          articleRelatedProducts.map((product) => {
            const { url, id } = product;
            const { name, headline, label, primaryImage } =
              product?.fields?.productInformation?.fields || {};
            return (
              <div className={contentWrapper()} key={id}>
                <div className={textWrapper()}>
                  {/* label */}
                  <>
                    {Array.isArray(label) &&
                      label?.map((item: LabelInfo, index: number) => {
                        return (
                          <Text
                            className={labelText()}
                            encode={false}
                            field={item?.fields?.title as Field<string>}
                            tag="p"
                            key={index}
                          />
                        );
                      })}
                  </>
                  {/* headline / link */}
                  <LinkWrapper
                    className={headlineText()}
                    field={{
                      value: {
                        href: url,
                        title: name,
                        text: headline?.value,
                      },
                    }}
                    aria-label={stripHtml(headline?.value as string)}
                  />
                </div>
                <div>
                  {/* image */}
                  {primaryImage && (
                    <ImageWrapper className={image()} field={primaryImage as ImageField} />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ArticleRelatedProducts;
