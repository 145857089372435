// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    base: [
      'bg-components-card-search-result-color-bg',
      'border-components-card-search-result-color-divider-line',
      'flex',
      'flex-col',
      'gap-spacing-spacing-5',
    ],
    componentTitle: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'sm:font-header-large-medium',
      'sm:text-header-large-medium',
      'sm:leading-header-large-medium',
      'text-components-product-card-color-title',
    ],
    contentWrapper: [
      'bg-components-card-search-result-color-bg',
      'border-b',
      'border-components-card-search-result-color-divider-line',
      'items-center',
      'grid',
      'grid-cols-2',
      'gap-components-card-search-result-spacing-small-image-margin-right',
      'sm:gap-components-card-search-result-spacing-large-image-margin-right',
      'py-components-card-search-result-spacing-small-padding-y',
      'sm:py-components-card-search-result-spacing-large-padding-y',
    ],
    textWrapper: [
      'flex',
      'flex-col',
      'gap-components-card-search-result-spacing-large-eyebrow-margin-bottom',
    ],
    headlineText: [
      'font-header-small-xSmall',
      'text-header-small-xSmall',
      'leading-header-small-xSmall',
      'sm:font-header-large-xSmall',
      'sm:text-header-large-xSmall',
      'sm:leading-header-large-xSmall',
      'text-components-product-card-color-title',
      'hover:text-themes-text-color-brand1',
      'hover:underline',
      'break-words',
    ],
    image: ['rounded-themes-radius-large-image-card', 'h-auto', 'aspect-[4/3]'],
    labelText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-card-search-result-color-body',
    ],
  },
});
