// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { stripHtml } from 'lib/utils/regex';
import { useI18n } from 'next-localization';
import { tailwindVariants } from './IconCardTailwind';

export type IconCardListProps = CardComponents.IconCard.IconCardList & {
  params: { [key: string]: string };
};

export type IconCardItem = CardComponents.IconCard.IconCardItem & {
  id?: string;
};

type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;
type IconLocation = 'SideLeft' | 'TopCenter' | 'TopLeft' | undefined;
type Grid = '2column' | '3column' | '4column' | undefined;
type Theme = 'Dark' | 'Light' | undefined;
type iConRatio = 'Large' | 'Small' | undefined;

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const IconCard = (props: IconCardListProps): JSX.Element => {
  const {
    description,
    iconCards,
    primaryCTA,
    primaryCTAColor,
    primaryCTAType,
    title,
    multiColorBar,
    disclaimerText,
    overrideSVGWithBrandColor,

    enablePattern,
  } = props?.fields || {};
  const { alignCTA, alignIcon, backgroundImage, grid, theme } = props?.params || {};

  const componentVariants = ComponentVariants();
  const { themeName } = useTheme();
  const i18n = useI18n();
  const About = i18n.t('AccessibilityAbout') ? i18n.t('AccessibilityAbout') : 'about';
  if (!props.fields) return <>Icon Card Component</>;

  /**
   * check for the backgound image
   * 1. Apply background image if icon is set for TopCenter
   * 2. BackgroundImage is coming from the params as follows
   *  2.1 "backgroundImage": "<image mediaid=\"{942F5F16-8F99-484B-9487-FC4AAD14AB35}\" mediaurl=\"https://edge.sitecorecloud.io/ImageName.jpg\" />",
   *  2.2 extract mediaurl using regex
   */
  let bgImage;
  let bgImageVariant = false;

  if (backgroundImage && alignIcon === 'TopCenter') {
    const regex = /mediaurl="([^"]+)"/;
    const extractImage = backgroundImage.match(regex);
    if (extractImage) {
      bgImage = extractImage[1];
      bgImageVariant = true;
    } else {
      bgImage = '';
      bgImageVariant = false;
    }
  }
  // check if the configuration is set to override SVG with brand color.
  // If yes then override SVG color with the brand Color.
  const overrideSVGColor = overrideSVGWithBrandColor?.value;

  // check if icon ratio is set to large | small to set the width of the icon
  const {
    base,
    cards,
    cardBodyTextContainer,
    cardCtaPrimaryContainer,
    cardDescriptionText,
    cardListContainer,
    cardSubtitleText,
    cardTitleText,
    componentBG,
    container,
    ctaContainer,
    headerContainer,
    headerDescriptionText,
    headerTitleContainer,
    headerTitleText,
    legalDisclaimerText,
    svgBgLeft,
    svgBgRight,
    wrapper,
    bottomCTAWrapper,
  } = tailwindVariants({
    alignCTA: alignCTA as AlignCTA,
    bgImageVariant,
    grid: grid as Grid,
    theme: theme as Theme,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      className={componentBG()}
      style={bgImage ? { backgroundImage: `url(${bgImage})` } : undefined}
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      {/* Render top part of the border */}
      {multiColorBar?.value && componentVariants?.multipleBar?.top && (
        <MultiColorBorder
          multipleBar="top"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
      <Container className={container()}>
        <div data-component="authorable/cards/iconcard" data-testid="iconcard">
          <div className={base()}>
            {enablePattern?.value && (
              <>
                <SVG className={svgBgLeft()} svg={`IconCard/Bg=Left,Brand=${themeName}`} />
                <SVG className={svgBgRight()} svg={`IconCard/Bg=Right,Brand=${themeName}`} />
              </>
            )}
            <div className={wrapper()}>
              {/* Icon Card Title and Description Starts */}
              {title && (
                <div className={headerContainer()}>
                  {title && (
                    <div className={headerTitleContainer()}>
                      {title?.value && (
                        <Text className={headerTitleText()} encode={false} field={title} tag="h2" />
                      )}
                      {description?.value && (
                        <Text
                          className={headerDescriptionText()}
                          encode={false}
                          field={description}
                        />
                      )}
                    </div>
                  )}
                  {alignCTA == 'TopRight' && primaryCTA?.value?.href !== '' && (
                    <div className={ctaContainer()}>
                      <Button
                        href={primaryCTA?.value?.href}
                        label={primaryCTA?.value.text}
                        title={primaryCTA?.value?.title}
                        tag="a"
                        target={primaryCTA?.value?.target}
                        // The design requires an outline CTA but field name is primaryCTA,
                        // so for that we have added a fallback as an outline value,
                        // so if there is no value in sitecore field, it will take the outline value
                        type={primaryCTAType?.value || fallbackComponentVariantType}
                        color={primaryCTAColor?.value || fallbackComponentVariantColor}
                      />
                    </div>
                  )}
                </div>
              )}
              {/* Icon Card List Starts */}
              <div className={cardListContainer()}>
                {iconCards?.map((card: IconCardItem) => {
                  const iconRatioValue = card?.fields?.iconRatio?.value
                    ? card?.fields?.iconRatio?.value
                    : 'Small';
                  const {
                    cardIcon,
                    cardIconContainer,
                    cardBodyContainer,
                    cardCtaContainer,
                    cardContainer,
                  } = tailwindVariants({
                    iconRatio: iconRatioValue as iConRatio,
                    overrideSVGColor: overrideSVGColor,
                    iconLocation: alignIcon as IconLocation,
                  });
                  return (
                    <>
                      <div className={cardContainer()} key={card?.id}>
                        {card?.fields?.icon?.value?.src &&
                          card?.fields?.icon?.value?.extension !== null && (
                            <div className={cardIconContainer()}>
                              {card?.fields?.icon?.value?.extension === 'svg' && (
                                <SVG
                                  className={cardIcon()}
                                  svg="outline"
                                  url={card?.fields?.icon?.value?.src}
                                />
                              )}
                              {card?.fields?.icon?.value?.extension === 'png' && (
                                <ImageWrapper field={card?.fields?.icon} />
                              )}
                            </div>
                          )}
                        <div className={cardBodyContainer()}>
                          <div className={cardBodyTextContainer()}>
                            {card?.fields?.title?.value && (
                              <Text
                                className={cardTitleText()}
                                encode={false}
                                field={card.fields?.title}
                                tag="p"
                              />
                            )}
                            {card?.fields?.subtitle?.value && (
                              <Text
                                className={cardSubtitleText()}
                                encode={false}
                                field={card.fields?.subtitle}
                                tag="p"
                              />
                            )}
                            {card?.fields?.description?.value && (
                              <div className={cards()}>
                                <RichTextA11yWrapper
                                  className={cardDescriptionText()}
                                  field={card.fields?.description}
                                />
                              </div>
                            )}
                          </div>
                          <div className={cardCtaContainer()}>
                            {card?.fields?.primaryCTA?.value?.text && (
                              <div className={cardCtaPrimaryContainer()}>
                                <Button
                                  label={card?.fields?.primaryCTA?.value?.text}
                                  title={
                                    `${card?.fields?.primaryCTA?.value?.title}` ||
                                    `${card?.fields?.primaryCTA?.value?.text} ${About} ${stripHtml(
                                      card?.fields?.primaryCTA?.value?.text as string
                                    )}`
                                  }
                                  // The design requires an outline CTA but field name is primaryCTA,
                                  // so for that we have added a fallback as an outline value,
                                  // so if there is no value in sitecore field, it will take the outline value
                                  type={
                                    card?.fields?.primaryCTAType?.value ||
                                    fallbackComponentVariantType
                                  }
                                  color={
                                    card?.fields?.primaryCTAColor?.value ||
                                    fallbackComponentVariantColor
                                  }
                                  tag="a"
                                  target={card?.fields?.primaryCTA?.value?.target}
                                  href={card?.fields?.primaryCTA?.value?.href}
                                  gtmEvent={{
                                    event: 'cta_click',
                                    type: 'primary',
                                    'gtm.element.dataset.gtmLinkUrl':
                                      card?.fields?.primaryCTA?.value?.href,
                                    'gtm.element.dataset.gtmLinkName':
                                      card?.fields?.primaryCTA?.value?.title,
                                    'gtm.element.dataset.gtmDatasourceId': card.id,
                                    'gtm.element.dataset.gtmComponentName':
                                      card?.fields?.title?.value,
                                  }}
                                />
                              </div>
                            )}
                            {card?.fields?.secondaryCTA?.value.text && (
                              <Button
                                label={card?.fields?.secondaryCTA?.value?.text}
                                title={
                                  `${card?.fields?.secondaryCTA?.value?.title}` ||
                                  `${card?.fields?.secondaryCTA?.value?.text} ${About} ${stripHtml(
                                    card?.fields?.secondaryCTA?.value?.text as string
                                  )}`
                                }
                                type={card?.fields?.secondaryCTAType?.value || 'text'}
                                color={
                                  card?.fields?.secondaryCTAColor?.value ||
                                  fallbackComponentVariantColor
                                }
                                tag="a"
                                target={card?.fields?.secondaryCTA?.value?.target}
                                href={card?.fields?.secondaryCTA?.value?.href}
                                gtmEvent={{
                                  event: 'cta_click',
                                  type: 'secondary',
                                  'gtm.element.dataset.gtmLinkUrl':
                                    card?.fields?.secondaryCTA?.value?.href,
                                  'gtm.element.dataset.gtmLinkName':
                                    card?.fields?.secondaryCTA?.value?.title,
                                  'gtm.element.dataset.gtmDatasourceId': card.id,
                                  'gtm.element.dataset.gtmComponentName':
                                    card?.fields?.title?.value,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {alignCTA == 'BottomCenter' && (
                <div className={bottomCTAWrapper()}>
                  <>
                    {primaryCTA?.value?.href !== '' && (
                      <div className={ctaContainer()}>
                        <Button
                          href={primaryCTA?.value?.href}
                          label={primaryCTA?.value?.text}
                          title={primaryCTA?.value?.title}
                          tag="a"
                          target={primaryCTA?.value?.target}
                          // The design requires an outline CTA but field name is primaryCTA,
                          // so for that we have added a fallback as an outline value,
                          // so if there is no value in sitecore field, it will take the outline value
                          type={primaryCTAType?.value || fallbackComponentVariantType}
                          color={primaryCTAColor?.value || fallbackComponentVariantColor}
                          gtmEvent={{
                            event: 'cta_click',
                            type: 'primary',
                            'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                            'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                          }}
                          fullWidth
                        />
                      </div>
                    )}
                  </>
                </div>
              )}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </div>
      </Container>

      {/* Render bottom part of the border */}
      {multiColorBar?.value && componentVariants?.multipleBar?.bottom && (
        <MultiColorBorder
          multipleBar="bottom"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
    </div>
  );
};

export default IconCard;
