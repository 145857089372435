// Global
import { tv } from 'tailwind-variants';
import { ALL_THEMES } from 'lib/context/ThemeContext';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariantsArticleCard = tv({
  defaultVariants: {},
  slots: {
    componentBG: ['bg-components-article-card-listing-color-default-bg'],
    base: [
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
    contentWrapper: ['flex', 'flex-col'],
    titleAndDescriptionWrapperOuter: [
      'w-full',
      'flex',
      'flex-col',
      'justify-between',
      'md:items-center',
      'md:flex-row',
      'md:gap-x-themes-general-surface-spacing-large-body-margin-right',
      'mb-themes-general-surface-spacing-small-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-margin-bottom',
    ],
    titleAndDescriptionWrapperInner: ['flex', 'flex-col', 'flex-1'],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-article-card-listing-color-default-title',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
    ],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'text-components-article-card-listing-color-default-copy',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'max-md:mb-themes-general-surface-spacing-small-body-margin-bottom',
    ],
    svgWrapper: [
      'mb-themes-general-surface-spacing-small-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-margin-bottom',
    ],
    svgClass: ['[&>*]:h-full', '[&>*]:w-full', 'fill-colors-accent-1-400'],
    articleCardCarousel: ['w-full', '[&_li]:flex'],
    contentCarousel: [
      'flex-1',
      'flex-col',
      'flex',
      'max-lg:px-0',
      'max-lg:py-0',
      'order-2',
      'lg:order-1',
      '-mr-[calc(theme(spacing.themes-general-surface-spacing-small-padding-x))]',
      'md:-mr-[calc(theme(spacing.themes-general-surface-spacing-large-padding-x))]',
    ],
    contentCarouselTrack: ['mb-spacing-margin-small-7', 'md:mb-spacing-margin-large-5'],
    controlContainerClasses: [
      'flex',
      'gap-6',
      'md:px-components-hero-homepage-spacing-large-padding-x',
      'items-center',
      'justify-center',
      'md:justify-end',
    ],
    ctaWrapper: [],
    buttonClasses: [
      'group',
      'w-[2.5rem]',
      'h-[2.5rem]',
      'fill-components-button-color-outline-brand-default-stroke',
      'border-components-button-color-outline-brand-default-stroke',
      'disabled:border-components-button-color-outline-brand-disabled-stroke',
      'disabled:bg-transparent',
      'disabled:fill-components-button-color-outline-brand-disabled-stroke',
      'border-components-button-color-outline-white-outline-stroke',
      'border-[3px]',
      'rounded-themes-spacing-radius-large-button',
      'mr-2',
      'hover:fill-components-button-color-outline-brand-hover-text',
      'hover:border-components-button-color-outline-brand-hover-bg',
      'hover:bg-components-button-color-outline-brand-hover-bg',
      'active:bg-components-button-color-outline-white-pressed-bg',
      'active:border-components-button-color-outline-white-pressed-stroke',
      'disabled:pointer-events-none',
      'items-center',
      'justify-center',
      'inline-flex',
    ],
    iconClasses: [
      'hover:fill-components-button-color-outline-brand-hover-text',
      'group-disabled:fill-components-button-color-outline-brand-disabled-stroke',
    ],
    playPauseFill: ['fill-components-button-color-outline-brand-default-stroke'],
    iconNext: ['chevron_right'],
    iconPrev: ['chevron_left'],
    articleCardListing: [
      'w-full',
      'flex',
      'gap-themes-general-surface-spacing-large-cards-space-between',
    ],
    articleCard: ['w-full', 'flex'],
    disclaimerClasses: [
      'pt-components-product-results-list-with-search-spacing-large-padding-y',
      'text-components-article-card-listing-color-default-copy',
    ],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        titleAndDescriptionWrapperOuter: ['text-center'],
        ctaWrapper: [
          'text-center',
          'mt-themes-general-surface-spacing-small-button-margin-top',
          'md:mt-themes-general-surface-spacing-large-button-margin-top',
        ],
      },
      TopRight: {
        ctaWrapper: [],
      },
    },
    cardOrientation: {
      TwoColumns: {
        articleCard: ['md:w-1/2'],
      },
      ThreeColumns: {
        articleCard: ['md:w-1/3'],
      },
    },
  },
});

export const tailwindVariantsArticleSingleCard = tv({
  slots: {
    cardWrapper: [
      'flex',
      'flex-wrap',
      'w-full',
      'rounded-themes-spacing-radius-small-card',
      'md:rounded-themes-spacing-radius-large-card',
      'bg-components-article-card-color-bg',
      'border-components-article-card-spacing-large-border-width',
      'border-components-article-card-color-border',
      'overflow-hidden',
    ],
    card: ['w-full', 'flex', 'flex-col'],
    cardHead: [
      'px-components-article-card-spacing-small-padding-image-x',
      'pt-components-article-card-spacing-small-padding-image-y',
      'md:px-components-article-card-spacing-large-padding-image-x',
      'md:pt-components-article-card-spacing-large-padding-image-y',
    ],
    cardMedia: [
      'w-full',
      'h-auto',
      'object-cover',
      'rounded-tl-components-article-card-spacing-large-image-radius-tl',
      'rounded-tr-components-article-card-spacing-large-image-radius-tr',
      'rounded-bl-components-article-card-spacing-large-image-radius-bl',
      'rounded-br-components-article-card-spacing-large-image-radius-br',
    ],
    cardBody: [
      'h-full',
      'flex',
      'flex-col',
      'justify-between',
      'px-components-article-card-spacing-large-padding-content-x',
      'py-components-article-card-spacing-large-padding-content-y',
      'bg-components-article-card-color-content-bg',
      'gap-y-themes-general-card-spacing-small-body-margin-bottom',
      'md:gap-y-themes-general-card-spacing-large-body-margin-bottom',
    ],
    cardContentWrapper: [],
    labelContainer: [
      'mb-themes-general-card-spacing-small-eyebrow-margin-bottom',
      'md:mb-themes-general-card-spacing-large-eyebrow-margin-bottom',
    ],
    labelbg: ['bg-components-article-card-color-bg'],
    cardTitleText: [
      'font-header-small-small',
      'text-header-small-small',
      'leading-header-small-small',
      'md:font-header-large-xSmall',
      'md:text-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'text-components-article-card-color-title',
      'mb-themes-general-card-spacing-small-title-margin-bottom',
      'md:mb-themes-general-card-spacing-large-title-margin-bottom',
    ],
    cardSubtitleText: [
      'font-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'leading-bodySans-small-semibold',
      'md:font-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
      'md:leading-bodySans-medium-semibold',
      'text-components-article-card-color-subtitle',
      'mb-themes-general-card-spacing-small-title-margin-bottom',
      'md:mb-themes-general-card-spacing-large-title-margin-bottom',
    ],
    cardDescriptionText: [
      'font-bodySans-small',
      'text-bodySans-small',
      'leading-bodySans-small',
      'line-clamp-4',
      'pt-[4px]',
      'pb-[4px]',
      'border-b-4',
      'border-transparent',
      'max-h-[84px]',
      '[&>.richtext-wrapper>div>p]:py-[4px]',
      'text-components-article-card-color-subtitle',
    ],
    cardCtaWrapper: [
      'flex',
      'flex-wrap',
      '[&>*]:flex-1',
      'justify-between',
      'gap-themes-general-card-spacing-small-button-space-between',
      'md:gap-themes-general-card-spacing-large-button-space-between',
    ],
    cardPrimaryCTA: ['w-full'],
    cardSecondaryCTA: ['w-full'],
  },
  variants: {
    brand: {
      ...themeVariants,
      Baygon: {
        labelbg: [
          'bg-components-label-color-on-white-background-bg-default',
          'text-components-label-color-on-white-label-label-default',
        ],
        cardCtaWrapper: ['md:px-1'],
      },
      Pyrel: {
        cardCtaWrapper: ['md:px-1'],
      },
      // Added class to solved white space between Image and border at top corners of Article cards
      Duck: {
        cardMedia: ['!rounded-none'],
      },
    },
  },
});
