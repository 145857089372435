// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';

// Local
import Button from 'helpers/Button/Button';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import fallback from 'lib/fallback/fallback';
import { tailwindVariants } from './TopBarTailwind';

export type TopBarProps = ComponentProps & DartComponents.TopBar.TopBar;

type Theme = 'Dark' | 'Light' | undefined;

const TopBar = (props: TopBarProps): JSX.Element => {
  const [showTopBar, setShowTopBar] = useState<boolean>(false);
  const { description, title, primaryCTA, icon, closeButton, primaryCTAType, primaryCTAColor } =
    props?.fields || {};
  const { theme } = props?.params || {};
  const componentVariants = ComponentVariants();

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.topBar?.value;
  const fallbackComponentVariantType = fallback?.topBar?.type;

  const {
    base,
    contentContainer,
    headlineText,
    descriptionText,

    iconTitileWrapper,
    closeButtonStyle,
    topBarIcon,
    ctaSpacing,
  } = tailwindVariants({
    theme: theme as Theme,
  });

  useEffect(() => {
    setShowTopBar(!sessionStorage.getItem(`topBanner_${props?.rendering?.uid}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBanner = () => {
    sessionStorage.setItem(`topBanner_${props?.rendering?.uid}`, 'true');
    setShowTopBar(false);
  };
  if (!props.fields) return <>TopBar Component</>;

  if (!showTopBar) return <></>;

  return (
    <div className={base()} data-component="authorable/topbar">
      <div className={contentContainer()}>
        <div className={iconTitileWrapper()}>
          {icon?.value?.src && (
            <SVG className={topBarIcon()} svg="outline" url={icon?.value?.src} />
          )}
          {title?.value && (
            <Text className={headlineText()} encode={false} field={title} tag="h2" />
          )}
        </div>
        {description?.value && (
          <RichTextA11yWrapper className={descriptionText()} field={description} />
        )}
        {primaryCTA?.value?.text && primaryCTA?.value?.href && (
          <Button
            childClass={ctaSpacing()}
            href={primaryCTA.value.href}
            iconRight="chevron_right"
            label={primaryCTA.value.text}
            tag="a"
            target={primaryCTA?.value?.target}
            title={primaryCTA?.value?.title}
            // The design requires an outline CTA but field name is primaryCTA,
            // so for that we have added a fallback as an outline value,
            // so if there is no value in sitecore field, it will take the outline value.
            type={primaryCTAType?.value || fallbackComponentVariantType}
            color={
              primaryCTAColor?.value ||
              (theme === 'Light'
                ? componentVariants?.topBar?.primaryCta?.color
                : fallbackComponentVariantColor)
            }
          />
        )}
      </div>
      {closeButton?.value && (
        <div className={closeButtonStyle()} onClick={closeBanner}>
          <GoogleMaterialSymbol
            icon="close"
            className={
              theme === 'Light'
                ? `fill-components-banner-color-icon-close-default`
                : 'fill-components-banner-color-icon-close-alert'
            }
          ></GoogleMaterialSymbol>
        </div>
      )}
    </div>
  );
};
export default TopBar;
