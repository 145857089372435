// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { useTheme } from 'lib/context/ThemeContext';
import { tailwindVariants } from './StatsTailwind';

export type StatsListProps = ComponentProps & CompositeComponents.Stats.StatsList;
export type StatsList = CompositeComponents.Stats.Stats;

type InvertStyling = true | false | undefined;
type Theme = 'Dark' | 'Light' | undefined;
type Grid = '2column' | '3column' | '4column' | undefined;

const Stats = (props: StatsListProps): JSX.Element => {
  const { themeName } = useTheme();

  const { title, description, enablePattern, stats, invertStyling, backgroundImage } =
    props?.fields || {};
  const componentVariants = ComponentVariants();
  const { theme, grid } = props?.params || {};

  const {
    base,
    backgroundImageStats,
    statsInnerWrapper,
    statsTitle,
    statsDescription,
    statsTopWrapper,
    statsBottomWrapper,
    stateItem,
    stateWrapper,
    stateCallOut,
    stateLabel,
  } = tailwindVariants({
    theme: theme as Theme,
    grid: grid as Grid,
    invertStyling: invertStyling?.value as InvertStyling,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div data-component="authorable/stats">
      {/* Render top part of the border */}
      {enablePattern?.value && componentVariants?.multipleBar?.top && (
        <MultiColorBorder
          multipleBar="top"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
      <div className={`${base()} relative`} id={id ? id : undefined} tabIndex={id ? -1 : 1}>
        <Container>
          {backgroundImage && (
            <ImageWrapper
              className={backgroundImageStats()}
              layout="responsive"
              priority
              field={backgroundImage}
            />
          )}
          <div className={statsInnerWrapper()}>
            {(title?.value || description?.value) && (
              <div className={statsTopWrapper()}>
                {title?.value && (
                  <div className={statsTitle()}>
                    <Text encode={false} field={title} tag="h2" />
                  </div>
                )}
                {description?.value && (
                  <div className={statsDescription()}>
                    <RichTextA11yWrapper field={description} />
                  </div>
                )}
              </div>
            )}
            {stats && stats?.length > 0 && (
              <div className={statsBottomWrapper()}>
                <div className={stateWrapper()}>
                  {stats?.map((state: StatsList, index) => (
                    <div key={index} className={stateItem()}>
                      {state?.fields?.statCallout?.value && (
                        <div className={stateCallOut()}>
                          <Text encode={false} field={state?.fields?.statCallout} tag="p" />
                        </div>
                      )}
                      <div className={stateLabel()}>
                        <Text encode={false} field={state?.fields?.statLabel} tag="span" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>

      {/* Render bottom part of the border */}
      {enablePattern?.value && componentVariants?.multipleBar?.bottom && (
        <MultiColorBorder
          multipleBar="bottom"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
    </div>
  );
};

export default Stats;
