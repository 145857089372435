// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    base: [
      'align-center',
      'flex',
      'gap-components-lightbox-spacing-large-carousel-margin-bottom',
      'justify-center',
    ],
    centerSplide: ['flex', 'flex-col', 'items-center', 'justify-center'],
    centerSplidePagination: ['splide__pagination', 'mr-4', 'gap-spacing-spacing-2'],
    centerSplidePaginationContainer: [
      'flex',
      'gap-6',
      'w-full',
      'flex-1',
      'justify-between',
      'md:justify-center',
    ],
    centerSplidePaginationItems: [
      'w-2',
      'h-2',
      'outline-1',
      'outline',
      'outline-offset-1',
      'rounded-full',
      'outline-components-pagination-on-white-accent-default',
      'gap-2',
      'aria-selected:bg-components-pagination-on-white-accent-default',
    ],
    centerSplideSlide: ['flex', 'justify-center', 'w-full'],
    centerSplideTrack: [
      'flex',
      'md:h-components-lightbox-spacing-large-featured-image-height',
      'md:w-components-lightbox-spacing-large-featured-image-width',
      'h-components-lightbox-spacing-small-image-height',
      'w-components-lightbox-spacing-small-image-width',
      'items-center',
      'justify-center',
      'mb-components-lightbox-spacing-small-carousel-margin-bottom',
      'md:mb-components-lightbox-spacing-large-carousel-margin-bottom',
      '[&_img]:rounded-themes-spacing-radius-small-image',
      'md:[&_img]:rounded-themes-spacing-radius-large-image',
      '[&_img]:object-cover',
      '[&_img]:flex-1',
      '[&_img]:h-full',
    ],
    detailsSplide: [
      'w-components-lightbox-spacing-large-featured-image-width',
      'place-self-center',
      'flex',
      'items-center',
      'justify-center',
    ],
    detailsSplideSlide: [
      'flex',
      'flex-col',
      'md:gap-components-lightbox-spacing-large-media-details-margin-bottom',
      'items-center',
      'justify-start',
    ],
    prevNextButtons: [
      'md:hidden',
      'right-spacing-spacing-4',
      'max-md:py-[0.7rem]',
      'max-md:px-[0.7rem]',
      'max-md:h-[40px]',
      'max-md:w-[40px]',
      'max-md:min-h-[40px]',
      'md:w-[40px]',
      'z-50',
    ],
    prevNextSplide: ['hidden', 'items-center', 'justify-center', 'w-1/4', 'md:flex'],
    prevNextSplideButtons: ['absolute', 'w-[40px]', 'z-50'],
    prevNextSplideSlide: [
      'flex',
      'items-center',
      '[&>img]:rounded-components-lightbox-spacing-large-overlay-radius',
      'after:absolute',
      'after:w-full',
      'after:h-full',
      'after:ease-in-out',
      'after:duration-500',
      'after:rounded-components-lightbox-spacing-large-overlay-radius',
    ],
    prevNextSplideTrack: [
      'flex',
      'h-components-lightbox-spacing-large-image-height',
      'items-center',
      'justify-center',
      '[&_img]:flex-1',
      '[&_img]:h-full',
      '[&_img]:object-cover',
      '[&_img]:rounded-components-lightbox-spacing-large-overlay-radius',
    ],
    slideDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-center',
      'text-components-lightbox-color-body',
      'mb-components-lightbox-spacing-small-media-details-margin-bottom',
      'md:mb-0',
    ],
    slideTitle: [
      'font-header-small-medium',
      'leading-header-small-medium',
      'text-header-small-medium',
      'md:font-header-large-medium',
      'md:leading-header-large-medium',
      'md:text-header-large-medium',
      'text-center',
      'text-components-lightbox-color-title',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:mb-0',
    ],
    imageBorder: ['border-[2px]', 'border-currentColor'],
  },
  variants: {
    isPrev: {
      true: {
        prevNextSplideSlide: ['after:bg-components-lightbox-color-overlay-left-fill-default'],
      },
      false: {
        prevNextSplideSlide: ['after:bg-components-lightbox-color-overlay-right-fill-default'],
      },
    },
    isFirstOrLast: {
      true: {
        prevNextSplideSlide: ['cursor-not-allowed'],
      },
      false: {
        prevNextSplideSlide: ['cursor-pointer'],
      },
    },
    prev: {
      true: {
        prevNextSplideButtons: ['right-spacing-spacing-4'],
      },
      false: {
        prevNextSplideButtons: ['left-spacing-spacing-4'],
      },
    },
  },
});
