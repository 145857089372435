// Global
import {
  GetStaticComponentProps,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import graphQLClientFactory from 'lib/graphql-client-factory';
import { useI18n } from 'next-localization';
import { useState, useEffect } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { BasePageFields } from 'lib/templates/Foundation.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';

// Local
import FooterQuery from './Footer.graphql';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import SVG from 'helpers/SVG/SVG';
import TextTokenReplacer from 'helpers/TextTokenReplacer/TextTokenReplacer';
import { stripHtml } from 'lib/utils/regex';
import { tailwindVariants } from './FooterTailwind';

export type FooterGQLinks = ComponentProps &
  DartComponents.FooterJson &
  DartComponents.Footer & {
    staticProps?: {
      footer?: DartComponents.FooterJson;
    };
  } & { searchInfo?: BasePageFields.Search };

const Footer = (staticProps: FooterGQLinks): JSX.Element => {
  const [activeIndexes, setActiveIndexes] = useState<string[]>([]);
  const [windowSize, setWindowSize] = useState<number>();
  const { sitecoreContext } = useSitecoreContext();

  const searchInfoEnums = staticProps?.searchInfo?.fields?.pageType as Common.Enums.dartEnum;
  const i18n = useI18n();
  const socialIconMsg = i18n.t('FollowBrandOn');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }

    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    if (windowSize && windowSize > 1440) {
      setActiveIndexes([]);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  if (!staticProps) return <></>;

  const { componentName, dataSource } = staticProps?.rendering || {};
  const {
    copyRightText,
    enableSocial,
    footerLinks,
    logoImage,
    logoUrl,
    mainNavigations,
    socialLinks,
    socialTitle,
  } = staticProps?.staticProps?.footer || {};

  const footerLinksEven = footerLinks?.targetItems?.filter(
    (_footerLink, index: number) => index % 2 === 0
  );

  const footerLinksOdd = footerLinks?.targetItems?.filter(
    (_footerLink, index: number) => index % 2 !== 0
  );

  const {
    base,
    brandContainer,
    brandNavContainer,
    brandSocialContainer,
    copyRightTextContainer,
    footerLinkContainer,
    footerLinkIcon,
    footerLinkWrapper,
    footerRawHtmlWrapper,
    footerTextItem,
    footerTextItemWidth,
    globalContainer,
    globalContainerLeft,
    globalContainerRight,
    globalContainerRightColumn,
    linkContainer,
    linkIcon,
    mainNavContainer,
    mainNavItemContainer,
    mainNavItemLink,
    navItemLinkIcon,
    secondaryNavItemContainer,
    secondaryNavItemLink,
    socialIcon,
    socialIconsContainer,
    socialTitleContainer,
  } = tailwindVariants();

  const handleDropdown = (id: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    if (windowSize && windowSize < 1440) {
      event.preventDefault();
    }
    if (activeIndexes.includes(id)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== id));
    } else {
      setActiveIndexes([...activeIndexes, id]);
    }
  };

  return (
    <div className={base()} data-component="authorable/landmarks/footer">
      <Container fullWidth>
        <>
          {(enableSocial?.jsonValue?.value !== false ||
            mainNavigations?.targetItems.length !== 0) && (
            <div className={brandContainer()}>
              {enableSocial?.jsonValue && (
                <div className={brandSocialContainer()}>
                  <div className={socialTitleContainer()}>
                    <Text encode={false} field={socialTitle?.jsonValue} />
                  </div>
                  <div className={socialIconsContainer()}>
                    {socialLinks?.targetItems?.map((socialLink) => {
                      const { socialIcon: socialIconImage, socialUrl } = socialLink || {};
                      return (
                        <LinkWrapper
                          field={socialUrl?.jsonValue}
                          gtmEvent={{
                            event: 'link',
                            type: 'footer',
                            'gtm.element.dataset.gtmLinkName': socialTitle?.jsonValue?.value,
                            'gtm.element.dataset.gtmDatasourceId': dataSource,
                            'gtm.element.dataset.gtmComponentName': componentName,
                          }}
                          key={socialLink?.id}
                        >
                          <SVG
                            className={socialIcon()}
                            url={socialIconImage?.jsonValue?.value?.src}
                          />
                          <Text
                            className="sr-only"
                            encode={false}
                            field={{
                              value: `${socialIconMsg.replace(
                                '{BRAND}',
                                `${sitecoreContext.site?.name}`
                              )} ${socialIconImage?.jsonValue?.value?.alt}`,
                            }}
                            tag="span"
                          />
                        </LinkWrapper>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className={brandNavContainer()}>
                {mainNavigations?.targetItems?.length ? (
                  <ol className={mainNavContainer()}>
                    {mainNavigations?.targetItems?.map((mainNavItem) => {
                      const isMainOpen = activeIndexes.includes(mainNavItem?.id ?? '');
                      const { secondaryNavContainer } = tailwindVariants({
                        isMainOpen: isMainOpen,
                      });
                      return (
                        <li className={mainNavItemContainer()} key={mainNavItem?.id}>
                          {mainNavItem?.secondaryNavigations?.targetItems.length ? (
                            <>
                              {mainNavItem?.navigationLink?.jsonValue?.value?.href ? (
                                <LinkWrapper
                                  className={mainNavItemLink()}
                                  field={mainNavItem?.navigationLink?.jsonValue}
                                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                                    handleDropdown(mainNavItem?.id ?? '', event)
                                  }
                                >
                                  <GoogleMaterialSymbol
                                    className={`${linkIcon()} hidden md:block`}
                                    icon="chevron_right"
                                  />
                                  <GoogleMaterialSymbol
                                    className={navItemLinkIcon()}
                                    icon={isMainOpen ? 'expand_less' : 'expand_more'}
                                  />
                                </LinkWrapper>
                              ) : (
                                <div
                                  className={footerTextItem()}
                                  onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                    handleDropdown(
                                      mainNavItem?.id ?? '',
                                      event as unknown as React.MouseEvent<HTMLAnchorElement>
                                    )
                                  }
                                >
                                  <div className={mainNavItemLink()}>
                                    <span className={footerTextItemWidth()}>
                                      <Text
                                        field={mainNavItem?.navigationTitle?.jsonValue}
                                        aria-label={stripHtml(
                                          mainNavItem?.navigationTitle?.jsonValue?.value as string
                                        )}
                                      />
                                      <GoogleMaterialSymbol
                                        className={navItemLinkIcon()}
                                        icon={isMainOpen ? 'expand_less' : 'expand_more'}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className={linkContainer()}>
                              <LinkWrapper
                                className={mainNavItemLink()}
                                field={mainNavItem?.navigationLink?.jsonValue}
                                gtmEvent={{
                                  event: 'navigation_click',
                                  type: 'footer',
                                  'gtm.element.dataset.gtmLinkName':
                                    mainNavItem?.navigationLink?.jsonValue?.value.text,
                                  'gtm.element.dataset.gtmLinkUrl':
                                    mainNavItem?.navigationLink?.jsonValue?.value?.href,
                                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                                  'gtm.element.dataset.gtmComponentName': componentName,
                                }}
                              >
                                <GoogleMaterialSymbol className={linkIcon()} icon="chevron_right" />
                              </LinkWrapper>
                            </div>
                          )}
                          {mainNavItem?.secondaryNavigations?.targetItems?.length ? (
                            <ol className={secondaryNavContainer()}>
                              {mainNavItem?.secondaryNavigations?.targetItems.map(
                                (secondaryNavItem) => {
                                  if (
                                    !isMainOpen &&
                                    activeIndexes.includes(secondaryNavItem?.id ?? '')
                                  ) {
                                    setActiveIndexes(
                                      activeIndexes.filter((item) => item !== secondaryNavItem?.id)
                                    );
                                  }

                                  const isSecondaryOpen =
                                    activeIndexes.includes(secondaryNavItem?.id ?? '') &&
                                    isMainOpen;

                                  const { tertiaryNavContainer } = tailwindVariants({
                                    isSecondaryOpen: isSecondaryOpen,
                                  });

                                  return (
                                    <li
                                      className={secondaryNavItemContainer()}
                                      key={secondaryNavItem?.id}
                                    >
                                      {secondaryNavItem?.navigations?.targetItems.length ? (
                                        <LinkWrapper
                                          className={secondaryNavItemLink()}
                                          field={secondaryNavItem?.navigationLink?.jsonValue}
                                          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                                            handleDropdown(secondaryNavItem?.id ?? '', event);
                                          }}
                                          aria-label={stripHtml(
                                            secondaryNavItem?.navigationLink?.jsonValue?.value
                                              ?.text as string
                                          )}
                                        >
                                          <GoogleMaterialSymbol
                                            className={`${linkIcon()} hidden md:block`}
                                            icon="chevron_right"
                                          />
                                          <GoogleMaterialSymbol
                                            className={navItemLinkIcon()}
                                            icon={isSecondaryOpen ? 'remove' : 'add'}
                                          />
                                        </LinkWrapper>
                                      ) : (
                                        <div className={linkContainer()}>
                                          {secondaryNavItem?.navigationLink?.jsonValue?.value
                                            ?.href ? (
                                            <LinkWrapper
                                              className={secondaryNavItemLink()}
                                              field={secondaryNavItem?.navigationLink?.jsonValue}
                                              gtmEvent={{
                                                event: 'navigation_click',
                                                type: 'footer',
                                                'gtm.element.dataset.gtmLinkName':
                                                  secondaryNavItem?.navigationLink?.jsonValue?.value
                                                    .text,
                                                'gtm.element.dataset.gtmLinkUrl':
                                                  secondaryNavItem?.navigationLink?.jsonValue?.value
                                                    ?.href,
                                                'gtm.element.dataset.gtmDatasourceId': dataSource,
                                                'gtm.element.dataset.gtmComponentName':
                                                  componentName,
                                              }}
                                              aria-label={stripHtml(
                                                secondaryNavItem?.navigationLink?.jsonValue?.value
                                                  ?.text as string
                                              )}
                                            >
                                              <GoogleMaterialSymbol
                                                className={linkIcon()}
                                                icon="chevron_right"
                                              />
                                            </LinkWrapper>
                                          ) : (
                                            <div className={footerTextItem()}>
                                              <div className={secondaryNavItemLink()}>
                                                <Text
                                                  field={
                                                    secondaryNavItem?.navigationTitle?.jsonValue
                                                  }
                                                  aria-label={stripHtml(
                                                    secondaryNavItem?.navigationTitle?.jsonValue
                                                      ?.value as string
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                      {secondaryNavItem?.navigations?.targetItems.length ? (
                                        <ol className={tertiaryNavContainer()}>
                                          {secondaryNavItem?.navigations?.targetItems?.map(
                                            (secondaryNavItemChild) => (
                                              <li className="flex" key={secondaryNavItemChild.id}>
                                                <div className={linkContainer()}>
                                                  <LinkWrapper
                                                    className={secondaryNavItemLink()}
                                                    field={
                                                      secondaryNavItemChild?.navigationLink
                                                        ?.jsonValue
                                                    }
                                                    gtmEvent={{
                                                      event: 'navigation_click',
                                                      type: 'footer',
                                                      'gtm.element.dataset.gtmLinkName':
                                                        secondaryNavItemChild?.navigationLink
                                                          ?.jsonValue?.value.text,
                                                      'gtm.element.dataset.gtmLinkUrl':
                                                        secondaryNavItemChild?.navigationLink
                                                          ?.jsonValue?.value?.href,
                                                      'gtm.element.dataset.gtmDatasourceId':
                                                        dataSource,
                                                      'gtm.element.dataset.gtmComponentName':
                                                        componentName,
                                                    }}
                                                    aria-label={stripHtml(
                                                      secondaryNavItemChild?.navigationLink
                                                        ?.jsonValue?.value?.text as string
                                                    )}
                                                  >
                                                    <GoogleMaterialSymbol
                                                      className={linkIcon()}
                                                      icon="chevron_right"
                                                    />
                                                  </LinkWrapper>
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </ol>
                                      ) : null}
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          ) : null}
                        </li>
                      );
                    })}
                  </ol>
                ) : null}
              </div>
            </div>
          )}
          <div className={globalContainer()}>
            <div className={globalContainerLeft()}>
              <LinkWrapper
                field={logoUrl?.jsonValue}
                title={logoImage?.jsonValue?.value?.alt as string}
                aria-label={logoImage?.jsonValue?.value?.alt as string}
                gtmEvent={{
                  event: 'navigation_click',
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              >
                <ImageWrapper field={logoImage?.jsonValue} />
              </LinkWrapper>
              <div className={copyRightTextContainer()}>
                <TextTokenReplacer
                  field={copyRightText?.jsonValue}
                  tokens={['{year}']}
                  tokenValues={[new Date().getFullYear().toString()]}
                />
              </div>
            </div>
            <div className={globalContainerRight()}>
              <div className={globalContainerRightColumn()}>
                {footerLinksEven?.map((footerLink) => {
                  const { ctaLink: navigationLink, rawHtml } = footerLink || {};

                  if (rawHtml?.jsonValue?.value) {
                    return (
                      <div className={footerLinkContainer()} key={footerLink?.id}>
                        <div
                          className={footerRawHtmlWrapper()}
                          dangerouslySetInnerHTML={{ __html: rawHtml?.jsonValue?.value }}
                        />
                        <GoogleMaterialSymbol className={footerLinkIcon()} icon="chevron_right" />
                      </div>
                    );
                  }

                  return (
                    <>
                      {navigationLink?.jsonValue?.value?.href && (
                        <div className={footerLinkContainer()} key={footerLink?.id}>
                          <LinkWrapper
                            className={footerLinkWrapper()}
                            field={navigationLink?.jsonValue}
                            gtmEvent={{
                              event: 'navigation_click',
                              type: 'footer',
                              'gtm.element.dataset.gtmLinkName':
                                navigationLink?.jsonValue?.value.text,
                              'gtm.element.dataset.gtmLinkUrl':
                                navigationLink?.jsonValue?.value?.href,
                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                              'gtm.element.dataset.gtmComponentName': componentName,
                            }}
                          >
                            <GoogleMaterialSymbol
                              className={footerLinkIcon()}
                              icon="chevron_right"
                            />
                          </LinkWrapper>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className={globalContainerRightColumn()}>
                {footerLinksOdd?.map((footerLink) => {
                  const { ctaLink: navigationLink, rawHtml } = footerLink || {};

                  if (rawHtml?.jsonValue?.value) {
                    return (
                      <div className={footerLinkContainer()} key={footerLink?.id}>
                        <div
                          className={footerRawHtmlWrapper()}
                          dangerouslySetInnerHTML={{ __html: rawHtml?.jsonValue?.value }}
                        />
                        <GoogleMaterialSymbol className={footerLinkIcon()} icon="chevron_right" />
                      </div>
                    );
                  }
                  return (
                    <>
                      {navigationLink?.jsonValue?.value?.href && (
                        <div className={footerLinkContainer()} key={footerLink?.id}>
                          <LinkWrapper
                            className={footerLinkWrapper()}
                            field={navigationLink?.jsonValue}
                            gtmEvent={{
                              event: 'navigation_click',
                              type: 'footer',
                              'gtm.element.dataset.gtmLinkName':
                                navigationLink?.jsonValue?.value.text,
                              'gtm.element.dataset.gtmLinkUrl':
                                navigationLink?.jsonValue?.value?.href,
                              'gtm.element.dataset.gtmDatasourceId': dataSource,
                              'gtm.element.dataset.gtmComponentName': componentName,
                            }}
                          >
                            <GoogleMaterialSymbol
                              className={footerLinkIcon()}
                              icon="chevron_right"
                            />
                          </LinkWrapper>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      </Container>
      <input
        type="hidden"
        name="hdnPageType"
        id="hdnPageTypeName"
        value={searchInfoEnums?.fields?.value?.value}
      />
      <input
        type="hidden"
        name="hdnhideFromSearch"
        id="hdnhideFromSearch"
        value={staticProps?.searchInfo?.fields?.hideFromSearch?.value?.toString()}
      />
    </div>
  );
};

export default Footer;

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphQLClientFactory({});
  const result = await graphQLClient.request<unknown>(FooterQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
  });

  return {
    staticProps: result,
    searchInfo: layoutData?.sitecore?.route as BasePageFields.Search,
  };
};
