// Global
import { tv } from 'tailwind-variants';

export const tailwindconstiants = tv({
  slots: {
    productInformationTopSlider: [
      'md:max-w-[630px]',
      'w-full',
      'mb-components-product-information-spacing-small-image-margin-bottom',
      'md:mb-components-product-information-spacing-large-image-margin-bottom',
    ],
    ProductCarousel: ['product-carousel'],
    productInformationBottomSlider: [
      'md:max-w-[630px]',
      'w-full',
      'mb-spacing-space-between-large-4',
      'thumbnail-slider',
    ],
    productInformationProgressBar: [
      'progress-bar',
      'h-[4px]',
      'w-[160px]',
      'bg-components-pagination-on-white-bg',
      'rounded-components-pagination-scrollbar-radius',
    ],
    productInformationProgress: [
      'bg-components-pagination-on-white-accent-scroll',
      'h-[4px]',
      'rounded-components-pagination-scrollbar-radius',
    ],
    productRoundedImages: [
      'product-primary-image',
      'article-author-image',
      'rounded-themes-spacing-radius-small-image',
      'md:rounded-themes-spacing-radius-large-image',
      '!h-full',
      'block',
      'object-cover',
      '!w-full',
      'aspect-[5/4]',
    ],
    productInformationImages: [
      '!h-full',
      'block',
      'object-cover',
      'rounded-themes-spacing-radius-small-image',
    ],
    productInformationGradient: [
      'absolute',
      'inset-0',
      'bg-components-media-video-thumbnail-overlay-bg',
    ],
    productInformationVideoThumbnail: [
      'absolute',
      'top-1/2',
      'left-1/2',
      'transform -translate-x-1/2 -translate-y-1/2',
      'h-[50px]',
      'w-[50px]',
      '[&>*]:h-full',
      '[&>*]:w-full',
      'fill-components-product-information-color-play-icon',
    ],
    productInformationProgressbarWrapper: [
      'flex',
      'flex-row',
      'items-center',
      'justify-center',
      'gap-4',
    ],
    productInformationSplideControls: ['splide__arrows', 'flex', 'flex-row', 'gap-2'],
    productInformationVideo: [
      'h-full',
      '[&>*]:!h-full',
      '[&>*]:!w-full',
      '[&>*]:!rounded-themes-spacing-radius-small-image',
      'aspect-video',
      'rounded-themes-spacing-radius-small-image',
      'md:rounded-themes-spacing-radius-large-image',
      'w-full',
      'max-w-[1200px]',
      'my-0',
      'mx-auto',
    ],
    btn: [
      'md:[&>button]:w-8',
      'md:[&>button]:h-8',
      '[&>button]:w-[40px]',
      '[&>button]:h-[40px]',
      '[&>button]:sm:py-[0.1.5rem]',
      '[&>button]:sm:px-[0.1.5rem]',
      '[&>button]:py-[0.7rem]',
      '[&>button]:px-[0.7rem]',
      'group',
      '[&>button]:!min-h-0',
    ],
  },
});
