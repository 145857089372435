// Global
import { ImageField, Item, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { createRef, useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';
import { useTheme } from 'lib/context/ThemeContext';

// Local
import MediaGalleryCarousel from 'components/authorable/Carousel/MediaGalleryCarousel';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import fallback from 'lib/fallback/fallback';
import { tailwindVariants } from './MediaGalleryTailwind';

type GalleryOrientation = 'Default' | 'MasonryHorizontal' | 'MasonryVertical';

export type MediaGalleryProps = ComponentProps &
  CompositeComponents.MediaGallery.MediaGallerySlideList;

interface RenderSlideProps {
  mediaGallerySlide?: CompositeComponents.MediaGallery.MediaGallerySlide & Item;
  imageClassName?: string;
  wrapperClassName?: string;
  onClick: (arg0: string) => void;
}

interface RenderSlidesProps {
  mediaSlides?: CompositeComponents.MediaGallery.MediaGallerySlideList[] & Item[];
  galleryOrientation?: string;
  toggleLightBox: (arg0: string) => void;
}
// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const RenderSlide = (props: RenderSlideProps): JSX.Element => {
  const { mediaGallerySlide, imageClassName, wrapperClassName, onClick } = props || {};

  if (!mediaGallerySlide) return <></>;

  return (
    <a
      href="#"
      className={wrapperClassName}
      onClick={(e) => {
        e.preventDefault();
        onClick(mediaGallerySlide.id as string);
      }}
    >
      <ImageWrapper
        className={imageClassName}
        field={mediaGallerySlide?.fields?.image as ImageField}
      />
    </a>
  );
};

const RenderSlides = (props: RenderSlidesProps): JSX.Element => {
  const { galleryOrientation, mediaSlides, toggleLightBox } = props;

  const {
    galleryColumn,
    galleryImage,
    galleryImageMiddleCol,
    galleryImageWrapper,
    galleryImageWrapperMiddleCol,
  } = tailwindVariants({
    orientation: galleryOrientation as GalleryOrientation,
  });
  const [customMediaSlideOrder, setCustomMediaSlideOrder] = useState<Item[] | Item[][]>([]);
  const chunkArray = (array: Item[], size: number) => {
    return array.reduce(
      (acc, _, i) => (i % size ? acc : [...acc, array.slice(i, i + size)]),
      [] as Item[][]
    );
  };

  useEffect(() => {
    if (galleryOrientation !== 'Default' && mediaSlides) {
      setCustomMediaSlideOrder(mediaSlides);
    }

    if (galleryOrientation === 'Default' && mediaSlides) {
      setCustomMediaSlideOrder(chunkArray(mediaSlides, 3));
    }
  }, [galleryOrientation, mediaSlides]);

  return (
    <>
      {customMediaSlideOrder && galleryOrientation && galleryOrientation !== 'Default' ? (
        <>
          <div className={galleryColumn()}>
            {customMediaSlideOrder.slice(0, 2).map((mediaGallerySlide) => (
              <React.Fragment key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}>
                <RenderSlide
                  key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}
                  wrapperClassName={galleryImageWrapper()}
                  imageClassName={galleryImage()}
                  mediaGallerySlide={mediaGallerySlide as Item}
                  onClick={toggleLightBox}
                />
              </React.Fragment>
            ))}
          </div>
          <div className={galleryColumn()}>
            {customMediaSlideOrder.slice(2, 5).map((mediaGallerySlide) => (
              <React.Fragment key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}>
                <RenderSlide
                  key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}
                  wrapperClassName={galleryImageWrapperMiddleCol()}
                  imageClassName={galleryImageMiddleCol()}
                  mediaGallerySlide={mediaGallerySlide as Item}
                  onClick={toggleLightBox}
                />
              </React.Fragment>
            ))}
          </div>
          <div className={galleryColumn()}>
            {customMediaSlideOrder.slice(5).map((mediaGallerySlide) => (
              <React.Fragment key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}>
                <RenderSlide
                  key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}
                  wrapperClassName={galleryImageWrapper()}
                  imageClassName={galleryImage()}
                  mediaGallerySlide={mediaGallerySlide as Item}
                  onClick={toggleLightBox}
                />
              </React.Fragment>
            ))}
          </div>
        </>
      ) : (
        <>
          {customMediaSlideOrder.map((mediaGallerySlideGroup, i: number) => (
            <div className={galleryColumn()} key={i}>
              {Array.isArray(mediaGallerySlideGroup) &&
                mediaGallerySlideGroup.map((mediaGallerySlide) => (
                  <React.Fragment
                    key={!Array.isArray(mediaGallerySlide) ? mediaGallerySlide?.id : ''}
                  >
                    <RenderSlide
                      key={mediaGallerySlide.id}
                      wrapperClassName={galleryImageWrapper()}
                      imageClassName={galleryImage()}
                      mediaGallerySlide={mediaGallerySlide}
                      onClick={toggleLightBox}
                    />
                  </React.Fragment>
                ))}
              {Array.isArray(mediaGallerySlideGroup) && mediaGallerySlideGroup.length < 3 && (
                <>
                  {Array.from({ length: Math.max(3 - mediaGallerySlideGroup.length, 0) }).map(
                    (_, index) => (
                      <div key={index} className={galleryImageWrapper()}></div>
                    )
                  )}
                </>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

const MediaGallery = (props: MediaGalleryProps): JSX.Element => {
  const {
    description,
    enablePattern,
    mediaGallerySlides,
    primaryCTA,
    title,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields ?? {};
  const { galleryOrientation } = props?.params ?? ({} as GalleryOrientation);
  const { componentName, dataSource } = props?.rendering || {};
  const { themeName } = useTheme();

  const {
    base,
    galleryWrapper,
    lightbox,
    lightboxButton,
    lightboxButtonWrapper,
    mediaGalleryCarouselWrapper,
    primaryCTAWrapper,
    topDescription,
    topTitle,
    topTitleContentWrapper,
    topTitleTextWrapper,
    topTitleWrapper,
    svgClassLarge,
    svgClassExtraLarge,
  } = tailwindVariants({
    orientation: galleryOrientation as GalleryOrientation,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const lightboxRef = createRef<HTMLDivElement>();

  const toggleLightBox = (galleryImageIndex: string) => {
    const index = mediaGallerySlides?.findIndex((slide) => slide.id === galleryImageIndex);
    setLightboxOpen(!lightboxOpen);
    setScroll(!lightboxOpen);
    setSlideIndex(index as number);
  };

  const setScroll = (toSet: boolean) => {
    if (toSet) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      toggleLightBox('');
    }
  };

  const handleTabKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const focusableElements = lightboxRef.current?.querySelectorAll(
      'button:not([tabindex="-1"]), a[href]:not([tabindex="-1"])'
    );

    if (!focusableElements || focusableElements.length === 0) {
      return;
    }

    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

    if (e.key === 'Tab') {
      if (e.shiftKey && document.activeElement === firstElement) {
        // If shift + tab and currently focused on the first element, move focus to the last element
        e.preventDefault();
        lastElement.focus();
      } else if (!e.shiftKey && document.activeElement === lastElement) {
        // If tab and currently focused on the last element, move focus to the first element
        e.preventDefault();
        firstElement.focus();
      }
    }
  };

  useEffect(() => {
    if (lightboxRef.current) {
      lightboxRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lightboxOpen]);

  const [customMediaSlideOrder, setCustomMediaSlideOrder] = useState<Item[]>([]);
  const [mediaSlides, setMediaSlides] = useState<Item[]>([]);

  useEffect(() => {
    if (mediaGallerySlides) {
      if (galleryOrientation !== 'Default') {
        const mediaSlidesCopy = [...mediaGallerySlides];
        if (mediaSlidesCopy.length > 7) {
          mediaSlidesCopy?.splice(7);
        }

        if (galleryOrientation === 'MasonryVertical') {
          const customSort = [0, 3, 1, 4, 6, 2, 5];
          setCustomMediaSlideOrder(customSort?.map((i) => mediaSlidesCopy[i]));
        } else {
          setCustomMediaSlideOrder(mediaSlidesCopy);
        }
        setMediaSlides(mediaSlidesCopy);
      }

      if (galleryOrientation === 'Default') {
        setCustomMediaSlideOrder([...mediaGallerySlides]);
        setMediaSlides(mediaGallerySlides);
      }
    }
  }, [mediaGallerySlides, galleryOrientation]);

  if (!props.fields) return <>Media Gallery Component</>;
  // Unique id for component
  const id = props.params.RenderingIdentifier;
  return (
    <div
      className={base()}
      data-component="authorable/mediagallery"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      {(title?.value || description?.value || primaryCTA?.value?.text) && (
        <div className={topTitleWrapper()}>
          <div className={topTitleContentWrapper()}>
            <div className={topTitleTextWrapper()}>
              <Text className={topTitle()} encode={false} field={title} tag="h2" />
              <RichTextA11yWrapper className={topDescription()} field={description} />
            </div>
            {primaryCTA?.value?.text && (
              <div className={primaryCTAWrapper()}>
                <Button
                  // The design requires an outline CTA but field name is primaryCTA,
                  // so for that we have added a fallback as an outline value,
                  // so if there is no value in sitecore field, it will take the outline value
                  type={primaryCTAType?.value || fallbackComponentVariantType}
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  tag="a"
                  label={primaryCTA?.value?.text}
                  href={primaryCTA?.value?.href}
                  target={primaryCTA?.value?.target}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              </div>
            )}
          </div>
          {enablePattern && enablePattern.value && (
            <>
              <SVG className={svgClassLarge()} svg={`MediaGallery/Brand=${themeName}`} />
              <SVG
                className={svgClassExtraLarge()}
                svg={`MediaGallery/Breakpoint=ExtraLarge,Brand=${themeName}`}
              />
            </>
          )}
        </div>
      )}
      <div className={galleryWrapper()}>
        <RenderSlides
          galleryOrientation={galleryOrientation}
          mediaSlides={customMediaSlideOrder}
          toggleLightBox={toggleLightBox}
        />
      </div>
      {lightboxOpen && customMediaSlideOrder && (
        <div
          ref={lightboxRef}
          className={lightbox()}
          onKeyDown={(e) => {
            handleKeyPress(e);
            handleTabKey(e);
          }}
        >
          <div className={lightboxButtonWrapper()}>
            <Button
              childClass={lightboxButton()}
              type="outline"
              iconLeft="close"
              onClick={() => {
                setLightboxOpen(!lightboxOpen);
                setScroll(!lightboxOpen);
              }}
            />
          </div>
          <div className={mediaGalleryCarouselWrapper()}>
            <MediaGalleryCarousel slideIndex={slideIndex} slides={mediaSlides} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaGallery;
