// Global
import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  defaultVariants: {
    cardOrientation: 0,
  },
  slots: {
    base: [
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
    cardContent: [
      'flex',
      'flex-col',
      '!break-word',
      'justify-center',
      'items-start',
      'gap-themes-general-card-spacing-large-title-margin-bottom',
      'pb-themes-general-card-spacing-large-body-margin-bottom',
    ],
    errorCardContent: [
      'flex',
      'w-full',
      'h-52',
      'items-center',
      'justify-center',
      'rounded-themes-radius-large-card',
      'bg-components-pdf-link-card-color-card-bg',
      'p-spacing-spacing-7',
      'pb-themes-general-card-spacing-large-body-margin-bottom',
    ],
    cardCtaButton: [
      'cardCtaButton',
      'border-none',
      '!w-full',
      '!min-w-[102px]',
      '!min-h-12',
      'rounded-themes-radius-large-button',
      '!text-components-button-color-filled-tonal-default-text',
      'bg-components-button-color-filled-tonal-default-bg',
      '!py-components-button-spacing-large-default-padding-y',
      '!px-components-button-spacing-large-default-padding-x',
    ],
    cardDescriptionText: [
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-components-pdf-link-card-color-card-body',
      'line-clamp-4',
      'text-ellipsis',
      'pt-1',
      'pb-1',
    ],
    cardSubtitleText: [
      'text-bodySans-small-semibold',
      'font-bodySans-small-semibold',
      'leading-bodySans-small-semibold',
      'text- themes-general-card-color-dark-subtitle',
      'text-ellipsis',
      'line-clamp-2',
      'pb-1',
    ],
    cardTitleText: [
      'text-header-large-xSmall',
      'font-header-large-xSmall',
      'leading-header-large-xSmall',
      'text-components-pdf-link-card-color-card-title',
      'break-word',
    ],
    cardWrapper: [
      'grid',
      'grid-cols-1',
      'sm:grid-cols-2',
      'w-full',
      'gap-themes-general-surface-spacing-large-cards-space-between',
    ],
    cardBody: [
      'flex',
      'flex-col',
      'items-start',
      'justify-between',
      'p-spacing-spacing-5',
      'rounded-themes-radius-large-card',
      'bg-components-pdf-link-card-color-card-bg',
    ],
    loaderWrapper: ['h-64', 'content-center'],
    searchLoaderAnimation: [
      'animate-spin',
      'block',
      'w-20',
      'h-20',
      'm-auto',
      'bg-components-omnibox-color-loader-icon-fill-default',
      '[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
    ],
    titleText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'text-components-generic-card-listing-color-title',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
    ],
    componentBG: ['bg-components-external-link-card-bg'],
    ctaWrapper: [],
    descriptionText: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-components-generic-card-listing-color-copy',
    ],
    legalDisclaimerText: [
      'pt-spacing-spacing-5',
      'text-components-generic-card-listing-color-copy',
    ],
    titleAndDescription: ['flex', 'flex-col', 'items-center', 'self-stretch'],
    titleAndDescriptionWrapperInner: ['flex', 'flex-col'],
    titleAndDescriptionWrapperOuter: [
      'w-full',
      'flex',
      'flex-col',
      'md:flex-row',
      'justify-between',
      'md:items-center',
      'mb-themes-general-surface-spacing-small-margin-bottom',
      'md:mb-themes-general-surface-spacing-large-margin-bottom',
      'gap-themes-general-surface-spacing-small-body-margin-bottom',
      'md:gap-themes-general-surface-spacing-large-body-margin-right',
    ],
    wrapper: ['flex-col', 'flex', 'items-center', 'justify-center'],
  },
  variants: {
    alignCTA: {
      BottomCenter: {
        ctaWrapper: [
          'w-full',
          'sm:w-auto',
          '[&>a]:w-full',
          'sm:[&>a]:w-auto',
          'mt-themes-general-surface-spacing-small-button-margin-top',
          'md:mt-themes-general-surface-spacing-large-button-margin-top',
        ],
      },
      TopRight: {
        ctaWrapper: [],
      },
    },
    cardOrientation: {
      0: {
        cardWrapper: ['md:grid-cols-2'],
      },
      2: {
        cardWrapper: ['md:grid-cols-2'],
      },
      3: {
        cardWrapper: ['md:grid-cols-3'],
      },
      4: {
        cardWrapper: ['md:grid-cols-4'],
      },
    },
  },
});
