// Global
import {
  RichTextField,
  Text,
  TextField,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { tailwindVariants } from './HeadlineTailwind';

export type HeadlineProps = ComponentProps & DartComponents.Headline.Headline;

type Alignment = 'Center' | 'Left' | undefined;

type BackgroundColor = 'BrandColor' | 'Default' | 'Light' | undefined;

const Headline = (props: HeadlineProps): JSX.Element => {
  const { backgroundColor = 'Default', alignContent = 'Center' } = props?.params || {};

  const { base, contentWrapper, headlineText, description, textAlignment, componentBG } =
    tailwindVariants({
      background: backgroundColor as BackgroundColor,
      alignment: alignContent as Alignment,
    });

  const { sitecoreContext } = useSitecoreContext();

  const id = props?.params?.RenderingIdentifier;

  return (
    <div
      className={componentBG()}
      data-component="authorable/headline"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container>
        <div className={base()}>
          <div className={(contentWrapper(), textAlignment())}>
            {props?.fields ? (
              <>
                <Text
                  className={headlineText()}
                  encode={false}
                  field={props?.fields?.headlineTitle as TextField}
                  tag="h2"
                />
                <RichTextA11yWrapper
                  className={description()}
                  field={props?.fields?.headlineDescription as RichTextField}
                />
              </>
            ) : null}
            {!props?.fields && sitecoreContext?.route?.fields ? (
              <>
                <Text
                  className={headlineText()}
                  encode={false}
                  field={sitecoreContext?.route?.fields?.headlineTitle as TextField}
                  tag="h1"
                />
                <RichTextA11yWrapper
                  className={description()}
                  field={sitecoreContext?.route?.fields?.headlineDescription as RichTextField}
                />
              </>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Headline;
