export type ThemeUrlKeys =
  | 'Corporate'
  | 'Ziploc'
  | 'Vanilla'
  | ''
  | 'Glade'
  | 'Raid'
  | 'Baygon'
  | 'Duck'
  | 'Pyrel'
  | 'MrMuscle';

export const ThemeUrls: Record<ThemeUrlKeys, string> = {
  Raid: 'https://cdn.fonts.net/kit/1bc50b91-f666-4197-8156-a77766f3e932/1bc50b91-f666-4197-8156-a77766f3e932.css',
  Glade:
    'https://cdn.fonts.net/kit/8383a640-2a23-4e11-96df-b4aab8fef5c9/8383a640-2a23-4e11-96df-b4aab8fef5c9.css',
  Corporate:
    'https://cdn.fonts.net/kit/3bcec18a-4ec4-4df8-a1ad-ea8283708578/3bcec18a-4ec4-4df8-a1ad-ea8283708578.css',
  Ziploc:
    'https://cdn.fonts.net/kit/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df.css?display=swap',
  Baygon:
    'https://cdn.fonts.net/kit/56b095f6-a989-4555-bade-03bf167c884e/56b095f6-a989-4555-bade-03bf167c884e.css',
  Duck: 'https://cdn.fonts.net/kit/3bcec18a-4ec4-4df8-a1ad-ea8283708578/3bcec18a-4ec4-4df8-a1ad-ea8283708578.css',
  Pyrel:
    'https://cdn.fonts.net/kit/1bc50b91-f666-4197-8156-a77766f3e932/1bc50b91-f666-4197-8156-a77766f3e932.css',
  Vanilla:
    'https://cdn.fonts.net/kit/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df.css?display=swap',
  '': 'https://cdn.fonts.net/kit/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df/fbf8b1ba-7fcf-4b12-9d7e-e861055df2df.css?display=swap',
  // added temp cdn for mrmuscle
  MrMuscle:
    'https://cdn.fonts.net/kit/190fdb61-4f30-4d13-a04a-bc3147e32196/190fdb61-4f30-4d13-a04a-bc3147e32196.css?display=swap',
};
