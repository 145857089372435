// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = {
  base: tv({
    compoundSlots: [
      {
        slots: ['itemNum', 'itemTitle'],
        class: [
          'font-header-small-xSmall',
          'leading-header-small-xSmall',
          'text-components-content-list-color-title',
          'text-header-small-xSmall',
          'md:font-header-medium-xSmall',
          'md:leading-header-medium-xSmall',
          'md:text-header-medium-xSmall',
          'lg:font-header-large-xSmall',
          'lg:leading-header-large-xSmall',
          'lg:text-header-large-xSmall',
        ],
      },
      {
        slots: ['itemDescription', 'itemIcon'],
        class: [
          'font-bodySans-small',
          'leading-bodySans-small',
          'text-bodySans-small',
          'md:font-bodySans-medium',
          'md:leading-bodySans-medium',
          'md:text-bodySans-medium',
        ],
      },
    ],
    slots: {
      base: [
        'content-list',
        'text-components-content-list-color-body',
        'group-[.layoutWrapper]:p-0',
        'md:p-spacing-spacing-10',
        'py-components-content-list-spacing-small-padding-y',
        'px-components-content-list-spacing-small-padding-x',
      ],
      componentWrapper: ['bg-components-content-list-color-bg'],
      itemDescription: [
        'flex-1',
        'text-components-content-list-color-body',
        '[&_img]:mt-spacing-spacing-4',
        'md:[&_img]:mt-spacing-spacing-7',
        '[&_img]:rounded-themes-radius-small-image',
        'md:[&_img]:rounded-themes-radius-small-image',
        '[&_img]:mx-auto',
        '[&>ul]:list-disc',
        '[&>ul]:ml-spacing-spacing-5',
        '[&>ul]:m-0',
        '[&>.img-wrapper-ce]:mt-components-content-list-spacing-small-item-copy-margin-bottom',
        '[&>.img-wrapper-ce]:md:mt-components-content-list-spacing-large-item-copy-margin-bottom',
        '[&>.scWebEditInput>.ql-editor>ul]:list-disc',
        '[&>.scWebEditInput>.ql-editor>ul]:ml-spacing-spacing-5',
      ],
      itemIcon: ['fill-components-content-list-color-icon'],
      itemNum: [],
      itemTitle: [
        'mb-components-content-list-spacing-small-item-title-margin-bottom',
        'md:mb-components-content-list-spacing-large-item-title-margin-bottom',
      ],
      itemContentWrapper: ['inline-flex', 'px-4'],
      itemWrapper: [
        'flex',
        'gap-components-content-list-spacing-small-item-icon-margin-right',
        'mb-components-content-list-spacing-small-header-margin-bottom',
        'md:mb-components-content-list-spacing-large-item-margin-bottom',
        'last-of-type:mb-0',
      ],
    },
    variants: {
      withUnorderedList: {
        true: {
          itemWrapper: [
            'last-of-type:border-b',
            'border-t',
            'border-components-site-search-facet-stroke',
            'py-spacing-spacing-4',
            '!mb-0',
            'md:mb-0',
            '[&_ul]:ml-4',
          ],
        },
      },
      withIcon: {
        false: {
          itemWrapper: [
            'border-b',
            'border-t',
            'rounded-components-site-search-facet-radius',
            '[&+div]:border-t-0',
            'border-components-site-search-facet-stroke',
            'py-components-content-list-list-item-container-padding-y',
            'px-components-content-list-list-item-container-padding-x',
            '!mb-0',
          ],
        },
      },
      brand: {
        ...themeVariants,
        Corporate: {
          base: ['!py-[2rem]'],
        },
      },
    },
  }),
  listHeaderTailwindVariants: tv({
    slots: {
      base: [
        'mb-themes-general-surface-spacing-small-body-margin-bottom',
        'md:mb-themes-general-surface-spacing-large-body-margin-bottom',
        'flex',
        'flex-col',
      ],
      header: [
        'font-header-small-medium',
        'leading-header-small-medium',
        'text-components-content-list-color-title',
        'text-header-small-medium',
        'md:font-header-medium-medium',
        'md:leading-header-medium-medium',
        'md:text-header-medium-medium',
        'lg:font-header-large-medium',
        'lg:leading-header-large-medium',
        'lg:text-header-large-medium',
        'mb-spacing-margin-large-2',
        'md:mb-spacing-margin-large-3',
      ],
      subHeader: [
        'font-header-small-xSmall',
        'leading-header-small-xSmall',
        'text-components-content-list-color-body',
        'text-header-small-xSmall',
        'md:font-header-medium-xSmall',
        'md:leading-header-medium-xSmall',
        'md:text-header-medium-xSmall',
        'lg:font-header-large-xSmall',
        'lg:leading-header-large-xSmall',
        'lg:text-header-large-xSmall',
        'mb-themes-general-surface-spacing-small-title-margin-bottom',
        'md:mb-themes-general-surface-spacing-large-title-margin-bottom',
      ],
      description: [
        'description',
        'text-components-content-list-color-body',
        'font-bodySans-medium',
        'leading-bodySans-medium',
        'text-bodySans-medium',
        'lg:font-bodySans-large',
        'lg:leading-bodySans-large',
        'lg:text-bodySans-large',
      ],
    },
    variants: {
      onlyHeader: {
        true: {
          header: ['!pb-0'],
        },
      },
      brand: {
        ...themeVariants,
        Corporate: {
          base: ['!py-[2rem]'],
        },
      },
    },
  }),
};
