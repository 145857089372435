import { tv } from 'tailwind-variants';

const tailwindVarianCard = tv({
  slots: {
    articleListContainer: [
      'w-full',
      'md:mb-components-product-results-list-with-search-spacing-large-padding-y',
      'mb-components-product-results-list-with-search-spacing-small-card-margin-bottom',
    ],
    cardHead: [
      'flex',
      'justify-between',
      'md:px-components-article-card-spacing-large-padding-image-x',
      'md:py-components-article-card-spacing-large-padding-image-y',
    ],
    cardTitleText: [
      'text-header-small-small',
      'font-header-small-small',
      'leading-header-small-small',
      'md:font-header-large-xSmall',
      'md:text-header-large-xSmall',
      'md:leading-header-large-xSmall',
      'text-components-article-card-color-title',
      'block',
    ],
    cardSubtitleText: [
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-components-article-card-color-subtitle',
      'block',
    ],
    cardPrimaryCTA: ['w-full', 'mr-2', 'mb-3'],
    cardMedia: [
      'h-[184px]',
      'components-article-card-spacing-small-padding-image-x',
      'components-article-card-spacing-small-padding-image-y',
      'rounded-tl-components-article-card-spacing-large-image-radius-tl',
      'rounded-tr-components-article-card-spacing-large-image-radius-tr',
      'rounded-bl-components-article-card-spacing-large-image-radius-bl',
      'rounded-br-components-article-card-spacing-large-image-radius-br',
    ],
    articleCardListing: [
      'w-full',
      'flex',
      'flex-wrap',
      'gap-x-components-article-card-listing-spacing-large-card-space-between',
      'gap-y-components-product-results-list-with-search-spacing-small-card-space-between',
      'md:gap-y-components-product-results-list-with-search-spacing-large-card-space-between',
      'items-stretch',
      'outline-none',
    ],
    labelBg: ['bg-components-article-card-color-content-bg'],
  },
});
export default tailwindVarianCard;

export const tailwindVariantProductSearch = tv({
  slots: {
    base: ['flex'],
    paginationContainer: [
      'flex',
      'flex-col',
      'border-t',
      'border-solid',
      'border-components-pagination-on-white-stroke',
      'md:gap-spacing-spacing-5',
      'md:pt-spacing-spacing-7',
      'pt-spacing-spacing-5',
    ],
    paginationRoot: ['flex', 'justify-between', 'items-center', 'md:items-start'],
    paginationIcon: [
      'md:p-0',
      'min-h-auto',
      ['[&>button]:flex'],
      ['[&>button]:p-0'],
      ['[&>button]:min-h-min'],
      'bg-components-button-color-text-brand-default-bg',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-pagination-on-white-stroke',
      'px-components-button-spacing-compressed-icon-only-padding-x',
      'py-components-button-spacing-compressed-default-padding-y',
      'md:pt-components-button-spacing-compressed-default-padding-y',
      'md:pb-[6px]',
      'rounded-themes-radius-large-button',
    ],
    paginationPageWrapper: ['md:flex', 'flex-row', 'gap-spacing-spacing-1', 'hidden'],
    paginationPage: [
      'flex',
      'py-components-button-spacing-compressed-default-padding-y',
      'px-components-button-spacing-compressed-default-padding-x',
      'rounded-themes-spacing-radius-large-button-icon',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-pagination-on-white-text-default',
    ],
    paginationDisplayPage: [
      'flex-row',
      'justify-center',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'md:font-bodySans-small',
      'md:text-bodySans-small',
      'text-components-pagination-on-white-text-default',
      'md:leading-bodySans-small',
    ],
    paginationGroup: ['flex-col', 'items-center', 'gap-spacing-spacing-5', 'flex'],
  },
  variants: {
    isMobile: {
      true: {
        paginationDisplayPage: ['md:hidden', 'flex'],
      },
      false: {
        paginationDisplayPage: ['hidden', 'md:flex'],
      },
    },
    currentPage: {
      true: {
        paginationPage: [
          'bg-components-pagination-on-white-bg-active',
          '!text-components-pagination-on-white-text-active',
        ],
      },
      false: {
        paginationPage: [
          'hover:bg-components-pagination-on-white-bg-hover',
          'hover:text-components-pagination-on-white-text-hover',
        ],
      },
    },
  },
});

export const tailwindProductTopBar = tv({
  slots: {
    base: [
      'flex',
      'justify-between',
      'py-6',
      'md:py-spacing-spacing-5',
      'md:px-spacing-spacing-4',
      'flex-col',
      'md:flex-col',
      'relative',
      'md:mb-components-top-title-spacing-large-margin-bottom',
    ],
    categoryFilterContainer: [
      'flex',
      'flex-col',
      'justify-end',
      'md:flex-row',
      'gap-components-product-results-list-with-search-spacing-small-filter-space-between',
      'pb-components-product-results-list-with-search-spacing-small-filter-space-between',
      'md:pb-[0px]',
    ],
    categoryList: [
      'flex',
      'flex-row',
      'gap-spacing-spacing-05',
      'md:gap-components-button-group-spacing-space-between',
      'md:px-components-button-group-spacing-padding-x',
      'pr-spacing-spacing-1',
      'pl-spacing-spacing-3',
      'rounded-components-button-group-spacing-border-radius',
      'snap-x',
    ],
    categoryContainer: [],
    categoryLabelBG: [
      'bg-components-button-group-color-button-bg-active',
      '!text-components-button-group-color-button-fg-active',
    ],
    categoryLabel: [
      '!ml-0',
      'hover:cursor-pointer',

      //Added calculation to match with the design.
      'pt-[calc(theme(spacing.components-button-spacing-large-default-padding-y)+1px)]',
      'pb-[calc(theme(spacing.components-button-spacing-large-default-padding-y)+4px)]',
    ],
    category: [
      'bg-components-button-group-color-button-bg-default',
      'flex',
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-button-group-color-button-fg-default',
      'hover:text-components-button-group-color-button-fg-hover',
      'py-components-button-spacing-large-default-padding-y',
      'px-components-button-spacing-large-default-padding-x',
      'hover:bg-components-button-group-color-button-bg-hover',
      'hover:cursor-pointer',
      'rounded-components-button-group-spacing-border-radius',
    ],
    filterContainer: [
      'flex',
      'md:gap-spacing-padding-large-2',
      'items-center',
      'md:justify-between',
      'justify-end',
    ],
    filterWrapper: [
      'md:mb-components-product-results-list-with-search-spacing-large-filter-space-between',
      'relative',
    ],
    categoryItem: ['snap-end', 'scroll-mx-6', 'shrink-0', 'scroll-mx-6'],
    svgWrapper: ['mb-themes-general-surface-spacing-large-margin-bottom'],
  },
});

export const tailwindArticleList = tv({
  slots: {
    articleCardList: ['w-full'],
    cardWrapperList: [
      'flex',
      'md:flex-row',
      'flex-col',
      'w-full',
      'h-full',
      'overflow-hidden',
      'md:gap-components-article-card-spacing-large-list-content-gap',
      'border-components-article-card-spacing-large-border-width',
      'border-components-article-card-color-border',
      'rounded-themes-spacing-radius-large-card',
      'bg-components-article-card-color-content-bg',
    ],
    cardMediaWrapperList: [
      'bg-components-article-card-color-bg',
      'flex',
      'relative',
      'items-center',
      'md:px-components-article-card-spacing-large-list-image-padding-x',
      'md:py-components-article-card-spacing-large-list-image-padding-y',
      'items-start',
      'max-md:items-start',
      'justify-center',
    ],
    cardContentWrapperList: [
      'py-components-article-card-spacing-small-padding-content-y',
      'px-components-article-card-spacing-large-padding-content-x',
      'md:py-components-article-card-spacing-large-padding-content-y',
      'flex',
      'flex-col',
      'h-full',
      'items-stretch',
      'md:w-2/3',
    ],
    cardContainerList: [
      'flex',
      'flex-col',
      'gap-components-article-card-spacing-large-category-margin-bottom',
    ],
    cardContentList: [
      'flex',
      'flex-col',
      'gap-components-article-card-spacing-large-title-margin-bottom',
      'pb-components-article-card-spacing-large-copy-margin-bottom',
    ],
    badgeContainerList: [
      'absolute',
      'top-0',
      'left-1/2',
      'transform',
      '-translate-x-1/2',
      'md:block',
      'hidden',
    ],
    cardBodyList: [
      'flex',
      'flex-col',
      'h-full',
      'gap-components-article-card-spacing-large-eyebrow-margin-bottom',
    ],
    labelContainerList: ['[&>div]:!mb-0', 'product-label', 'themes-radius-large-label'],
    cardCtaWrapperList: [
      'max-md:[&>a]:w-full',
      'mt-themes-general-card-spacing-large-body-margin-bottom',
    ],
  },
});

// Tailwind Varint for Filter
export const tailwindVariants = tv({
  slots: {
    searchComponentWrapper: ['search-component-wrapper', 'bg-components-site-search-bg'],
    searchMainSection: ['search-main-section', 'w-full', 'flex'],
    searchResultsLeftWrapper: [
      'searchResults-leftWrapper',
      'bg-components-site-search-refine-bg',
      'z-[9999]',
      'fixed',
      'bg-white',
      'h-screen',
      'py-spacing-spacing-7',
      'pr-spacing-spacing-5',
      'top-0',
      'z-50',
      'duration-700',
      'overflow-auto',
      'focus:outline-none',
    ],
    searchResultsLeft: [
      'searchResults-left',
      'px-components-site-search-refine-padding-x',
      'py-components-site-search-refine-padding-y',
      'gap-2',
      'flex',
      'flex-col',
    ],
    refineTitleWrapper: [
      'refineTitleWrapper',
      'flex',
      'gap-2',
      'items-center',
      'justify-between',
      'mb-spacing-spacing-5',
    ],
    refineTitle: ['refineTitle', 'text-components-site-search-refine-title'],
    refineTitleIconWrapper: [
      'refineTitleIconWrapper',
      '[&_svg]:h-10',
      '[&_svg]:w-10',
      'flex',
      'items-center',
      'justify-center',
    ],
    refineTitleIcon: ['fill-components-button-color-filled-white-default-icon'],
    selectionFilterWrapper: ['selection-filter-wrapper'],
    selectedFilterWrapper: [
      'selected-filter-wrapper',
      'flex',
      'gap-2',
      'items-center',
      'flex-wrap',
      'mb-spacing-spacing-5',
    ],
    selectedFilter: [
      'flex',
      'items-center',
      'justify-center',
      'gap-x-components-tag-spacing-space-between',
      'bg-components-tag-color-bg',
      'py-components-tag-spacing-padding-y',
      'px-components-tag-spacing-padding-x',
      'border-[1px]',
      'border-components-tag-color-stroke',
      'rounded-themes-radius-large-tag',
      'cursor-pointer',
      'hover:ease-in',
      'hover:border-components-tag-color-stroke-hover',
      'hover:bg-components-tag-color-bg-hover',
      '[&_span]:hover:text-components-tag-color-text-hover',
      '[&_svg]:hover:fill-components-tag-color-text-hover',
    ],
    selectedFilterText: [
      'font-bodySans-xSmall',
      'text-bodySans-xSmall',
      'text-components-tag-color-text',
      'leading-bodySans-xSmall',
    ],
    selectedFilterIcon: [
      'fill-components-tag-color-text',
      'flex',
      'items-center',
      'justify-center',
      '[&_svg]:w-[12px]',
      '[&_svg]:h-[12px]',
    ],
    accordionFacetsWrapper: ['border-t-[1px]', 'border-components-site-search-facet-stroke'],
    accordionFacetTriggerClass: ['w-full'],
    accordionFacetsHeaderWrapper: [
      'accordion-facets-header-wrapper',
      'flex',
      'items-center',
      'justify-between',
      'py-spacing-spacing-3',
      'px-spacing-spacing-0',
    ],
    accordionFacetsHeader: [
      'font-bodySans-medium-semibold',
      'text-components-site-search-facet-group-label-text',
    ],
    accordionFacetsHeaderIconWrapper: ['h-[24px]', 'w-[24px]', 'pt-[2px]'],
    accordionFacetsHeaderIcon: ['fill-components-site-search-facet-group-label-text'],
    accordionFacetsContentWrapper: [
      'bg-components-dropdown-item-bg-default',
      'mb-spacing-spacing-3',
    ],
    accordionFacetsContentItem: [
      'flex',
      'items-center',
      'py-spacing-spacing-3',
      'px-spacing-spacing-4',
    ],
    accordionFacetsContentItemIconWrapper: [
      'bg-white',
      'w-[20px]',
      'h-[20px]',
      'mr-forms-checkbox-spacing-space-between',
      'flex',
      'items-center',
      'justify-center',
      'border-[1px]',
      'border-forms-checkbox-color-stroke-default',
      'rounded-forms-checkbox-spacing-radius',
      '[&_svg]:bg-forms-checkbox-color-checkbox-selected',
      '[&_svg]:rounded-forms-checkbox-spacing-radius',
    ],
    accordionFacetsContentItemIcon: ['fill-forms-checkbox-color-checkmark-selected'],
    accordionFacetsItemLabelContainer: ['flex-1'],
    accordionFacetsItemLabelWrapper: [
      'flex',
      'items-center',
      'hover:cursor-pointer',
      'justify-between',
    ],
    accordionFacetsItemLabel: [
      'font-bodySans-medium',
      'text-bodySans-medium',
      'text-components-dropdown-item-text-default',
      'leading-bodySans-medium',
    ],
    accordionFacetsItemLabelTextColor: ['text-components-dropdown-item-text-default'],
    accordionContent: ['overflow-auto'],
  },
  variants: {
    deviceDetect: {
      Desktop: {
        searchComponentWrapper: ['px-[80px]', 'py-[40px]'],
        searchMainSection: ['flex-row'],
        searchResultsLeftWrapper: ['w-[30%]'],
        refineTitleWrapper: ['mb-spacing-spacing-5'],
        refineTitle: [
          'font-header-large-medium',
          'text-header-large-medium',
          'leading-header-large-medium',
        ],
      },
      Mobile: {
        searchComponentWrapper: [
          'py-components-hero-internal-spacing-small-padding-y',
          'px-components-accordion-spacing-small-padding-x',
        ],
        searchMainSection: ['flex-col'],
        searchResultsLeftWrapper: ['w-[90%]'],
        refineTitleWrapper: ['mb-spacing-spacing-5'],
        refineTitle: [
          'font-header-small-medium',
          'text-header-small-medium',
          'leading-header-small-medium',
        ],
      },
    },
    isFilterVisible: {
      true: {
        searchResultsLeftWrapper: ['right-0'],
      },
      false: {
        searchResultsLeftWrapper: ['-right-full'],
      },
    },
    isAccordionOpen: {
      true: {
        accordionFacetsWrapper: [],
      },
      false: {
        accordionFacetsWrapper: [],
      },
    },
  },
});

export const tailwindMain = tv({
  slots: {
    mainContainer: [
      'md:py-components-product-results-list-with-search-spacing-large-padding-y',
      'py-components-product-results-list-with-search-spacing-small-padding-y',
      'md:px-components-product-results-list-with-search-spacing-large-padding-x',
      'px-components-product-results-list-with-search-spacing-small-padding-x',
    ],
    loaderWrapper: [
      'flex',
      'items-center',
      'h-screen',
      'opacity-85',
      'fixed',
      'top-0',
      'right-0',
      'left-0',
      'bottom-0',
      'bg-white',
      'z-50',
    ],
    filterBodyBG: [
      'fixed',
      'content-[""]',
      'h-screen',
      'w-screen',
      'top-0',
      'left-0',
      'z-[999]',
      'bg-colors-grayscale-700',
      'opacity-90',
    ],
    searchLoaderAnimation: [
      'animate-spin',
      'block',
      'h-20',
      'w-20',
      'm-auto',
      'bg-components-omnibox-color-loader-icon-fill-default',
      '[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
    ],
  },
});
