// Global
import { tv } from 'tailwind-variants';

// Tailwind variables
export const tailwindVariants = tv({
  slots: {
    componentBG: [],
    base: [
      'flex',
      'flex-col',
      'gap-2',
      'items-start',
      'justify-start',
      'relative',
      'overflow-hidden',
      'md:py-spacing-padding-large-6',
      'py-themes-general-surface-spacing-small-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'px-themes-general-surface-spacing-small-padding-x',
      'gap-0',
      'shrink-0',
    ],
    contentWrapper: [
      'flex',
      'flex-col',
      'gap-components-heading-page-title-spacing-large-title-margin-bottom',
      'items-start',
      'justify-start',
      'self-stretch',
      'relative',
    ],
    description: [
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
      'sm:font-bodySans-large',
      'sm:leading-bodySans-large',
      'sm:text-bodySans-large',
      'md:mt-themes-general-surface-spacing-large-title-margin-bottom',
      'mt-themes-general-surface-spacing-small-title-margin-bottom',
    ],
    headlineText: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-medium',
      'sm:font-header-large-large',
      'sm:leading-header-large-large',
      'sm:text-header-large-large',
    ],
    textAlignment: [],
  },
  variants: {
    alignment: {
      Center: {
        contentWrapper: ['items-center'],
        textAlignment: ['w-full', 'text-center'],
      },
      Left: {
        contentWrapper: ['items-start'],
        textAlignment: ['w-full', 'md:text-left'],
      },
    },
    background: {
      BrandColor: {
        componentBG: ['bg-components-heading-page-title-color-color-bg'],
        description: ['text-components-heading-page-title-color-color-body'],
        headlineText: ['text-components-heading-page-title-color-color-title'],
      },
      Default: {
        componentBG: ['bg-components-heading-page-title-color-default-bg'],
        description: ['text-components-heading-page-title-color-default-body'],
        headlineText: ['text-components-heading-page-title-color-default-title'],
      },
      Light: {
        componentBG: ['bg-components-heading-page-title-color-light-bg'],
        description: ['text-components-heading-page-title-color-light-body'],
        headlineText: ['text-components-heading-page-title-color-light-title'],
      },
    },
  },
});
