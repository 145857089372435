// Global
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { HeroComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Common } from 'lib/templates/Foundation.Dart.model';
import fallback from 'lib/fallback/fallback';
import { tailwindVariants } from './HeroSDSTailwind';

export type Hero = HeroComponents.RenderingParameters.HeroSds;
export type HeroSdsProps = ComponentProps & ProductAndArticleComponent.Sds.SdsInformation;
export type CategoryInfo = ItemEx & DartPages.CategoryListPage;
export type ButtonType = ProductAndArticleComponent.Sds.Button;
type Label = Common.Label.Label;

type Alignment = 'Left' | undefined;

const HeroSDS = (props: HeroSdsProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const fallbackComponentVariantColor = fallback?.componentVariants?.value;
  const fallbackComponentVariantType = fallback?.componentVariants?.type;

  const sdsDetailPage = sitecoreContext?.route as DartPages.SdsDetailPage;
  const context = sdsDetailPage?.fields;
  const categoryInfo = sdsDetailPage?.fields?.category as CategoryInfo[];
  const categories = Array.isArray(categoryInfo)
    ? categoryInfo?.map((c: CategoryInfo) => c.fields?.categoryNameForSearchIndex?.value)?.join('|')
    : '';
  const subCategoryInfo = sdsDetailPage?.fields?.subCategory as CategoryInfo;
  const filterLabels = sdsDetailPage?.fields?.filterLabels as Label[];
  const filterLabels2 = sdsDetailPage?.fields?.filterLabels2 as Label[];
  const filterLabelPipeString = filterLabels?.map((c) => c.fields?.title?.value)?.join('|');
  const filterLabel2PipeString = filterLabels2?.map((c) => c.fields?.title?.value)?.join('|');

  const { description, epaNumber, formula, buttons, primaryImage, headline } = context || {};
  const { alignContent, backgroundColor } = props?.params || {};
  const alignment = alignContent !== 'Left';

  // const formatDate = (date: Field<string> | undefined) => {
  //   const dateString = date?.value || '';
  //   const [year, month, day] = dateString.split('T')[0].split('-');

  //   // Format the date as MM/DD/YYYY
  //   const formattedDate = `${month}/${day}/${year}`;
  //   return { value: formattedDate };
  // };

  const {
    base,
    containerClass,
    totalWrapper,
    heroWrapper,
    heroTitle,
    heroButtons,
    heroDescription,
    productPrimaryImage,
  } = tailwindVariants({
    alignment: alignment as unknown as Alignment,
  });

  if (!context) return <>SDS Hero</>;
  return (
    <div className={base()} data-component="authorable/heroes/sdshero">
      <Container className={containerClass()}>
        <div className={totalWrapper()}>
          <div className={productPrimaryImage()}>
            {primaryImage?.value?.src && (
              <ImageWrapper className="product-primary-image" field={primaryImage} />
            )}
          </div>

          <div className={heroWrapper()}>
            {headline?.value && (
              <Text className={heroTitle()} encode={false} field={headline} tag="h1" />
            )}

            {buttons?.length !== 0 && (
              <div className={heroButtons()}>
                {buttons?.map((button: ButtonType) => {
                  return (
                    <Button
                      key={button?.fields?.ctaLink?.value?.text}
                      color={
                        backgroundColor === 'BrandColor'
                          ? 'white'
                          : button?.fields?.ctaColor?.value || fallbackComponentVariantColor
                      }
                      childClass={`${buttons?.length > 4 ? '!flex-none' : '!flex-1 md:!flex-none'}`}
                      href={button?.fields?.ctaLink?.value?.href}
                      label={button?.fields?.ctaLink?.value?.text}
                      tag="a"
                      target={button?.fields?.ctaLink?.value?.target}
                      type={button?.fields?.ctaType?.value || fallbackComponentVariantType}
                    >
                      <LinkWrapper field={button} />
                    </Button>
                  );
                })}
              </div>
            )}

            {context?.description?.value && (
              <RichTextA11yWrapper className={heroDescription()} field={description} />
            )}
          </div>
        </div>
        <input type="hidden" name="hdnArticleCategory" id="hdnArticleCategory" value={categories} />
        <input
          type="hidden"
          name="hdnArticleSubCategory"
          id="hdnArticleSubCategory"
          value={subCategoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnListingPage"
          id="hdnListingPage"
          value={sdsDetailPage?.fields?.mainListingPage?.name}
        />
        <input
          type="hidden"
          name="hdnfilterLabel"
          id="hdnfilterLabel"
          value={filterLabelPipeString}
        />
        <input
          type="hidden"
          name="hdnfilterLabel2"
          id="hdnfilterLabel2"
          value={filterLabel2PipeString}
        />
        <input type="hidden" name="hdnEpaNumber" id="hdnEpaNumber" value={epaNumber?.value} />
        <input type="hidden" name="hdnFormula" id="hdnFormula" value={formula?.value} />
      </Container>
    </div>
  );
};
export default HeroSDS;
