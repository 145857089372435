// Global
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';
import React, { ReactElement } from 'react';
import useSWR from 'swr';
import AddSharp from 'public/assets/google-material-symbols/sharp/add.svg';
import ArrowDropUpSharp from 'public/assets/google-material-symbols/sharp/arrow_drop_up.svg';
import ArrowDropDownSharp from 'public/assets/google-material-symbols/sharp/arrow_drop_down.svg';
import ArrowDropDownOutlined from 'public/assets/google-material-symbols/outlined/arrow_drop_down.svg';
import ArrowDropUpOutlined from 'public/assets/google-material-symbols/outlined/arrow_drop_up.svg';
import ArrowForwardSharp from 'public/assets/google-material-symbols/sharp/arrow_forward.svg';
import AutoRenew from 'public/assets/google-material-symbols/sharp/autorenew.svg';
import ChevronLeftSharp from 'public/assets/google-material-symbols/sharp/chevron_left.svg';
import ChevronRightSharp from 'public/assets/google-material-symbols/sharp/chevron_right.svg';
import ChevronRightOutlined from 'public/assets/google-material-symbols/outlined/chevron_right.svg';
import CancelSharp from 'public/assets/google-material-symbols/sharp/cancel.svg';
import CloseSharp from 'public/assets/google-material-symbols/sharp/close.svg';
import CloseOutlined from 'public/assets/google-material-symbols/outlined/close.svg';
import CheckSmallRounded from 'public/assets/google-material-symbols/rounded/check_small.svg';
import DoneSharp from 'public/assets/google-material-symbols/sharp/done.svg';
import DoneOutlined from 'public/assets/google-material-symbols/outlined/done.svg';
import ExpandLessSharp from 'public/assets/google-material-symbols/sharp/expand_less.svg';
import ExpandMoreSharp from 'public/assets/google-material-symbols/sharp/expand_more.svg';
import ExpandLessOutlined from 'public/assets/google-material-symbols/outlined/expand_less.svg';
import ExpandMoreOutlined from 'public/assets/google-material-symbols/outlined/expand_more.svg';
import EcoSharp from 'public/assets/google-material-symbols/sharp/eco.svg';
import EditFillSharp from 'public/assets/google-material-symbols/sharp/edit-fill.svg';
import FavoriteSharp from 'public/assets/google-material-symbols/sharp/favorite.svg';
import GridViewSharp from 'public/assets/google-material-symbols/sharp/grid_view.svg';
import PauseCircleSharp from 'public/assets/google-material-symbols/sharp/pause_circle.svg';
import PlayArrowSharp from 'public/assets/google-material-symbols/sharp/play_arrow.svg';
import PlayCircleSharp from 'public/assets/google-material-symbols/sharp/play_circle.svg';
import ListSharp from 'public/assets/google-material-symbols/sharp/list.svg';
import LocationOnSharp from 'public/assets/google-material-symbols/sharp/location_on.svg';
import RemoveSharp from 'public/assets/google-material-symbols/sharp/remove.svg';
import RestartAltSharp from 'public/assets/google-material-symbols/sharp/restart_alt.svg';
import StarSharp from 'public/assets/google-material-symbols/sharp/star.svg';
import StarFillSharp from 'public/assets/google-material-symbols/sharp/star-fill.svg';
import StarHalfFillSharp from 'public/assets/google-material-symbols/sharp/star_half-fill.svg';
import StarHalfSharp from 'public/assets/google-material-symbols/sharp/star_half.svg';
import SearchOutlined from 'public/assets/google-material-symbols/outlined/search.svg';
import ShareSharp from 'public/assets/google-material-symbols/sharp/share.svg';

type IconVariants = {
  sharp?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  outlined?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  rounded?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

// Import common icons directly.  Don't need to worry about bundle size since they're always on the page anyway
const commonIcons: Record<string, IconVariants> = {
  arrow_drop_up: {
    sharp: ArrowDropUpSharp,
    outlined: ArrowDropUpOutlined,
  },
  arrow_forward: { sharp: ArrowForwardSharp },
  add: { sharp: AddSharp },
  arrow_drop_down: {
    sharp: ArrowDropDownSharp,
    outlined: ArrowDropDownOutlined,
  },
  autorenew: {
    sharp: AutoRenew,
  },
  chevron_left: { sharp: ChevronLeftSharp },
  chevron_right: {
    sharp: ChevronRightSharp,
    outlined: ChevronRightOutlined,
  },
  cancel: {
    sharp: CancelSharp,
  },
  close: {
    sharp: CloseSharp,
    outlined: CloseOutlined,
  },
  check_small: { rounded: CheckSmallRounded },
  done: {
    sharp: DoneSharp,
    outlined: DoneOutlined,
  },
  expand_less: { sharp: ExpandLessSharp, outlined: ExpandLessOutlined },
  expand_more: { sharp: ExpandMoreSharp, outlined: ExpandMoreOutlined },
  eco: { sharp: EcoSharp },
  'edit-fill': {
    sharp: EditFillSharp,
  },
  favorite: { sharp: FavoriteSharp },
  grid_view: { sharp: GridViewSharp },
  pause_circle: { sharp: PauseCircleSharp },
  play_arrow: { sharp: PlayArrowSharp },
  play_circle: { sharp: PlayCircleSharp },
  list: { sharp: ListSharp },
  location_on: { sharp: LocationOnSharp },
  remove: { sharp: RemoveSharp },
  restart_alt: { sharp: RestartAltSharp },
  search: {
    outlined: SearchOutlined,
  },
  'star_half-fill': {
    sharp: StarHalfFillSharp,
  },
  'star-fill': {
    sharp: StarFillSharp,
  },
  star_half: {
    sharp: StarHalfSharp,
  },
  star: {
    sharp: StarSharp,
  },
  share: {
    sharp: ShareSharp,
  },
};

export type Variant = 'outlined' | 'rounded' | 'sharp';

export type GoogleMaterialSymbolProps = {
  className?: string;
  fill?: boolean;
  icon: string;
  variant?: Variant;
  'aria-hidden'?: boolean;
};

const GoogleMaterialSymbol = ({
  className,
  fill,
  icon,
  variant = 'sharp' as Variant,
  ...props
}: GoogleMaterialSymbolProps): ReactElement => {
  const iconName = `${icon}${fill ? '-fill' : ''}`;
  const Icon = commonIcons[iconName];
  const IconVariant = Icon?.[variant];

  return (
    <React.Fragment>
      <span className={className} data-component="helpers/general/googlematerialsymbol" {...props}>
        {IconVariant ? <IconVariant /> : <SwrIcon icon={icon} fill={fill} variant={variant} />}
      </span>
    </React.Fragment>
  );
};

function SwrIcon({ fill, icon, variant }: GoogleMaterialSymbolProps): ReactElement | null {
  const iconUri = `${getStaticAssetUrl()}/assets/google-material-symbols/${variant}/${icon}${
    fill ? '-fill' : ''
  }.svg`;
  const fetcher = async (url: string) => {
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Failed to fetch icon: ${res.statusText}`);
    }

    return res.text();
  };
  const { data } = useSWR(iconUri, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return data ? <>{convertHtmlToReact(data)}</> : null;
}

export default GoogleMaterialSymbol;
