// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import fallback from 'lib/fallback/fallback';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { useI18n } from 'next-localization';
import { apiMockData } from 'src/stories/authorable/FeaturedJobCard/FeaturedJobCard.mock-data';
import { tailwindVariants } from './FeaturedJobCardTailwind';

export type FeaturedJobCardProps = ComponentProps &
  CardComponents.FeaturedJobCard.FeaturedJobCardsList;

interface JobCardProps {
  title: string;
  location: string;
  category: string;
  url: string;
  date: string;
}

type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;

type CardOrientation = 0 | 4 | 3 | 2 | undefined;

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

// Featured Job Card Component
/**
 * Featured Job Card has three orientation
 * 1. If 2 has been selected from backend then the orientation will be as below
 * - 2x2, full width
 * 2. If 3 has been selected from backend then the orientation will be as below
 * - 3 column, 1 row, full width
 * 3. If 3 has been selected from backend then the orientation will be as below
 * - 4 column, 1 row, full width
 */

const JobCard: React.FC<JobCardProps> = ({ title, location, category, url }) => {
  const {
    cardBody,
    cardContent,
    cardTitleText,
    cardSubtitleText,
    cardCtaButton,
    cardDescriptionText,
  } = tailwindVariants();
  const i18n = useI18n();
  const ViewJob = i18n.t('ViewJob');

  return (
    <div className={cardBody()}>
      <div className={cardContent()}>
        {title && <Text className={cardTitleText()} encode={false} field={{ value: title }} />}
        {location && (
          <Text className={cardSubtitleText()} encode={false} field={{ value: location }} />
        )}
        {category && (
          <Text className={cardDescriptionText()} encode={false} field={{ value: category }} />
        )}
      </div>
      <Button
        childClass={cardCtaButton()}
        type="filled"
        color="tonal"
        // we need this, update after confirmation
        label={ViewJob}
        href={url}
        tag="a"
      />
    </div>
  );
};

const FeaturedJobCard = (props: FeaturedJobCardProps): JSX.Element => {
  const componentVariants = ComponentVariants();

  const [loading, setLoading] = useState<boolean>(true);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const [jobs, setJobs] = useState<any>('');
  const [error, setError] = useState<string | null>(null);

  const { alignCTA } = props?.params || {};

  const { componentName, dataSource } = props?.rendering || {};

  const cardCount: string = props?.params?.grid;

  let cardOrientation = cardCount === '4column' ? 4 : cardCount === '3column' ? 3 : 2;

  const {
    base,
    componentBG,
    errorCardContent,
    cardWrapper,
    ctaWrapper,
    descriptionText,
    legalDisclaimerText,
    titleAndDescriptionWrapperInner,
    titleAndDescriptionWrapperOuter,
    titleText,
    loaderWrapper,
    searchLoaderAnimation,
    wrapper,
  } = tailwindVariants({
    alignCTA: alignCTA as AlignCTA,
    cardOrientation: cardOrientation as CardOrientation,
  });

  const i18n = useI18n();
  const JobCardsError = i18n.t('JobCardsError');
  const domainName = 'https://xmc-eh-2gsl2sgpxwdwj3eejdwivd.sitecorecloud.io';

  useEffect(() => {
    if (props?.params?.isInStorybook === 'true') {
      setJobs(apiMockData);
    }
  }, [props?.params?.isInStorybook]);

  useEffect(() => {
    let isMounted = true;

    const fetchJobData = async () => {
      try {
        let response;
        if (process.env.IS_EDITING_HOST) {
          response = await fetch(`${domainName}/api/featuredJobCards`, {
            headers: {
              'Content-Type': 'text/xml',
            },
          });
        } else {
          response = await fetch('/api/featuredJobCards', {
            headers: {
              'Content-Type': 'text/xml',
            },
          });
        }

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const xmlText = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, 'application/xml');

        // Extract all job elements
        const jobNodes = xmlDoc.getElementsByTagName('job');
        const jobList: JobCardProps[] = [];

        // Determine how many jobs to fetch based on cardOrientation (2, 3, or 4) to improve performance
        const limit = cardOrientation; // cardOrientation would be 2, 3, or 4

        // Loop through each <job> node and extract the required data
        for (let i = 0; i < jobNodes.length && jobList.length < limit; i++) {
          const jobNode = jobNodes[i];

          /* The above code is creating a `job` object by extracting data from XML elements using
         `getElementsByTagName` method. It retrieves the title, location, category, url, and 
          date information from the XML nodes and assigns them to the corresponding properties 
          of the `job` object. Finally, the `job` object is pushed into a `jobList` array. */
          const job: JobCardProps = {
            title: jobNode.getElementsByTagName('title')[0]?.textContent || '',
            location: jobNode.getElementsByTagName('locations')[0]?.textContent || '',
            category: jobNode.getElementsByTagName('category')[0]?.textContent || '',
            url: jobNode.getElementsByTagName('url')[0]?.textContent || '',
            date: jobNode.getElementsByTagName('date')[0]?.textContent || '',
          };

          jobList.push(job);
        }

        if (isMounted) {
          setLoading(false);
          setJobs(jobList); // Set the full list of jobs
        }
      } catch (err) {
        if (isMounted) {
          setError(JobCardsError);
        }
      }
    };

    fetchJobData();

    return () => {
      isMounted = false; // Cleanup
    };
  }, [cardOrientation, JobCardsError]);

  if (!props.fields) return <></>;

  const {
    description,
    primaryCTA,
    title,
    multiColorBar,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  /* The value of the variable `cardCount`,
  setting the variable `cardOrientation` to a specific number. If `cardCount` is equal to
  '4column', `cardOrientation` will be set to 8. If `cardCount` is equal to '3column',
  `cardOrientation` will be set to 3. If `cardCount` is equal to '2column', 
  `cardOrientation` will be set to 2. */
  if (cardCount === '4column') {
    cardOrientation = 8;
  } else if (cardCount === '3column') {
    cardOrientation = 3;
  } else if (cardCount === '2column') {
    cardOrientation = 2;
  }

  const id = props?.params?.RenderingIdentifier;

  return (
    <>
      <div
        className={componentBG()}
        data-component="authorable/cards/featuredjobcard1"
        id={id ? id : undefined}
      >
        {/* Render top part of the border */}
        {multiColorBar?.value && componentVariants?.multipleBar?.top && (
          <MultiColorBorder
            multipleBar="top"
            skewXdeg={componentVariants?.multipleBar?.skewX?.value}
          />
        )}
        <Container>
          <div
            className={base()}
            data-component="authorable/general/featuredjobcard"
            data-testid="featuredjobcard"
          >
            <div className={wrapper()}>
              {title && (
                <div className={titleAndDescriptionWrapperOuter()}>
                  <div className={titleAndDescriptionWrapperInner()}>
                    {title?.value && <Text className={titleText()} field={title} tag="h2" />}
                    {description?.value && (
                      <Text
                        className={descriptionText()}
                        encode={false}
                        field={description}
                        tag="p"
                      />
                    )}
                  </div>
                  {alignCTA === 'TopRight' && primaryCTA?.value?.href !== '' && (
                    <div className={ctaWrapper()}>
                      <Button
                        label={primaryCTA?.value.text}
                        // The design requires an outline CTA but field name is primaryCTA,
                        // so for that we have added a fallback as an outline value,
                        // so if there is no value in sitecore field, it will take the outline value
                        type={primaryCTAType?.value || fallbackComponentVariantType}
                        color={primaryCTAColor?.value || fallbackComponentVariantColor}
                        tag="a"
                        target={primaryCTA?.value?.target}
                        href={primaryCTA?.value?.href}
                        gtmEvent={{
                          event: 'cta_click',
                          type: 'primary',
                          'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                          'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                          'gtm.element.dataset.gtmDatasourceId': dataSource,
                          'gtm.element.dataset.gtmComponentName': componentName,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

              {
                // Display an error message if there is an error or if jobs array is empty
                // We are adding custom message, for time being it is hard coded. After confirmation from client we'll do the updates accordingly. For Now we need this.
                error && props?.params?.isInStorybook !== 'true' ? (
                  <div className={errorCardContent()}>{error}</div>
                ) : loading && props?.params?.isInStorybook !== 'true' ? (
                  // Show the loading animation if the data is still being fetched
                  <div className={loaderWrapper()}>
                    <div className={searchLoaderAnimation()} />
                  </div>
                ) : props?.params?.isInStorybook === 'true' ? (
                  <div className={cardWrapper()}>
                    {apiMockData
                      .slice(0, cardOrientation)
                      .map((job: JobCardProps, index: number) => (
                        <JobCard
                          key={index}
                          title={job?.title}
                          location={job?.location}
                          category={job?.category}
                          url={job?.url}
                          date={job?.date}
                        />
                      ))}
                  </div>
                ) : (
                  jobs &&
                  jobs.length > 0 && (
                    // Display jobs if available and fetched
                    <div className={cardWrapper()}>
                      {/* Limit the number of jobs displayed to match cardOrientation */}
                      {jobs.slice(0, cardOrientation).map((job: JobCardProps, index: number) => (
                        <JobCard
                          key={index}
                          title={job?.title}
                          location={job?.location}
                          category={job?.category}
                          url={job?.url}
                          date={job?.date}
                        />
                      ))}
                    </div>
                  )
                )
              }

              {alignCTA === 'BottomCenter' && primaryCTA?.value?.href !== '' && (
                <div className={ctaWrapper()}>
                  <Button
                    // The design requires an outline CTA but field name is primaryCTA,
                    // so for that we have added a fallback as an outline value,
                    // so if there is no value in sitecore field, it will take the outline value
                    type={primaryCTAType?.value || fallbackComponentVariantType}
                    color={primaryCTAColor?.value || fallbackComponentVariantColor}
                    label={primaryCTA?.value.text}
                    href={primaryCTA?.value?.href}
                    tag="a"
                    target={primaryCTA?.value?.target}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'primary',
                      'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': dataSource,
                      'gtm.element.dataset.gtmComponentName': componentName,
                    }}
                  />
                </div>
              )}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </Container>
        {/* Render bottom part of the border */}
        {multiColorBar?.value && componentVariants?.multipleBar?.bottom && (
          <MultiColorBorder
            multipleBar="bottom"
            skewXdeg={componentVariants?.multipleBar?.skewX?.value}
          />
        )}
      </div>
    </>
  );
};

export default FeaturedJobCard;
