// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { useTheme } from 'lib/context/ThemeContext';
import { HeroComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentVariants } from 'lib/context/ComponentVariants';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import SVG from 'helpers/SVG/SVG';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import fallback from 'lib/fallback/fallback';
import { tailwindVariants, tailwindVariantsForWithImageVariant } from './HeroInternalTailwind';

export type HeroInternalProps = ComponentProps &
  HeroComponents.HeroInternal.HeroInternal & {
    theme?: string;
  };

type Alignment = 'Left' | 'Center' | undefined;

type ShowPattern = true | false | undefined;

const HeroInternal = (props: HeroInternalProps): JSX.Element => {
  const {
    description,
    enablePattern,
    primaryCTA,
    title,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};
  const { alignContent } = props?.params || {};
  const { dataSource, componentName } = props?.rendering || {};

  const { themeName } = useTheme();
  const componentVariants = ComponentVariants();

  const alignment = alignContent !== 'Left' && 'Center';
  const showPattern = enablePattern?.value ? true : false;

  const {
    base,
    titleText,
    descriptionText,
    svgBackground,
    svgDecor,
    textWrapper,
    contentCtaWrapper,
    disclaimerClasses,
  } = tailwindVariants({
    alignment: alignment as Alignment,
    showPattern: showPattern as ShowPattern,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  if (!props?.fields) return <></>;

  return (
    <Container fullWidth>
      <div className={base()} data-component="authorable/heroes/internalhero">
        {componentVariants?.heroInternal?.hasDecor &&
          (themeName == 'Off' || themeName == 'Autan') && (
            <SVG className={svgDecor()} svg={`hero-internal/Decor,Brand=${themeName}`} />
          )}
        <SVG className={svgBackground()} svg={`hero-internal/BG,Brand=${themeName}`} />
        <div className={textWrapper()}>
          <Text className={titleText()} encode={false} field={title} tag="h1" />
          {props.fields?.description && (
            <RichTextA11yWrapper className={descriptionText()} field={description} />
          )}
          {primaryCTA?.value?.href && (
            <div className={contentCtaWrapper()}>
              <Button
                href={primaryCTA?.value?.href}
                label={primaryCTA?.value?.text}
                tag="a"
                target={primaryCTA?.value?.target}
                color={primaryCTAColor?.value || 'white'}
                type={primaryCTAType?.value || 'filled'}
                size="compressed"
                gtmEvent={{
                  event: 'cta_click',
                  type: 'primary',
                  'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                  'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              >
                <LinkWrapper field={primaryCTA} />
              </Button>
            </div>
          )}
          {disclaimerText?.value != '' && (
            <LegalDisclaimer
              disclaimerText={disclaimerText}
              disclaimerClasses={disclaimerClasses()}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export const WithImage = (props: HeroInternalProps): JSX.Element => {
  const {
    description,
    primaryCTA,
    secondaryCTA,
    title,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
    secondaryCTAColor,
    secondaryCTAType,
    subtitle,
    logo,
    image,
  } = props?.fields || {};
  const { dataSource, componentName } = props?.rendering || {};

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.topBar?.value;
  const fallbackComponentVariantType = fallback?.topBar?.type;

  const {
    base,
    leftSideContentWrapper,
    logoWrapper,
    contentWrapper,
    contentTitle,
    contentSubtitle,
    contentDescription,
    contentCtaWrapper,
    primaryCtaWrapper,
    secondaryCtaWrapper,
    disclaimerClasses,
    rightSideImageWrapper,
    imageClasses,
  } = tailwindVariantsForWithImageVariant({});

  if (!props?.fields) return <></>;

  return (
    <Container fullWidth>
      <div className={base()} data-component="authorable/heroes/internalheroWithImage">
        <div className={leftSideContentWrapper()}>
          {logo?.value?.src && (
            <div className={logoWrapper()}>
              <ImageWrapper field={logo} />
            </div>
          )}
          <div className={contentWrapper()}>
            {title?.value && (
              <Text className={contentTitle()} encode={false} field={title} tag="h1" />
            )}
            {subtitle?.value && (
              <Text className={contentSubtitle()} encode={false} field={subtitle} tag="p" />
            )}
            {description?.value && (
              <RichTextA11yWrapper className={contentDescription()} field={description} />
            )}
          </div>
          <div className={contentCtaWrapper()}>
            {primaryCTA?.value?.text && (
              <div className={primaryCtaWrapper()}>
                <Button
                  href={primaryCTA?.value?.href}
                  target={primaryCTA?.value?.target}
                  label={primaryCTA?.value?.text}
                  tag="a"
                  fullWidth
                  // As the design requires a filled CTA, we do not need to add a fallback since
                  // the button component has a default variant that displays a filled CTA
                  color={primaryCTAColor?.value || fallbackComponentVariantColor}
                  type={primaryCTAType?.value || 'filled'}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'primary',
                    'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              </div>
            )}
            {secondaryCTA?.value?.text && (
              <div className={secondaryCtaWrapper()}>
                <Button
                  href={secondaryCTA?.value?.href}
                  target={secondaryCTA?.value?.target}
                  label={secondaryCTA?.value?.text}
                  tag="a"
                  fullWidth
                  // The design requires an outline CTA, so for that we have added a fallback as an outline value,
                  // so if there is no value in sitecore field, it will take the outline value.
                  type={secondaryCTAType?.value || fallbackComponentVariantType}
                  color={secondaryCTAColor?.value || fallbackComponentVariantColor}
                  gtmEvent={{
                    event: 'cta_click',
                    type: 'secondary',
                    'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                    'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                    'gtm.element.dataset.gtmDatasourceId': dataSource,
                    'gtm.element.dataset.gtmComponentName': componentName,
                  }}
                />
              </div>
            )}
          </div>
          {disclaimerText?.value != '' && (
            <LegalDisclaimer
              disclaimerText={disclaimerText}
              disclaimerClasses={disclaimerClasses()}
            />
          )}
        </div>
        <div className={rightSideImageWrapper()}>
          <ImageWrapper field={image} className={imageClasses()} />
        </div>
      </div>
    </Container>
  );
};

export default HeroInternal;
