// Global
import React from 'react';
import Parser from 'html-react-parser';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';

// Types
export type JebbitProps = ComponentProps & DartComponents.Jebbit.Jebbit;

const Jebbit = (props: JebbitProps): JSX.Element => {
  const id = props?.params?.RenderingIdentifier;

  if (!props.fields) return <>Jebbit Component</>;

  return (
    <div data-component="authorable/jebbit" id={id ? id : undefined} tabIndex={id ? -1 : 1}>
      <Container>
        {props?.fields?.embedIframe?.value && (
          <div className="py-spacing-spacing-7">{Parser(props?.fields?.embedIframe?.value)}</div>
        )}
      </Container>
    </div>
  );
};

export default Jebbit;
