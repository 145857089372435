import React, { useEffect } from 'react';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Parser from 'html-react-parser';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

export type BazaarVoiceSocialProps = ComponentProps &
  DartComponents.BazaarVoiceSocial.BazaarVoiceSocial;

const BazaarVoiceSocial = (props: BazaarVoiceSocialProps): JSX.Element => {
  const { galleriesHTML } = props?.fields || {};

  const context = useSitecoreContext();
  const crL8Sitename = context?.sitecoreContext?.bazaarVoice?.crL8Sitename;

  useEffect(() => {
    const CRL8_SITENAME = crL8Sitename; // Replace with your actual site name
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://edge.curalate.com/sites/Glade-5ck3MK/site/latest/site.min.js`;
    console.log(
      'test',
      `${window.location.protocol}//edge.curalate.com/sites/${CRL8_SITENAME}/site/latest/site.min.js`
    );
    script.onload = () => {
      if (window.crl8) {
        window.crl8.createExperience('homepage');
      }
    };
    document.body.appendChild(script);

    return () => {
      if (window.crl8) {
        window.crl8.destroyAllExperiences();
      }
    };
  }, []);
  if (!props.fields) return <>Bazaar Voice Social</>;

  return (
    <div data-component="authorable/bazaarvoicesocial">
      <Container>
        <div className="mt-spacing-spacing-7 bazarvoice">
          {galleriesHTML ? Parser(galleriesHTML?.value) : ''}
        </div>
      </Container>
    </div>
  );
};

export default BazaarVoiceSocial;
