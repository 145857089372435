// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { useTheme } from 'lib/context/ThemeContext';
import { CardComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import SVG from 'helpers/SVG/SVG';
import fallback from 'lib/fallback/fallback';
import { useI18n } from 'next-localization';
import { stripHtml } from 'lib/utils/regex';
import { tailwindVariants } from './GenericCardTailwind';

export type GenericCard = ItemEx & CardComponents.GenericCard.GenericCardItem;

export type GenericCardProps = ComponentProps & CardComponents.GenericCard.GenericCardsList;

type AlignCTA = 'BottomCenter' | 'TopRight' | undefined;

type CardOrientation = 0 | 4 | 3 | 2 | undefined;

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

// Generic Card Component
/**
 * Generic Card has three orientation
 * 1. If 2 has been selected from backend then the orientation will be as below
 * - 2x2, full width
 * 2. If 3 has been selected from backend then the orientation will be as below
 * - 3 column, 1 row, full width
 * 3. If 3 has been selected from backend then the orientation will be as below
 * - 4 column, 1 row, full width
 */

const GenericCard = (props: GenericCardProps): JSX.Element => {
  const componentVariants = ComponentVariants();
  const { themeName } = useTheme();
  const i18n = useI18n();
  const About = i18n.t('AccessibilityAbout') ? i18n.t('AccessibilityAbout') : 'about';
  if (!props.fields) return <></>;

  const {
    description,
    genericCards,
    primaryCTA,
    title,
    multiColorBar,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
  } = props?.fields || {};

  const { alignCTA } = props?.params || {};

  const { componentName, dataSource } = props?.rendering || {};

  const cardCount: number = genericCards?.length || 0;

  let cardOrientation: CardOrientation = 0;

  if (cardCount >= 4) {
    cardOrientation = 4;
  } else if (cardCount === 3) {
    cardOrientation = 3;
  } else if (cardCount === 2) {
    cardOrientation = 2;
  }

  const {
    base,
    card,
    cardBody,
    cardCtaWrapper,
    cardDescriptionText,
    cardHead,
    cardSubtitleText,
    cardTitleText,
    cardWrapper,
    componentBG,
    ctaWrapper,
    descriptionText,
    legalDisclaimerText,
    svgDecorLeft,
    svgDecorRight,
    titleAndDescription,
    titleAndDescriptionWrapperInner,
    titleAndDescriptionWrapperOuter,
    titleText,
  } = tailwindVariants({
    alignCTA: alignCTA as AlignCTA,
    cardOrientation,
  });

  const id = props?.params?.RenderingIdentifier;

  return (
    <>
      <div
        className={componentBG()}
        data-component="authorable/cards/genericcard"
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        {/* Render top part of the border */}
        {multiColorBar?.value && componentVariants?.multipleBar?.top && (
          <MultiColorBorder
            multipleBar="top"
            skewXdeg={componentVariants?.multipleBar?.skewX?.value}
          />
        )}

        <Container>
          <div
            className={base()}
            data-component="authorable/general/genericcard"
            data-testid="genericcard"
          >
            {componentVariants?.genericCardListing?.hasDecor && (
              <>
                <SVG className={svgDecorLeft()} svg={`GenericCard/Decor_Left,Brand=${themeName}`} />
                <SVG
                  className={svgDecorRight()}
                  svg={`GenericCard/Decor_Right,Brand=${themeName}`}
                />
              </>
            )}
            <div className={titleAndDescription()}>
              {title && (
                <div className={titleAndDescriptionWrapperOuter()}>
                  <div className={titleAndDescriptionWrapperInner()}>
                    {title?.value && <Text className={titleText()} field={title} tag="h2" />}
                    {description?.value && (
                      <Text
                        className={descriptionText()}
                        encode={false}
                        field={description}
                        tag="p"
                      />
                    )}
                  </div>
                  {alignCTA == 'TopRight' && primaryCTA?.value?.href !== '' && (
                    <div className={ctaWrapper()}>
                      <Button
                        label={primaryCTA?.value.text}
                        title={primaryCTA?.value?.title}
                        // The design requires an outline CTA but field name is primaryCTA,
                        // so for that we have added a fallback as an outline value,
                        // so if there is no value in sitecore field, it will take the outline value
                        type={primaryCTAType?.value || fallbackComponentVariantType}
                        color={primaryCTAColor?.value || fallbackComponentVariantColor}
                        tag="a"
                        target={primaryCTA?.value?.target}
                        href={primaryCTA?.value?.href}
                        gtmEvent={{
                          event: 'cta_click',
                          type: 'primary',
                          'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                          'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                          'gtm.element.dataset.gtmDatasourceId': dataSource,
                          'gtm.element.dataset.gtmComponentName': componentName,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {genericCards?.length && (
                <div className={cardWrapper()}>
                  {genericCards?.map((cardData: GenericCard, index) => {
                    const {
                      description,
                      image,
                      primaryCTA,
                      secondaryCTA,
                      subtitle,
                      title,
                      primaryCTAType,
                      primaryCTAColor,
                      secondaryCTAType,
                      secondaryCTAColor,
                    } = cardData?.fields || {};
                    const { id, name } = cardData;
                    const ariaLabel = stripHtml(title?.value as string);
                    return (
                      <div className={card()} key={index}>
                        <div className={cardHead()}>
                          {image && <ImageWrapper field={image} layout="intrinsic" />}
                        </div>
                        <div className={cardBody()}>
                          {title?.value && (
                            <Text
                              className={cardTitleText()}
                              encode={false}
                              field={title}
                              tag="p"
                            />
                          )}
                          {subtitle?.value && (
                            <Text
                              className={cardSubtitleText()}
                              encode={false}
                              field={subtitle}
                              tag="p"
                            />
                          )}
                          {description?.value && (
                            <RichTextA11yWrapper
                              className={cardDescriptionText()}
                              field={description}
                            />
                          )}
                          <div className={cardCtaWrapper()}>
                            {primaryCTA?.value?.text && (
                              <Button
                                //As the design requires a filled CTA, we do not need to add a fallback since
                                //the button component has a default variant that displays a filled CTA.
                                type={primaryCTAType?.value as string}
                                color={primaryCTAColor?.value}
                                label={primaryCTA?.value?.text}
                                title={
                                  `${primaryCTA?.value?.title}` ||
                                  `${primaryCTA?.value?.text} ${About} ${ariaLabel}`
                                }
                                tag="a"
                                target={primaryCTA?.value?.target}
                                href={primaryCTA?.value?.href}
                                gtmEvent={{
                                  event: 'cta_click',
                                  type: 'primary',
                                  'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                                  'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                                  'gtm.element.dataset.gtmDatasourceId': id,
                                  'gtm.element.dataset.gtmComponentName': name,
                                }}
                              />
                            )}
                            {secondaryCTA?.value.text && (
                              <Button
                                //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                                //so if there is no value in sitecore field, it will take the outline value.
                                type={secondaryCTAType?.value || fallbackComponentVariantType}
                                color={secondaryCTAColor?.value || fallbackComponentVariantColor}
                                label={secondaryCTA?.value?.text}
                                title={
                                  `${secondaryCTA?.value?.title}` ||
                                  `${secondaryCTA?.value?.text} ${About} ${ariaLabel}`
                                }
                                tag="a"
                                target={secondaryCTA?.value?.target}
                                href={secondaryCTA?.value?.href}
                                gtmEvent={{
                                  event: 'cta_click',
                                  type: 'secondary',
                                  'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                                  'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                                  'gtm.element.dataset.gtmDatasourceId': id,
                                  'gtm.element.dataset.gtmComponentName': name,
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {alignCTA == 'BottomCenter' && primaryCTA?.value?.href !== '' && (
                <div className={ctaWrapper()}>
                  <Button
                    // The design requires an outline CTA but field name is primaryCTA,
                    // so for that we have added a fallback as an outline value,
                    // so if there is no value in sitecore field, it will take the outline value
                    type={primaryCTAType?.value || fallbackComponentVariantType}
                    color={primaryCTAColor?.value || fallbackComponentVariantColor}
                    label={primaryCTA?.value.text}
                    title={primaryCTA?.value?.title}
                    href={primaryCTA?.value?.href}
                    tag="a"
                    target={primaryCTA?.value?.target}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'primary',
                      'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': dataSource,
                      'gtm.element.dataset.gtmComponentName': componentName,
                    }}
                  />
                </div>
              )}
            </div>
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerText={disclaimerText}
                disclaimerClasses={legalDisclaimerText()}
              />
            )}
          </div>
        </Container>

        {/* Render bottom part of the border */}
        {multiColorBar?.value && componentVariants?.multipleBar?.bottom && (
          <MultiColorBorder
            multipleBar="bottom"
            skewXdeg={componentVariants?.multipleBar?.skewX?.value}
          />
        )}
      </div>
    </>
  );
};

export default GenericCard;
