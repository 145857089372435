// Global
import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import SVG from 'helpers/SVG/SVG';
import { useTheme } from 'lib/context/ThemeContext';
import { tailwindVariantsBrandCard } from './GlossaryTabTailwind';

export type GlossaryTabProps = ComponentProps & DartComponents.GlossaryTab.GlossaryTabsList;
export type TabList = DartComponents.GlossaryTab.Tab;

interface Field<T> {
  value: T;
}

const GlossaryTab = (props: GlossaryTabProps): JSX.Element => {
  const { tabs } = props?.fields ?? {};

  const { themeName } = useTheme();

  let initialTab = '';

  if (tabs?.[0]?.fields?.title) {
    initialTab = (tabs[0].fields.title as Field<string>).value;
  }

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  const id = props?.params?.RenderingIdentifier;

  // Ensure the selected tab is updated when the component mounts
  useEffect(() => {
    if (tabs?.[0]?.fields?.title) {
      setSelectedTab((tabs[0].fields.title as Field<string>).value);
    }
    handleTabClick(selectedTab);
  }, [tabs, selectedTab]);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName); // Update the selected tab when clicked
  };

  if (!tabs) return <></>;
  const filteredTabs = tabs
    .map((tab: TabList, index: number) => ({ tab, index })) // Pair each tab with its index
    .filter(({ tab }) => tab?.fields?.title?.value === selectedTab);

  const {
    base,
    filterTabButtons,
    filterTabBrandTitle,
    filterTabContainer,
    svgClassLarge,
    svgClassExtraLarge,
  } = tailwindVariantsBrandCard({});

  return (
    <Container>
      <div
        className={base()}
        id={id ? id : undefined}
        data-component="authorable/glossarytab"
        tabIndex={id ? -1 : 1}
      >
        <div className={filterTabButtons()}>
          {tabs?.map((tab: TabList, index: number) => {
            const { title } = tab?.fields ?? {};

            return (
              <div
                key={index}
                data-id={index}
                className={`${filterTabBrandTitle()} ${
                  selectedTab === tab?.fields?.title?.value &&
                  'bg-components-button-group-color-button-bg-active !text-components-button-group-color-button-fg-active hover:!text-components-button-group-color-button-fg-default'
                }`}
                onClick={() => handleTabClick(tab?.fields?.title?.value || '')}
              >
                {title?.value && <Text encode={false} field={title} />}
              </div>
            );
          })}
        </div>

        <SVG className={svgClassLarge()} svg={`rule-lines/Breakpoint=Large,Brand=${themeName}`} />
        <SVG
          className={svgClassExtraLarge()}
          svg={`rule-lines/Breakpoint=ExtraLarge,Brand=${themeName}`}
        />

        <div className={filterTabContainer()}>
          {filteredTabs.map(({ index }) => {
            const phKey = `glossarytab-${index}-{*}`; // Use the original index of the tab
            return <Placeholder key={index} name={phKey} rendering={props.rendering} />;
          })}
        </div>
      </div>
    </Container>
  );
};

export default GlossaryTab;
