// GLobal
import React from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { tailwindVariants } from './ContentListTailwind';
import { useTheme } from 'lib/context/ThemeContext';

export type ContentListProps = ComponentProps & CompositeComponents.ContentList.ContentList;

const ContentList = (props: ContentListProps): JSX.Element => {
  const { themeName } = useTheme();

  const { base, componentWrapper, itemDescription, itemIcon, itemWrapper } = tailwindVariants.base({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div
      className={componentWrapper()}
      data-component="authorable/contentlist"
      id={id ? id : undefined}
      tabIndex={-1}
    >
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          <>
            {props?.fields?.contentListItems?.map((content, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className={itemWrapper({
                      withIcon: !!content.fields.icon?.value,
                    })}
                    data-id={index}
                    key={index}
                  >
                    {content.fields?.icon?.value && (
                      <GoogleMaterialSymbol
                        className={itemIcon()}
                        icon={content.fields?.icon?.value}
                      />
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </>
        </div>
      </Container>
    </div>
  );
};

// DIRECTIONS
export const WithSteps = (props: ContentListProps): JSX.Element => {
  const { themeName } = useTheme();

  const { base, componentWrapper, itemDescription, itemNum, itemTitle, itemWrapper } =
    tailwindVariants.base({
      /* eslint-disable  @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      brand: themeName as string,
    });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} id={id ? id : undefined} tabIndex={-1}>
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            const itemNumber = index + 1;
            return (
              <React.Fragment key={index}>
                <div className={itemWrapper()} data-id={index} key={index}>
                  <div className={itemNum()}>{itemNumber}.</div>
                  <div>
                    {content.fields.title?.value && (
                      <h2>
                        <RichTextA11yWrapper
                          className={itemTitle()}
                          field={content.fields?.title}
                        />
                      </h2>
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export const WithMultipleBulletLists = (props: ContentListProps): JSX.Element => {
  const { themeName } = useTheme();

  const { base, componentWrapper, itemDescription, itemTitle, itemWrapper } = tailwindVariants.base(
    {
      /* eslint-disable  @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      brand: themeName as string,
    }
  );
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} id={id ? id : undefined} tabIndex={-1}>
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className={itemWrapper()} data-id={index} key={index}>
                  <div>
                    {content.fields.title?.value && (
                      <h2>
                        <RichTextA11yWrapper
                          className={itemTitle()}
                          field={content.fields?.title}
                        />
                      </h2>
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export const WithBulletsWithInTheSteps = (props: ContentListProps): JSX.Element => {
  const { themeName } = useTheme();

  const { base, componentWrapper, itemDescription, itemNum, itemTitle, itemWrapper } =
    tailwindVariants.base({
      /* eslint-disable  @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      brand: themeName as string,
    });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} tabIndex={-1}>
      <Container>
        <div className={base()} id={id ? id : undefined}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            const itemNumber = index + 1;
            return (
              <React.Fragment key={index}>
                <div className={itemWrapper()} data-id={index} key={index}>
                  <div className={itemNum()}>{itemNumber}.</div>
                  <div>
                    {content.fields.title?.value && (
                      <h2>
                        <RichTextA11yWrapper
                          className={itemTitle()}
                          field={content.fields?.title}
                        />
                      </h2>
                    )}
                    <RichTextA11yWrapper
                      className={itemDescription()}
                      field={content?.fields?.description}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

// INGREDIENTS
export const WithUnorderedList = (props: ContentListProps): JSX.Element => {
  const { themeName } = useTheme();

  const { base, componentWrapper, itemDescription, itemContentWrapper, itemWrapper } =
    tailwindVariants.base({
      withUnorderedList: true,
      /* eslint-disable  @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      brand: themeName as string,
    });
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <div className={componentWrapper()} id={id ? id : undefined} tabIndex={-1}>
      <Container>
        <div className={base()}>
          <ContentListHeader {...props} />
          {props?.fields?.contentListItems?.map((content, index: number) => {
            return (
              <div className={itemWrapper()} data-id={index} key={index}>
                <div className={itemContentWrapper()}>
                  <RichTextA11yWrapper
                    className={itemDescription()}
                    field={content?.fields?.description}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

const ContentListHeader = (props: ContentListProps): JSX.Element => {
  const { base, description, header, subHeader } = tailwindVariants.listHeaderTailwindVariants();
  // Unique id for component
  const { uid } = props.rendering;

  return (
    <>
      {(props?.fields?.header?.value ||
        props?.fields?.subHeader?.value ||
        props?.fields?.description?.value) && (
        <div className={base()} id={uid ? uid : undefined} tabIndex={uid ? -1 : 1}>
          {props?.fields?.header?.value && (
            <RichTextA11yWrapper
              className={header({
                onlyHeader: !props.fields.subHeader?.value && !props.fields.description?.value,
              })}
              field={props?.fields?.header}
            />
          )}
          {props?.fields?.subHeader?.value && (
            <RichTextA11yWrapper className={subHeader()} field={props?.fields?.subHeader} />
          )}
          {props?.fields?.description?.value && (
            <RichTextA11yWrapper className={description()} field={props?.fields?.description} />
          )}
        </div>
      )}
    </>
  );
};

export default ContentList;
