// Global
import { ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Video from 'helpers/Video/Video';
import Button from 'helpers/Button/Button';
import YouTubeThumbnail from 'helpers/YoutubeThumbnail/YoutubeThumbnail';
import { tailwindVariants } from './MediaVideoTailwind';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';

export type MediaVideoProps = ComponentProps & DartComponents.MediaVideo.MediaVideoList;
export type MediaVideo = DartComponents.MediaVideo.MediaVideo;

export type videoWidht = 'full-width' | 'half-width' | 'one-third-width' | undefined;

/**
 * Width Options
 1. Full width
 2. Half width
 3. 1/3 width 
 */
const MediaVideo = (props: MediaVideoProps): JSX.Element => {
  const id = props?.params?.RenderingIdentifier;

  // Params to check width of the video.
  const { width } = props?.params || '';

  // Tailwind variants
  const {
    wrapper,
    base,
    mediaCard,
    mediaWrapper,
    captionContainer,
    caption,
    subtitle,
    description,
    button,
    playButton,
  } = tailwindVariants({
    width: width as videoWidht,
  });

  const [modalIsOpen, setModalIsOpen] = useState<{ [key: number]: boolean }>({});

  // Function to open modal for a specific item
  const openModal = (id: number) => {
    setModalIsOpen({ ...modalIsOpen, [id]: true });
  };

  // Function to close modal for a specific item
  const closeModal = (id: number) => {
    setModalIsOpen({ ...modalIsOpen, [id]: false });
  };

  // Dictionary Item for aria-label
  const i18n = useI18n();
  const Play = i18n.t('Play');
  const Close = i18n.t('Close');

  Modal.setAppElement(id);

  return (
    <div className={wrapper()} data-component="authorable/MedioVideo" id={id ? id : undefined}>
      <Container className={base()}>
        {props?.fields?.videos?.map((media: MediaVideo, index) => {
          return (
            <>
              <div className={mediaCard()}>
                {/* Modal for each item */}
                <Modal
                  isOpen={modalIsOpen[index] || false}
                  onRequestClose={() => closeModal(index)}
                  contentLabel={media?.fields?.caption?.value}
                  className="modal"
                  overlayClassName="overlay"
                  shouldCloseOnOverlayClick={false}
                >
                  <Video
                    field={media?.fields?.videoId}
                    responsive
                    class="aspect-video [&_iframe]:rounded-none"
                    autoplay={true}
                    controls={true}
                  />
                  <Button
                    title={Close}
                    iconLeft="close"
                    childClass={button()}
                    tag="button"
                    type="outline"
                    color="brand"
                    onClick={() => closeModal(index)}
                  />
                </Modal>
                {media?.fields?.videoId?.value && (
                  <div className={mediaWrapper()} onClick={() => openModal(index)}>
                    <>
                      <Button
                        title={Play}
                        type="fill"
                        iconLeft="play_arrow-fill"
                        tag="button"
                        childClass={playButton()}
                        onClick={() => openModal(index)}
                      />
                      {media?.fields?.videoThumbnail?.value?.src === undefined ? (
                        // content author sets video ID to display thubnail image
                        <YouTubeThumbnail videoId={media?.fields?.videoId?.value} />
                      ) : (
                        // content author sets image for thumbnail image
                        <ImageWrapper
                          field={media?.fields?.videoThumbnail as ImageField}
                          className={mediaCard()}
                        />
                      )}
                    </>
                  </div>
                )}
                <div className={captionContainer()}>
                  {media?.fields?.caption?.value && (
                    <div className={caption()}>
                      <Text field={media?.fields?.caption} />
                    </div>
                  )}
                  {media?.fields?.subtitle?.value && (
                    <div className={subtitle()}>
                      <Text field={media?.fields?.subtitle} />
                    </div>
                  )}
                  {media?.fields?.description?.value && (
                    <div className={description()}>
                      <RichTextA11yWrapper field={media?.fields?.description} />{' '}
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </Container>
    </div>
  );
};

export default MediaVideo;
