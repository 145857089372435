// Global

import { tv } from 'tailwind-variants';

export const tailwindVariants = tv({
  slots: {
    base: [
      // Loading Indicator
      '[&_.p-w-r_.pr-loading-indicator]:animate-spin',
      '[&_.p-w-r_.pr-loading-indicator]:h-[80px]',
      '[&_.p-w-r_.pr-loading-indicator]:w-[80px]',
      '[&_.p-w-r_.pr-loading-indicator]:bg-components-omnibox-color-loader-icon-fill-default',
      '[&_.p-w-r_.pr-loading-indicator]:[clip-path:path("M73.3333_40.0038C73.3333_58.4115_58.411_73.3338_40.0033_73.3338C21.5957_73.3338_6.67334_58.4115_6.67334_40.0038C6.67334_21.5962_21.5957_6.67383_40.0033_6.67383C58.411_6.67383_73.3333_21.5962_73.3333_40.0038ZM13.3393_40.0038C13.3393_54.73_25.2772_66.6678_40.0033_66.6678C54.7295_66.6678_66.6673_54.73_66.6673_40.0038C66.6673_25.2777_54.7295_13.3398_40.0033_13.3398C25.2772_13.3398_13.3393_25.2777_13.3393_40.0038Z")]',
      // Write A Review Container
      'flex',
      'max-w-[880px]',
      'mx-auto',
      'pb-10',
      'px-spacing-padding-large-3',
      'md:px-0',
      // Alert
      // Alert - Wrapper
      '[&_.p-w-r_.pr-alert-container]:max-w-full',
      '[&_.p-w-r_.pr-alert-container]:mt-0',
      // Alert - Danger / Error
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger]:p-0',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger]:px-spacing-padding-large-4',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger]:py-spacing-padding-large-3',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger>*]:flex',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger>*]:flex-col',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger>*]:gap-[24px]',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger]:border-color-system-alert-400',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger]:bg-color-system-alert-100',
      '[&_.p-w-r_.pr-alert-container_.pr-alert-danger]:text-color-system-alert-400',
      '[&_.p-w-r_.pr-alert-container_h2]:m-0',
      '[&_.p-w-r_.pr-alert-container_h2]:font-header-large-xSmall',
      '[&_.p-w-r_.pr-alert-container_h2]:leading-header-large-xSmall',
      '[&_.p-w-r_.pr-alert-container_h2]:text-header-large-xSmall',
      '[&_.p-w-r_.pr-alert-container_ul]:mb-0',
      '[&_.p-w-r_.pr-alert-container_ul]:font-bodySans-large',
      '[&_.p-w-r_.pr-alert-container_ul]:leading-bodySans-large',
      '[&_.p-w-r_.pr-alert-container_ul]:text-bodySans-large',
      // Write A Review Content Wrapper
      '[&_.p-w-r_.pr-war]:max-w-full',
      '[&_.p-w-r_.pr-war]:flex',
      '[&_.p-w-r_.pr-war]:flex-col',
      '[&_.p-w-r_.pr-war]:gap-spacing-margin-large-4',
      '[&_.p-w-r]:!flex',
      '[&_.p-w-r]:flex-col',
      '[&_.p-w-r]:flex-1',
      '[&_.p-w-r]:jusitfy-center',
      // Header
      // Header - Wrapper
      '[&_.pr-header]:!border-b-themes-stroke-color-light',
      '[&_.pr-header]:!border-b',
      '[&_.pr-header]:!border-solid',
      '[&_.p-w-r_.pr-header]:mb-0',
      '[&_.p-w-r_.pr-header_.pr-header-table]:flex',
      '[&_.p-w-r_.pr-header_.pr-header-table]:justify-start',
      '[&_.p-w-r_.pr-header_.pr-header-table]:items-center',
      '[&_.p-w-r_.pr-header_.pr-header-table]:gap-[40px]',
      '[&_.p-w-r_.pr-header_.pr-header-table]:p-spacing-padding-large-3',
      '[&_.p-w-r_.pr-header_.pr-header-table]:m-0',
      '[&_.p-w-r_.pr-header_img]:rounded-themes-radius-large-image',
      '[&_.p-w-r_.pr-header-title]:font-header-large-small',
      '[&_.p-w-r_.pr-header-title]:leading-header-large-small',
      '[&_.p-w-r_.pr-header-title]:text-header-large-small',
      '[&_.p-w-r_.pr-header-title]:text-themes-text-color-black',
      '[&_.p-w-r_.pr-header-product-name]:font-bodySans-xLarge-underline',
      '[&_.p-w-r_.pr-header-product-name]:leading-bodySans-xLarge-underline',
      '[&_.p-w-r_.pr-header-product-name]:text-bodySans-xLarge-underline',
      '[&_.p-w-r_.pr-header-product-name>*]:text-themes-text-color-black',
      '[&_.p-w-r_.pr-header-title]:m-0',
      '[&_.p-w-r_.pr-header-product-name]:m-0',
      '[&_.p-w-r_.pr-header-product-name]:mt-spacing-margin-large-3',
      '[&_#pr-war-form_.pr-header-required]:m-0',
      '[&_#pr-war-form_.pr-header-required]:mb-spacing-margin-large-5',
      // Inputs
      '[&_#pr-war-form_.pr-textinput]:rounded-themes-radius-large-form-field-input',
      // Form Group Labels
      '[&_#pr-war-form_.pr-form-control_span]:inline-block',
      '[&_#pr-war-form_.pr-form-control_span]:text-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-form-control_span]:font-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-form-control_span]:leading-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-file-input-label]:text-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-file-input-label]:font-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-file-input-label]:leading-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-header-required_span]:inline-block',
      '[&_#pr-war-form_.pr-header-required_span]:text-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-header-required_span]:text-themes-stroke-color-dark',
      '[&_#pr-war-form_.pr-header-required_span]:font-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-header-required_span]:leading-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-control-label_span]:inline-block',
      '[&_#pr-war-form_.pr-control-label_span]:text-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-control-label_span]:font-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-control-label_span]:leading-bodySans-medium-semibold',
      '[&_#pr-war-form_.pr-control-label_span]:mb-[13px]',
      // Form Group Labels - Error State
      '[&_#pr-war-form_.pr-has-error_.pr-control-label_span]:text-components-text-field-border-destructive',
      '[&_#pr-war-form_.pr-has-error_input]:border-components-text-field-border-destructive',
      '[&_#pr-war-form_.pr-has-error_input::placeholder]:text-components-text-field-border-destructive',
      '[&_#pr-war-form_.pr-has-error_input]:border-components-text-field-border-destructive',
      '[&_#pr-war-form_.pr-has-error_textarea::placeholder]:text-components-text-field-border-destructive',
      '[&_#pr-war-form_.pr-has-error_svg]:fill-components-text-field-border-destructive',
      // Form Groups
      // Form Group - Your Rating
      '[&_#pr-war-form_.pr-rating-form-group]:mb-spacing-margin-large-5',
      '[&_#pr-war-form_.pr-rating-form-group_.pr-control-label_span]:mb-spacing-spacing-4',
      '[&_#pr-war-form_.pr-rating-form-group_.pr-control-label]:mb-0',
      '[&_#pr-war-form_.pr-rating-form-group_.pr-control-label_span]:text-bodySans-large-semibold',
      '[&_#pr-war-form_.pr-rating-form-group_.pr-control-label_span]:font-bodySans-large-semibold',
      '[&_#pr-war-form_.pr-rating-form-group_.pr-control-label_span]:leading-bodySans-large-semibold',
      // Form Group - Your Rating - Rating Stars
      '[&_#pr-war-form_input:focus+.pr-star-v4]:bg-components-product-card-color-star-rating',
      '[&_.p-w-r_.pr-star-v4]:bg-none',
      '[&_.p-w-r_.pr-star-v4]:w-[1.5rem]',
      '[&_.p-w-r_.pr-star-v4]:h-[1.5rem]',
      '[&_.p-w-r_.pr-star-v4.pr-star-v4-0-filled]:[mask-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Im0zNTQtMjQ3IDEyNi03NiAxMjYgNzctMzMtMTQ0IDExMS05Ni0xNDYtMTMtNTgtMTM2LTU4IDEzNS0xNDYgMTMgMTExIDk3LTMzIDE0M1pNMjMzLTgwbDY1LTI4MUw4MC01NTBsMjg4LTI1IDExMi0yNjUgMTEyIDI2NSAyODggMjUtMjE4IDE4OSA2NSAyODEtMjQ3LTE0OUwyMzMtODBabTI0Ny0zNTBaIi8+PC9zdmc+")]',
      '[&_.p-w-r_.pr-star-v4.pr-star-v4-0-filled]:bg-components-product-card-color-star-rating',
      '[&_.p-w-r_.pr-star-v4.pr-star-v4-0-filled:hover]:bg-components-product-card-color-star-rating',
      '[&_.p-w-r_.pr-star-v4.pr-star-v4-100-filled]:bg-components-product-card-color-star-rating',
      '[&_.p-w-r_.pr-star-v4.pr-star-v4-100-filled]:[mask-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGQ9Im0yMzMtODAgNjUtMjgxTDgwLTU1MGwyODgtMjUgMTEyLTI2NSAxMTIgMjY1IDI4OCAyNS0yMTggMTg5IDY1IDI4MS0yNDctMTQ5TDIzMy04MFoiLz48L3N2Zz4=")]',
      // Form Group - Review Headline
      '[&_#pr-war-form_.pr-form-group.pr-headline-form-group]:mb-[32px]',
      '[&_#pr-war-form_.pr-form-group.pr-headline-form-group_.form-group]:mb-0',
      // Form Group - Comments
      '[&_#pr-war-form_.pr-form-group.pr-comments-form-group]:mb-[32px]',
      '[&_#pr-war-form_.pr-form-group.pr-comments-form-group_.form-group]:mb-0',
      // Form Group - Bottom Line
      '[&_#pr-war-form_.pr-form-group.pr-bottomline-form-group]:mb-[32px]',
      '[&_#pr-war-form_.pr-form-group.pr-bottomline-form-group_.form-group]:mb-0',
      // Form Group - Bottom Line - Buttons
      '[&_#pr-war-form_.pr-form-group.pr-bottomline-form-group_.pr-btn.active]:bg-themes-background-color-brand1',
      '[&_#pr-war-form_.pr-form-group.pr-bottomline-form-group_.pr-btn.active]:border-components-text-field-border-default',
      '[&_#pr-war-form_.pr-form-group.pr-bottomline-form-group_.pr-btn]:border',
      '[&_#pr-war-form_.pr-form-group.pr-bottomline-form-group_.pr-btn.active]:hover:text-white',
      // Form Group - Nickname
      '[&_#pr-war-form_.pr-form-group.pr-name-form-group]:mb-[32px]',
      '[&_#pr-war-form_.pr-form-group.pr-name-form-group_.form-group]:mb-0',
      // Form Group - Location
      '[&_#pr-war-form_.pr-form-group.pr-location-form-group]:mb-[32px]',
      '[&_#pr-war-form_.pr-form-group.pr-location-form-group_.form-group]:mb-0',
      // Form Group - Email Collection
      '[&_#pr-war-form_.pr-form-group.pr-email\\_collection-form-group_.form-group]:mb-0',
      // Form Group - Last Form Group in Write A Review Form
      '[&_#pr-war-form_.pr-form-group:last-child]:mb-0',
      '[&_#pr-war-form_.pr-form-group:last-child_.form-group]:mb-0',
      // Form Group - Required Indicator Asterisk
      '[&_#pr-war-form_.pr-required-indicator]:text-color-system-alert-300',
      // Buttons
      // Buttons - Add Files - Image / Video
      '[&_.p-w-r_.pr-file-input-btn-group]:gap-spacing-margin-large-3',
      'md:[&_.p-w-r_.pr-file-input-btn-group]:gap-spacing-margin-large-5',
      '[&_.p-w-r_.pr-file-input-btn-group]:items-start',
      '[&_.p-w-r_button.pr-btn-fileinput_svg]:hidden',
      '[&_.p-w-r_button.pr-btn-fileinput]:min-h-[2.5rem]',
      '[&_.p-w-r_button.pr-btn-review]:min-h-12',
      '[&_.p-w-r_button.pr-btn-default]:text-bodySans-medium-semibold',
      '[&_.p-w-r_button.pr-btn-default]:font-bodySans-medium-semibold',
      '[&_.p-w-r_button.pr-btn-default]:leading-none',
      '[&_.p-w-r_button.pr-btn-fileinput]:bg-components-button-color-outline-brand-default-bg',
      '[&_.p-w-r_button.pr-btn-fileinput]:m-0',
      '[&_.p-w-r_button.pr-btn-fileinput]:border-components-button-color-outline-brand-default-stroke',
      '[&_.p-w-r_button.pr-btn-fileinput]:border-3',
      '[&_.p-w-r_button.pr-btn-fileinput]:border-solid',
      '[&_.p-w-r_button.pr-btn-fileinput]:fill-components-button-color-outline-brand-default',
      '[&_.p-w-r_button.pr-btn-fileinput]:py-components-button-spacing-compressed-default-padding-y',
      '[&_.p-w-r_button.pr-btn-fileinput]:px-components-button-spacing-compressed-default-padding-x',
      '[&_.p-w-r_button.pr-btn-fileinput]:rounded-themes-radius-small-button',
      '[&_.p-w-r_button.pr-btn-fileinput]:text-components-button-color-outline-brand-default-text',
      '[&_.p-w-r_button.pr-btn-fileinput]:[&>*]:gap-components-button-spacing-compressed-default-space-between',
      '[&_.p-w-r_button.pr-btn-fileinput:active]:bg-components-button-color-outline-brand-pressed-bg',
      '[&_.p-w-r_button.pr-btn-fileinput:active]:border-components-button-color-outline-brand-pressed-stroke',
      '[&_.p-w-r_button.pr-btn-fileinput:active]:text-components-button-color-outline-brand-pressed-text',
      '[&_.p-w-r_button.pr-btn-fileinput:disabled]:bg-transparent',
      '[&_.p-w-r_button.pr-btn-fileinput:disabled]:border-components-button-color-outline-brand-disabled-stroke/components-button-color-outline-brand-disabled-stroke',
      '[&_.p-w-r_button.pr-btn-fileinput:disabled]:text-components-button-color-outline-brand-disabled-text/components-button-color-outline-brand-disabled-text',
      '[&_.p-w-r_button.pr-btn-fileinput:focus]:bg-components-button-color-outline-brand-focus-bg',
      '[&_.p-w-r_button.pr-btn-fileinput:focus]:border-components-button-color-outline-brand-focus-stroke',
      '[&_.p-w-r_button.pr-btn-fileinput:focus]:shadow-focus',
      '[&_.p-w-r_button.pr-btn-fileinput:focus]:text-components-button-color-outline-brand-focus-text',
      '[&_.p-w-r_button.pr-btn-fileinput:hover]:bg-components-button-color-outline-brand-hover-bg',
      '[&_.p-w-r_button.pr-btn-fileinput:hover]:border-components-button-color-outline-brand-hover-stroke',
      '[&_.p-w-r_button.pr-btn-fileinput:hover]:text-components-button-color-outline-brand-hover-text',
      // Buttons - Submit
      '[&_.p-w-r_button.pr-btn-review]:bg-components-button-color-filled-brand-default-bg',
      '[&_.p-w-r_button.pr-btn-review]:border-components-button-color-filled-brand-default-stroke',
      '[&_.p-w-r_button.pr-btn-review]:![width:auto]',
      '[&_.p-w-r_button.pr-btn-review]:border-3',
      '[&_.p-w-r_button.pr-btn-review]:border-solid',
      '[&_.p-w-r_button.pr-btn-review]:fill-components-button-color-filled-brand-default',
      '[&_.p-w-r_button.pr-btn-review]:gap-components-button-spacing-large-default-space-between',
      '[&_.p-w-r_button.pr-btn-review]:py-components-button-spacing-large-default-padding-y',
      '[&_.p-w-r_button.pr-btn-review]:px-components-button-spacing-large-default-padding-x',
      '[&_.p-w-r_button.pr-btn-review]:rounded-themes-radius-large-button',
      '[&_.p-w-r_button.pr-btn-review]:text-components-button-color-filled-brand-default-text',
      '[&_.p-w-r_button.pr-btn-review]:[&>*]:gap-components-button-spacing-large-default-space-between',
      '[&_.p-w-r_button.pr-btn-review:active]:bg-components-button-color-filled-brand-pressed-bg',
      '[&_.p-w-r_button.pr-btn-review:active]:border-components-button-color-filled-brand-pressed-stroke',
      '[&_.p-w-r_button.pr-btn-review:disabled]:bg-components-button-color-filled-brand-disabled-bg/components-button-color-filled-brand-disabled-bg',
      '[&_.p-w-r_button.pr-btn-review:disabled]:border-transparent',
      '[&_.p-w-r_button.pr-btn-review:disabled]:text-components-button-color-filled-brand-disabled-text/components-button-color-filled-brand-disabled-text',
      '[&_.p-w-r_button.pr-btn-review:focus]:bg-components-button-color-filled-brand-focus-bg',
      '[&_.p-w-r_button.pr-btn-review:focus]:border-components-button-color-filled-brand-focus-stroke',
      '[&_.p-w-r_button.pr-btn-review:focus]:shadow-focus',
      '[&_.p-w-r_button.pr-btn-review:hover]:bg-components-button-color-filled-brand-hover-bg',
      '[&_.p-w-r_button.pr-btn-review:hover]:border-components-button-color-filled-brand-hover-stroke',
      '[&_.p-w-r_button.pr-btn-review:hover]:text-components-button-color-filled-brand-hover-text',
      // Footer
      // Footer - Wrapper
      '[&_.p-w-r_.pr-footer]:border-0',
      '[&_.p-w-r_.pr-footer]:flex',
      '[&_.p-w-r_.pr-footer]:flex-col',
      '[&_.p-w-r_.pr-footer]:gap-spacing-margin-large-4',
      '[&_.p-w-r_.pr-footer]:items-start',
      '[&_.p-w-r_.pr-footer]:p-0',
      // Footer - Disclaimer Text
      '[&_.p-w-r_.pr-footer_.pr-subscript]:m-0',
      // Footer - Power Reviews Logo
      '[&_.pr-footer_.pr-pull-right]:hidden',
    ],
  },
});
