// Global
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';

// Lib
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Video from 'helpers/Video/Video';
import { tailwindVariants } from './ArticleVideoTailwind';

export type ArticleVideoProps = ProductAndArticleComponent.Articles.ArticleInformation;

const ArticleVideo = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const articleInfo = sitecoreContext?.route?.fields?.articleInformation as ArticleVideoProps;
  const { video } = tailwindVariants();

  if (!articleInfo) return <></>;

  const articleVideo = articleInfo.fields?.articleVideo;
  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  return (
    <Container id={id ? id : undefined}>
      <Video
        class={video()}
        data-component="authorable/articlevideo"
        field={articleVideo}
        responsive
      />
    </Container>
  );
};

export default ArticleVideo;
