// Global
import React from 'react';
import { ImageField, LinkField, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import FlocklerEmbed from '@flockler/react-flockler-embed';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import SVG from 'helpers/SVG/SVG';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import { tailwindVariants } from './FlocklerTailwind';

export type FlocklerProps = ComponentProps & DartComponents.Flockler.Flockler;

type BackgroundColor = 'BrandColor' | undefined;

const Flockler = (props: FlocklerProps): JSX.Element => {
  const { backgroundColor = 'Default' } = props?.params || {};

  const { siteUuid, embedUuid, socialLinks } = props?.fields || {};

  const {
    base,
    contentWrapper,
    headlineText,
    headlineAlignment,
    iconContainer,
    socialIcon,
    componentBG,
  } = tailwindVariants({
    background: backgroundColor as BackgroundColor,
  });

  const id = props?.params?.RenderingIdentifier;
  const HeadlineText = props?.fields?.headline as TextField;
  const SiteUuidValue = siteUuid?.value as string;
  const EmbedUuidValue = embedUuid?.value as string;

  return (
    <div
      className={componentBG()}
      data-component="authorable/flockler"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container>
        <div className={base()}>
          <div className={contentWrapper()}>
            {props?.fields ? (
              <>
                <div className={headlineAlignment()}>
                  {/* Headline */}
                  {HeadlineText && (
                    <Text className={headlineText()} encode={false} field={HeadlineText} tag="h2" />
                  )}

                  {/* Social links */}
                  <div className={iconContainer()}>
                    {socialLinks?.length !== 0 &&
                      socialLinks?.map((socialLink, index) => {
                        const { socialIcon: socialIconImage, socialUrl } = socialLink?.fields || {};
                        const url = socialIconImage as ImageField;

                        return (
                          <>
                            {socialUrl && (
                              <LinkWrapper
                                className={socialIcon()}
                                field={socialUrl as LinkField}
                                key={index}
                                showLinkTextWithChildrenPresent={false}
                              >
                                <SVG url={url?.value?.src} />
                              </LinkWrapper>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>

                {/* Flockler */}
                {SiteUuidValue && EmbedUuidValue && (
                  <FlocklerEmbed siteUuid={SiteUuidValue} embedUuid={EmbedUuidValue} />
                )}
              </>
            ) : null}
            {!props?.fields ? (
              <>
                <Text
                  className={headlineText()}
                  encode={false}
                  field={props?.fields?.headline as TextField}
                  tag="h2"
                />
              </>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Flockler;
