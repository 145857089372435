const SDSListingItemQuery = `query($routePath: String!, $language: String!, $site: String!){
    layout(language: $language, routePath: $routePath, site: $site) {
        item {
          template{
            id
          }
          ancestors(hasLayout:true,includeTemplateIDs:"de293f96-ac18-4eab-9d18-75c40703b1cd"){
            name
          }
        }
      }
  }`;
export default SDSListingItemQuery;
