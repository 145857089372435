// Global
import { tv } from 'tailwind-variants';

// Tailwind
export const tailwindVariants = tv({
  slots: {
    base: ['component', 'jumplinks-main', 'z-[99]'],
    jumpLinksWrapper: [
      'jumplinks-wrapper',
      'py-spacing-padding-small-3',
      'px-themes-general-surface-spacing-small-padding-x',
      'bg-components-jumplink-color-bg-default',
      'flex',
      'flex-col',
      'items-center',
      'text-center',
      'md:py-spacing-padding-large-3',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
    jumpLinkItems: [
      'jumplinks-items',
      'flex',
      'flex-row',
      'flex-wrap',
      'justify-center',
      'gap-spacing-spacing-4',
      'md:gap-spacing-spacing-5',
      'px-components-jumplink-spacing-small-link-container-padding-x',
      'py-spacing-padding-large-1',
      'md:px-spacing-padding-large-3',
      'md:py-spacing-padding-large-1',
    ],
    jumpLinksTopWrapper: ['pt-spacing-spacing-4'],
    jumpLinksTitle: [
      'font-header-medium-medium',
      'text-header-medium-medium',
      'leading-header-medium-medium',
      'md:font-header-large-medium',
      'md:text-header-large-medium',
      'md:leading-header-large-medium',
      'text-components-jumplink-color-title-text',
      'mb-themes-general-surface-spacing-small-title-margin-bottom',
      'md:mb-spacing-spacing-5',
    ],
    jumpLinksDescription: [
      'font-bodySans-small',
      'text-bodySans-small',
      'leading-bodySans-small',
      'md:font-bodySans-medium',
      'md:text-bodySans-medium',
      'md:leading-bodySans-medium',
      'text-components-jumplink-color-description-text',
      'mb-components-jumplink-spacing-small-title-area-margin-bottom',
      'md:mb-components-jumplink-spacing-large-title-area-margin-bottom',
    ],
    jumpLinksLabel: [
      'font-bodySans-medium-semibold',
      'text-bodySans-medium-semibold',
      'leading-bodySans-medium-semibold',
      'text-components-jumplink-color-link-text-default',
      'md:hover:underline',
      'md:hover:decoration-2',
      'md:hover:underline-offset-8',
      'md:hover:text-components-jumplink-color-link-text-active',
      'md:hover:decoration-components-jumplink-color-link-border-active',
      'md:active:text-components-jumplink-color-link-text-active',
      'md:active:decoration-components-jumplink-color-link-border-active',
      'group-focus:underline',
      'group-focus:decoration-2',
      'group-focus:underline-offset-8',
      'group-focus:text-components-jumplink-color-link-text-active',
      'group-focus:decoration-components-jumplink-color-link-border-active',
      'group-active:underline',
      'group-active:decoration-2',
      'group-active:underline-offset-8',
      'group-active:text-components-jumplink-color-link-text-active',
      'group-active:decoration-components-jumplink-color-link-border-active',
      'group-focus-visible:text-components-jumplink-color-link-text-active',
      'group-focus-visible:underline',
      'group-focus-visible:decoration-2',
      'group-focus-visible:underline-offset-8',
      'group-focus-visible:decoration-components-jumplink-color-link-border-active',
    ],
    jumpLinksLabelLinkWrapper: ['group', 'focus-visible:outline-none'],
    jumpLinksBottomWrapper: [],
  },
  variants: {
    isSticky: {
      true: {
        base: ['sticky', 'w-full', 'top-0'],
      },
      false: {
        base: [],
      },
    },
    isActive: {
      true: {
        jumpLinksLabel: [
          'underline',
          'underline-offset-2',
          '!text-components-jumplink-color-link-text-active',
        ],
      },
      false: {
        jumpLinksLabel: [],
      },
    },
  },
});
