// Global
import { tv } from 'tailwind-variants';
import { ALL_THEMES } from 'lib/context/ThemeContext';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export const tailwindVariants = tv({
  compoundSlots: [
    {
      slots: [
        'galleryImage',
        'galleryImageMiddleCol',
        'galleryImageWrapper',
        'galleryImageWrapperMiddleCol',
      ],
      class: [
        'rounded-themes-spacing-radius-small-image',
        'md:rounded-themes-spacing-radius-large-image',
      ],
    },
  ],
  slots: {
    base: [
      'bg-components-media-gallery-color-bg',
      'py-components-media-gallery-spacing-small-padding-y',
      'px-components-media-gallery-spacing-small-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
    description: [],
    galleryColumn: ['flex'],
    galleryImage: ['object-cover', 'w-full'],
    galleryImageMiddleCol: ['aspect-square', 'object-cover', 'w-full'],
    galleryImageWrapper: [],
    galleryImageWrapperMiddleCol: ['basis-full'],
    galleryWrapper: ['flex'],
    lightbox: [
      'bg-components-lightbox-color-overlay-bg',
      'max-md:gap-spacing-spacing-6',
      'fixed',
      'h-full',
      'inset-0',
      'w-full',
      'z-[100]',
      'flex',
      'flex-col',
      'overflow-scroll',
      'md:pb-[40px]',
    ],
    lightboxButton: [
      'max-md:py-[0.7rem]',
      'max-md:px-[0.7rem]',
      'max-md:h-[40px]',
      'max-md:w-[40px]',
      'max-md:min-h-[40px]',
      'md:w-[40px]',
    ],
    lightboxButtonWrapper: ['self-end', 'pt-4', 'pr-4', 'md:pt-[40px]', 'md:pr-[40px]'],
    mediaGalleryCarouselWrapper: [
      'flex',
      'flex-col',
      'justify-center',
      'md:justify-start',
      'align-center',
      'gap-components-lightbox-spacing-small-media-details-margin-bottom',
      'md:gap-components-lightbox-spacing-large-media-details-margin-bottom',
      'px-[24px]',
      'md:px-0',
    ],
    pattern: [],
    primaryCTAWrapper: ['md:self-center'],
    topDescription: [
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-bodySans-small',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'md:text-bodySans-medium',
      'text-components-media-gallery-color-body',
    ],
    topTitle: [
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'text-components-media-gallery-color-title',
    ],
    topTitleContentWrapper: [
      'flex',
      'flex-col',
      'md:flex-row',
      'justify-between',
      'gap-themes-general-surface-spacing-small-body-margin-bottom',
      'md:gap-themes-general-surface-spacing-large-body-margin-right',
    ],
    topTitleTextWrapper: [
      'flex',
      'flex-col',
      'gap-themes-general-surface-spacing-small-title-margin-bottom',
      'md:gap-themes-general-surface-spacing-large-title-margin-bottom',
    ],
    topTitleWrapper: [
      'flex',
      'flex-col',
      'gap-themes-general-surface-spacing-small-body-margin-bottom',
      'md:gap-themes-general-surface-spacing-large-body-margin-bottom',
      'pb-themes-general-surface-spacing-small-margin-bottom',
      'md:pb-themes-general-surface-spacing-large-margin-bottom',
    ],
    svgClassLarge: ['block'],
    svgClassExtraLarge: ['hidden'],
  },
  variants: {
    brand: {
      ...themeVariants,
      // For Corporate - At 150% device specification, we had to reduce the font size so added font size in "topTitle".
      Corporate: {
        svgClassLarge: ['lg:hidden'],
        svgClassExtraLarge: ['lg:block'],
        topTitle: [
          'md:!font-header-large-medium',
          'md:!leading-header-large-medium',
          'md:!text-header-large-medium',
        ],
      },
    },
    orientation: {
      Default: {
        galleryColumn: [
          'basis-full',
          'gap-x-components-media-gallery-small-container-grid-gap-x',
          'md:gap-x-components-media-gallery-large-container-grid-gap-x',
        ],
        galleryImage: ['aspect-square'],
        galleryImageWrapper: ['basis-4/12'],
        galleryWrapper: [
          'flex-wrap',
          'gap-y-components-media-gallery-small-container-grid-gap-y',
          'md:gap-y-components-media-gallery-large-container-grid-gap-y',
        ],
      },
      MasonryHorizontal: {
        galleryColumn: [
          'basis-full',
          'gap-x-components-media-gallery-small-container-grid-gap-x',
          'md:gap-x-components-media-gallery-large-container-grid-gap-x',
        ],
        galleryImage: ['aspect-[4/3]'],
        galleryImageWrapper: ['basis-full'],
        galleryWrapper: [
          'flex-row',
          'flex-wrap',
          'gap-y-components-media-gallery-small-container-grid-gap-y',
          'md:gap-y-components-media-gallery-large-container-grid-gap-y',
        ],
      },
      MasonryVertical: {
        galleryColumn: [
          'content-start',
          'flex-1',
          'flex-wrap',
          'gap-y-components-media-gallery-small-container-grid-gap-y',
          'md:gap-y-components-media-gallery-large-container-grid-gap-y',
        ],
        galleryImage: ['aspect-[3/4]'],
        galleryImageWrapper: ['basis-full'],
        galleryWrapper: [
          'flex-row',
          'gap-x-components-media-gallery-small-container-grid-gap-x',
          'md:gap-x-components-media-gallery-large-container-grid-gap-x',
        ],
      },
    },
  },
});
