// Global
import { ALL_THEMES } from 'lib/context/ThemeContext';
import { tv } from 'tailwind-variants';

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

//Tailwind
export const tailwindVariants = tv({
  defaultVariants: {
    grid: undefined,
  },
  slots: {
    base: ['flex'],
    backgroundImageStats: ['absolute', 'left-0', 'top-0', 'hidden', 'md:block'],
    statsInnerWrapper: [
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      '!bg-components-stats-bg-inner',
      'rounded-themes-spacing-radius-small-general',
      'md:rounded-themes-spacing-radius-large-general',
      'flex',
      'flex-col',
      'items-center',
      'relative',
      'z-10',
    ],
    statsTitle: [
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-stats-title',
      'text-center',
    ],
    statsDescription: [
      'md:text-bodySans-medium',
      'md:font-bodySans-medium',
      'md:leading-bodySans-medium',
      'text-bodySans-small',
      'font-bodySans-small',
      'leading-bodySans-small',
      'text-center',
      'text-components-stats-copy',
    ],
    statsTopWrapper: [
      'mt-spacing-spacing-2',
      'md:mt-0',
      'md:gap-themes-general-surface-spacing-large-title-margin-bottom',
      'gap-themes-general-surface-spacing-small-title-margin-bottom',
      'flex',
      'flex-col',
      'items-center',
    ],
    statsBottomWrapper: [
      'w-full',
      'mt-themes-general-surface-spacing-small-margin-bottom',
      'md:mt-themes-general-surface-spacing-large-margin-bottom',
    ],
    stateItem: ['flex', 'items-center', 'flex-1', 'gap-spacing-spacing-2', 'justify-between'],
    stateWrapper: [
      'stats-wrapper',
      'flex',
      'flex-wrap',
      'flex-col',
      'gap-spacing-spacing-5',
      'md:gap-spacing-spacing-6',
      'text-center',
      'justify-center',
      'md:items-start',
    ],
    stateCallOut: [
      'text-header-medium-xxSmall',
      'font-header-medium-xxSmall',
      'leading-header-medium-xxSmall',
      'p-spacing-spacing-2',
      'text-components-stats-supporting-text',
    ],
    stateLabel: [
      'md:text-header-large-xLarge',
      'md:font-header-large-xLarge',
      'md:leading-header-large-xLarge',
      'text-header-large-large',
      'font-header-large-large',
      'leading-header-large-large',
      'p-spacing-spacing-2',
      'text-components-stats-stat-text',
    ],
  },
  variants: {
    theme: {
      Dark: {
        base: [
          'bg-components-stats-bg-section-inner',
          'md:py-themes-general-surface-spacing-large-padding-y',
          'md:px-themes-general-surface-spacing-large-padding-x',
          'py-themes-general-surface-spacing-small-padding-y',
          'px-themes-general-surface-spacing-small-padding-x',
          'overflow-hidden',
        ],
      },
      Light: {
        base: ['bg-components-stats-bg-default'],
      },
    },
    invertStyling: {
      true: {
        stateItem: ['flex-col-reverse'],
        stateLabel: ['block'],
      },
      false: {
        stateCallOut: ['block'],
        stateItem: ['flex-col'],
      },
    },
    grid: {
      '2column': {
        stateWrapper: ['md:grid', 'md:grid-cols-2'],
      },
      '3column': {
        stateWrapper: ['md:grid', 'md:grid-cols-3'],
      },
      '4column': {
        stateWrapper: ['md:grid', 'md:grid-cols-4'],
      },
      undefined: {
        stateWrapper: ['md:flex-row'],
      },
    },
    brand: {
      ...themeVariants,
      Glade: { backgroundImageStats: ['!w-full', '!h-full'] },
    },
  },
});
