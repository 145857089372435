// Global
import { LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useRef, useState } from 'react';

// Lib
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Video from 'helpers/Video/Video';
import LegalDisclaimer from 'helpers/LegalDisclaimer/LegalDisclaimer';
import fallback from 'lib/fallback/fallback';
import { useTheme } from 'lib/context/ThemeContext';
import { tailwindVariants } from './DartPromoTailwind';
import GoogleMaterialSymbol from 'helpers/GoogleMaterialSymbol/GoogleMaterialSymbol';

export type PromoProps = ComponentProps & DartComponents.Promo.Promo;
export type PromoCTA = DartComponents.Promo.PromoCta;

type AlignmentType = 'Center' | 'Left' | 'Right';

type BgColorType = 'Light' | 'BrandColor' | 'Default' | undefined;

type Media = 'video' | 'image';

// Add fallback component variant color
const fallbackComponentVariantColor = fallback?.componentVariants?.value;
const fallbackComponentVariantType = fallback?.componentVariants?.type;

const DartPromo = (props: PromoProps): JSX.Element => {
  const {
    description,
    headline,
    image,
    label,
    primaryCTA,
    secondaryCTA,
    video,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
    secondaryCTAColor,
    secondaryCTAType,
  } = props?.fields || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { alignContent, backgroundColor, RenderingIdentifier } = props?.params || {};
  const { themeName } = useTheme();

  if (!props.fields) return <>Promo Component</>;

  const id = RenderingIdentifier;
  const labelLink = label?.fields?.link as LinkField;
  const media = video?.value ? 'video' : 'image';

  const {
    base,
    componentBG,
    containerBrandSpecific,
    contentBlock,
    contentContainer,
    ctaContainer,
    descriptionContainer,
    descriptionContainerText,
    headlineContainer,
    labelContainer,
    mediaContainer,
    wrapper,
    disclaimerContent,
  } = tailwindVariants({
    alignment: alignContent as AlignmentType,
    background: backgroundColor as BgColorType,
    media: media as Media,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  return (
    <div
      className={componentBG()}
      data-component="authorable/dartpromo"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container className={containerBrandSpecific()}>
        <div className={base()}>
          <div className={wrapper()}>
            <div className={contentContainer()}>
              {labelLink?.value?.href && (
                <Label
                  className={labelContainer()}
                  color={backgroundColor === 'BrandColor' ? 'color' : 'white'}
                  backgroundColor={
                    tailwindVariants.variants.background[
                      backgroundColor as keyof typeof tailwindVariants.variants.background
                    ].base
                  }
                  link={label?.fields?.link as LinkField}
                  gtmEvent={{
                    event: 'link',
                    type: 'label',
                    'gtm.element.dataset.gtmDatasourceId': labelLink.value.id as string,
                    'gtm.element.dataset.gtmComponentName': labelLink.value.text,
                  }}
                />
              )}

              <div className={contentBlock()}>
                {headline?.value.length && (
                  <div className={headlineContainer()}>
                    <Text encode={false} field={headline} tag="h2" />
                  </div>
                )}
                {description?.value.length && (
                  <div className={(descriptionContainer(), descriptionContainerText())}>
                    <RichTextA11yWrapper field={description} />
                  </div>
                )}
              </div>
            </div>
            {(primaryCTA?.value?.text || secondaryCTA?.value?.text) && (
              <div className={ctaContainer()}>
                {primaryCTA?.value?.text?.length && (
                  <Button
                    //As the design requires a filled CTA, we do not need to add a fallback since
                    //the button component has a default variant that displays a filled CTA.
                    type={primaryCTAType?.value || 'filled'}
                    color={backgroundColor === 'BrandColor' ? 'white' : primaryCTAColor?.value}
                    label={primaryCTA?.value?.text}
                    title={primaryCTA?.value?.title}
                    tag="a"
                    target={primaryCTA?.value?.target}
                    href={primaryCTA?.value?.href}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'primary',
                      'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': dataSource,
                      'gtm.element.dataset.gtmComponentName': componentName,
                    }}
                  />
                )}
                {secondaryCTA?.value?.text?.length && (
                  <Button
                    //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                    //so if there is no value in sitecore field, it will take the outline value.
                    type={secondaryCTAType?.value || fallbackComponentVariantType}
                    color={
                      backgroundColor === 'BrandColor'
                        ? 'white'
                        : secondaryCTAColor?.value || fallbackComponentVariantColor
                    }
                    label={secondaryCTA?.value?.text}
                    title={secondaryCTA?.value?.title}
                    tag="a"
                    target={secondaryCTA?.value?.target}
                    iconRight={
                      themeName === 'Corporate' || themeName === 'OffDefense' ? 'chevron_right' : ''
                    }
                    href={secondaryCTA?.value?.href}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'secondary',
                      'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': dataSource,
                      'gtm.element.dataset.gtmComponentName': componentName,
                    }}
                  />
                )}
              </div>
            )}
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerClasses={disclaimerContent()}
                disclaimerText={disclaimerText}
              />
            )}
          </div>

          <div className={mediaContainer()}>
            {image?.value?.src?.length && !video?.value?.length && (
              <ImageWrapper field={image} layout="responsive" />
            )}
            {video?.value?.length ? (
              <Video
                responsive
                class="h-full [&_iframe]:rounded-themes-radius-large-video"
                field={video}
                gtmEvent={{
                  event: 'media',
                  type: 'video',
                  'gtm.element.dataset.gtmLinkName': video?.value,
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export const WithDropdown = (props: PromoProps): JSX.Element => {
  const {
    description,
    headline,
    image,
    label,
    video,
    disclaimerText,
    dropdownCTAList,
    dropdownLabel,
    dropdownCTALabel,
    dropdownCTAColor,
    dropdownCTAType,
  } = props?.fields || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { alignContent, backgroundColor, RenderingIdentifier } = props?.params || {};
  const { themeName } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{
    text: string | null;
    link: string | null;
    target: string | null;
  }>({
    text: null,
    link: null,
    target: null,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  // handle dropdown outside click event
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // handle option selection functionality
  const handleOptionClick = (
    href: string | undefined,
    text: string | undefined,
    target: string | undefined
  ) => {
    setSelectedOption({
      text: text ?? '',
      link: href ?? '',
      target: target ?? '',
    });
    setIsOpen(false); // Close the dropdown
  };

  if (!props.fields) return <>WithDropdown Variant Component</>;

  const id = RenderingIdentifier;
  const labelLink = label?.fields?.link as LinkField;
  const media = video?.value ? 'video' : 'image';

  const {
    base,
    componentBG,
    containerBrandSpecific,
    contentBlock,
    contentContainer,
    dropdownContainer,
    dropdownContainerDropdown,
    dropdownIcon,
    dropdownMenuContainer,
    dropdownMenuItem,
    descriptionContainer,
    descriptionContainerText,
    headlineContainer,
    labelContainer,
    mediaContainer,
    wrapper,
    disclaimerContent,
  } = tailwindVariants({
    alignment: alignContent as AlignmentType,
    background: backgroundColor as BgColorType,
    media: media as Media,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  return (
    <div
      className={componentBG()}
      data-component="authorable/dartpromo"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container className={containerBrandSpecific()}>
        <div className={base()}>
          <div className={wrapper()}>
            <div className={contentContainer()}>
              {labelLink?.value?.href && (
                <Label
                  className={labelContainer()}
                  color={backgroundColor === 'BrandColor' ? 'color' : 'white'}
                  backgroundColor={
                    tailwindVariants.variants.background[
                      backgroundColor as keyof typeof tailwindVariants.variants.background
                    ].base
                  }
                  link={label?.fields?.link as LinkField}
                  gtmEvent={{
                    event: 'link',
                    type: 'label',
                    'gtm.element.dataset.gtmDatasourceId': labelLink.value.id as string,
                    'gtm.element.dataset.gtmComponentName': labelLink.value.text,
                  }}
                />
              )}

              <div className={contentBlock()}>
                {headline?.value.length && (
                  <div className={headlineContainer()}>
                    <Text encode={false} field={headline} tag="h2" />
                  </div>
                )}
                {description?.value.length && (
                  <div className={(descriptionContainer(), descriptionContainerText())}>
                    <RichTextA11yWrapper field={description} />
                  </div>
                )}
              </div>
            </div>

            {dropdownCTAList != null && dropdownCTAList?.length > 0 ? (
              <>
                <div className={dropdownContainer()} ref={dropdownRef}>
                  <button
                    type="button"
                    id="dropdown-button"
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                    aria-controls="dropdown-menu"
                    className={dropdownContainerDropdown()}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span>{selectedOption.text || dropdownLabel?.value}</span>
                    <GoogleMaterialSymbol
                      icon={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                      className={dropdownIcon()}
                    />
                  </button>
                  {isOpen && (
                    <ul
                      id="dropdown-menu"
                      role="listbox"
                      className={dropdownMenuContainer()}
                      tabIndex={-1}
                    >
                      {dropdownCTAList?.length &&
                        dropdownCTAList?.map((option: PromoCTA, index) => (
                          <li
                            id={`option-${index}`}
                            role="option"
                            key={option?.fields?.ctaLink?.value?.href}
                            data-target={option?.fields?.ctaLink?.value?.target}
                            className={dropdownMenuItem()}
                            onClick={() =>
                              handleOptionClick(
                                option?.fields?.ctaLink?.value?.href,
                                option?.fields?.ctaLink?.value?.text,
                                option?.fields?.ctaLink?.value?.target
                              )
                            }
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                handleOptionClick(
                                  option?.fields?.ctaLink?.value?.href,
                                  option?.fields?.ctaLink?.value?.text,
                                  option?.fields?.ctaLink?.value?.target
                                );
                              }
                            }}
                            tabIndex={isOpen ? 0 : -1}
                          >
                            {option?.fields?.ctaLink?.value?.text}
                          </li>
                        ))}
                    </ul>
                  )}
                </div>
                {dropdownCTALabel?.value && (
                  <Button
                    color={dropdownCTAColor?.value || fallbackComponentVariantColor}
                    type={dropdownCTAType?.value || fallbackComponentVariantType}
                    tag="a"
                    label={dropdownCTALabel?.value}
                    href={selectedOption?.link as string}
                    target={selectedOption?.target as string}
                  />
                )}
              </>
            ) : null}

            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerClasses={disclaimerContent()}
                disclaimerText={disclaimerText}
              />
            )}
          </div>

          <div className={mediaContainer()}>
            {image?.value?.src?.length && !video?.value?.length && (
              <ImageWrapper field={image} layout="responsive" />
            )}
            {video?.value?.length ? (
              <Video
                responsive
                class="h-full [&_iframe]:rounded-themes-radius-large-video"
                field={video}
                gtmEvent={{
                  event: 'media',
                  type: 'video',
                  'gtm.element.dataset.gtmLinkName': video?.value,
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export const Alternate = (props: PromoProps): JSX.Element => {
  const {
    description,
    headline,
    image,
    label,
    primaryCTA,
    secondaryCTA,
    video,
    disclaimerText,
    primaryCTAColor,
    primaryCTAType,
    secondaryCTAColor,
    secondaryCTAType,
  } = props?.fields || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { alignContent, backgroundColor, RenderingIdentifier } = props?.params || {};
  const { themeName } = useTheme();

  if (!props.fields) return <>Promo Component</>;

  const id = RenderingIdentifier;
  const labelLink = label?.fields?.link as LinkField;
  const media = video?.value ? 'video' : 'image';

  const {
    altComponentBG,
    altcontainerBrandSpecific,
    altBase,
    altMediaContainer,
    altWrapper,
    contentBlock,
    contentContainer,
    ctaContainer,
    descriptionContainer,
    descriptionContainerText,
    headlineContainer,
    labelContainer,
    disclaimerContent,
  } = tailwindVariants({
    alignment: alignContent as AlignmentType,
    background: backgroundColor as BgColorType,
    media: media as Media,
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  return (
    <div
      className={altComponentBG()}
      data-component="authorable/dartpromo"
      id={id ? id : undefined}
      tabIndex={id ? -1 : 1}
    >
      <Container className={altcontainerBrandSpecific()}>
        <div className={altBase()}>
          <div className={altWrapper()}>
            <div className={contentContainer()}>
              {labelLink?.value?.href && (
                <Label
                  className={labelContainer()}
                  color={backgroundColor === 'BrandColor' ? 'color' : 'white'}
                  backgroundColor={
                    tailwindVariants.variants.background[
                      backgroundColor as keyof typeof tailwindVariants.variants.background
                    ].base
                  }
                  link={label?.fields?.link as LinkField}
                  gtmEvent={{
                    event: 'link',
                    type: 'label',
                    'gtm.element.dataset.gtmDatasourceId': labelLink.value.id as string,
                    'gtm.element.dataset.gtmComponentName': labelLink.value.text,
                  }}
                />
              )}

              <div className={contentBlock()}>
                {headline?.value.length && (
                  <div className={headlineContainer()}>
                    <Text encode={false} field={headline} tag="h2" />
                  </div>
                )}
                {description?.value.length && (
                  <div className={(descriptionContainer(), descriptionContainerText())}>
                    <RichTextA11yWrapper field={description} />
                  </div>
                )}
              </div>
            </div>
            {(primaryCTA?.value?.text || secondaryCTA?.value?.text) && (
              <div className={ctaContainer()}>
                {primaryCTA?.value?.text?.length && (
                  <Button
                    //As the design requires a filled CTA, we do not need to add a fallback since
                    //the button component has a default variant that displays a filled CTA.
                    type={primaryCTAType?.value || 'filled'}
                    color={backgroundColor === 'BrandColor' ? 'white' : primaryCTAColor?.value}
                    label={primaryCTA?.value?.text}
                    title={primaryCTA?.value?.title}
                    tag="a"
                    target={primaryCTA?.value?.target}
                    href={primaryCTA?.value?.href}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'primary',
                      'gtm.element.dataset.gtmLinkUrl': primaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': primaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': dataSource,
                      'gtm.element.dataset.gtmComponentName': componentName,
                    }}
                  />
                )}
                {secondaryCTA?.value?.text?.length && (
                  <Button
                    //The design requires an outline CTA, so for that we have added a fallback as an outline value,
                    //so if there is no value in sitecore field, it will take the outline value.
                    type={secondaryCTAType?.value || fallbackComponentVariantType}
                    color={
                      backgroundColor === 'BrandColor'
                        ? 'white'
                        : secondaryCTAColor?.value || fallbackComponentVariantColor
                    }
                    label={secondaryCTA?.value?.text}
                    title={secondaryCTA?.value?.title}
                    tag="a"
                    target={secondaryCTA?.value?.target}
                    iconRight={
                      themeName === 'Corporate' || themeName === 'OffDefense' ? 'chevron_right' : ''
                    }
                    href={secondaryCTA?.value?.href}
                    gtmEvent={{
                      event: 'cta_click',
                      type: 'secondary',
                      'gtm.element.dataset.gtmLinkUrl': secondaryCTA?.value?.href,
                      'gtm.element.dataset.gtmLinkName': secondaryCTA?.value?.text,
                      'gtm.element.dataset.gtmDatasourceId': dataSource,
                      'gtm.element.dataset.gtmComponentName': componentName,
                    }}
                  />
                )}
              </div>
            )}
            {disclaimerText?.value != '' && (
              <LegalDisclaimer
                disclaimerClasses={disclaimerContent()}
                disclaimerText={disclaimerText}
              />
            )}
          </div>

          <div className={altMediaContainer()}>
            {image?.value?.src?.length && !video?.value?.length && (
              <ImageWrapper field={image} layout="responsive" />
            )}
            {video?.value?.length ? (
              <Video
                responsive
                class="h-full [&_iframe]:rounded-themes-radius-large-video"
                field={video}
                gtmEvent={{
                  event: 'media',
                  type: 'video',
                  'gtm.element.dataset.gtmLinkName': video?.value,
                  'gtm.element.dataset.gtmDatasourceId': dataSource,
                  'gtm.element.dataset.gtmComponentName': componentName,
                }}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DartPromo;
