// Global
import { Splide } from '@splidejs/react-splide';
import React from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { ComponentProps } from 'lib/component-props';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';
import { CompositeComponents } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { ALL_THEMES, useTheme } from 'lib/context/ThemeContext';

// Local
import Carousel from 'components/authorable/Carousel/Carousel';
import PullQuoteItem from 'components/authorable/PullQuote/PullQuoteItem';
import MultiColorBorder from 'helpers/MultiColorBorder/MultiColorBorder';
import { ComponentVariants } from 'lib/context/ComponentVariants';
import { ColorTypes } from 'helpers/Label/Label';
import fallback from 'lib/fallback/fallback';

export type PullQuote = ItemEx &
  CompositeComponents.PullQuote.PullQuoteSlide &
  Common.Enums.dartEnum;

const themeVariants = ALL_THEMES.reduce(
  (acc, curr) => ((acc[curr] = {}), acc),
  {} as Record<string, object>
);

export type PullQuoteProps = ComponentProps & CompositeComponents.PullQuote.PullQuoteSlideList;

const tailwindVariants = tv({
  slots: {
    base: ['component', 'pullQuote', 'relative'],
    container: ['mx-auto', 'max-w-screen-xl'],
    contentCarousel: [
      'flex-1',
      'flex-col',
      'flex',
      'max-lg:px-0',
      'max-lg:py-0',
      'order-2',
      'lg:order-1',
    ],
    contentCarouselTrack: [
      'mb-components-pull-quote-spacing-small-quote-margin-bottom',
      'md:mb-components-pull-quote-spacing-large-quote-margin-bottom',
    ],
    controlContainerClasses: [
      'flex',
      'gap-6',
      'lg:px-components-hero-homepage-spacing-large-padding-x',
      'justify-end',
      'items-center',
    ],
    iconNext: ['chevron_right'],
    iconPrev: ['chevron_left'],
    playPauseFill: ['fill-components-button-color-outline-white-default-icon'],
    pullQuoteItemWrapper: ['flex', 'flex-row', 'justify-center', 'md:w-auto', 'h-full'],
    pullQuoteSingleItem: [
      'flex',
      'flex-row',
      'justify-center',
      'w-auto',
      'md:max-w-[770px]',
      'm-auto',
    ],
    pullQuoteWrapper: [
      'bg-components-pull-quote-color-bg',
      'py-themes-general-surface-spacing-small-padding-y',
      'px-themes-general-surface-spacing-small-padding-x',
      'md:py-themes-general-surface-spacing-large-padding-y',
      'md:px-themes-general-surface-spacing-large-padding-x',
    ],
  },
  variants: {
    brand: {
      ...themeVariants,
      Off: {
        pullQuoteWrapper: [
          'md:bg-auto',
          'md:bg-no-repeat',
          'md:bg-[url("/assets/Pull-Quote/decor,Brand=Off.svg")]',
          'md:bg-[left_85px]',
        ],
      },
      Autan: {
        pullQuoteWrapper: [
          'md:bg-auto',
          'md:bg-no-repeat',
          'md:bg-[url("/assets/Pull-Quote/decor,Brand=Autan.svg")]',
          'md:bg-[left_85px]',
        ],
      },
      OffDefense: {
        pullQuoteWrapper: [
          'md:bg-auto',
          'md:bg-no-repeat',
          'md:bg-[url("/assets/Pull-Quote/decor,Brand=OffDefense.svg")]',
          'md:bg-[left_85px]',
        ],
      },
      Raid: {
        pullQuoteWrapper: [
          'md:bg-auto',
          'md:bg-no-repeat',
          'md:h-full',
          'bg-[url("/assets/Pull-Quote/decor,Brand=Raid.svg")]',
        ],
      },
      Duck: {
        pullQuoteWrapper: [
          'md:bg-auto',
          'md:bg-no-repeat',
          'md:h-full',
          'bg-[url("/assets/Pull-Quote/decor,Brand=Duck.svg")]',
        ],
      },
    },
  },
});

const PullQuoteDefaultComponent = (props: PullQuoteProps): JSX.Element => (
  <div className={props.params.styles}>
    <div>
      <span className="is-empty-hint">PullQuote</span>
    </div>
  </div>
);

export const PullQuote = (props: PullQuoteProps): JSX.Element => {
  const { multiColorBar, pullQuoteSlides } = props?.fields || {};
  const { componentName, dataSource } = props?.rendering || {};
  const { autoPlay } = props?.params || {};
  const contentRef = React.createRef<Splide>();
  const { themeName } = useTheme();

  if (!props.fields) return <PullQuoteDefaultComponent {...props} />;

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;
  const componentVariants = ComponentVariants();

  // Add fallback component variant color
  const fallbackComponentVariantColor = fallback?.componentVariants?.value;

  const {
    contentCarousel,
    contentCarouselTrack,
    base,
    pullQuoteWrapper,
    container,
    controlContainerClasses,
    iconPrev,
    iconNext,
    playPauseFill,
    pullQuoteItemWrapper,
    pullQuoteSingleItem,
  } = tailwindVariants({
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    brand: themeName as string,
  });

  return (
    <div className={base()} data-component="authorable/pullquote" id={id} tabIndex={id ? -1 : 1}>
      {multiColorBar?.value && (
        <MultiColorBorder
          multipleBar="top"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}

      <div className={pullQuoteWrapper()}>
        <div className={container()}>
          {pullQuoteSlides?.length && pullQuoteSlides?.length > 1 ? (
            <Carousel
              autoPlay={autoPlay}
              hasTrack={false}
              gtmEvent={{
                'gtm.element.dataset.gtmDatasourceId': dataSource,
                'gtm.element.dataset.gtmComponentName': componentName,
              }}
              ref={contentRef}
              carouselClasses={contentCarousel()}
              trackClasses={contentCarouselTrack()}
              options={{
                type: 'slide',
                autoplay: true,
                drag: true,
                isNavigation: false,
                rewind: false,
                interval: 3600,
                focus: 'center',
                perPage: 1,
                trimSpace: false,
                gap: 10,
                padding: { left: 0, right: 30 },
                mediaQuery: 'min',
                breakpoints: {
                  991: {
                    fixedWidth: '770px',
                    gap: 30,
                    padding: '20%',
                  },
                  1890: {
                    fixedWidth: '770px',
                    gap: 30,
                    padding: '20%',
                  },
                },
              }}
              carouselControls={{
                controlContainerClasses: controlContainerClasses(),
                buttons: {
                  prev: {
                    icon: iconPrev(),
                  },
                  next: {
                    icon: iconNext(),
                  },
                  playPause: playPauseFill(),
                },
                navigationButtonVariant:
                  (componentVariants?.pullQuote?.carousel?.navigation as 'white' | 'brand') ??
                  fallbackComponentVariantColor,
                paginationVariant:
                  (componentVariants?.pullQuote?.carousel?.pagination as ColorTypes) ??
                  fallbackComponentVariantColor,
              }}
            >
              {pullQuoteSlides?.map((pullQuoteSlide: PullQuote, index) => {
                return (
                  <div key={index} className={pullQuoteItemWrapper()}>
                    <PullQuoteItem data={pullQuoteSlide} renderingParams={props} key={index} />
                  </div>
                );
              })}
            </Carousel>
          ) : (
            <div>
              {pullQuoteSlides?.map((pullQuoteSlide: PullQuote, index) => {
                return (
                  <div key={index} className={pullQuoteSingleItem()}>
                    <PullQuoteItem data={pullQuoteSlide} renderingParams={props} key={index} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* Render bottom part of the border */}
      {multiColorBar?.value && componentVariants?.multipleBar?.bottom && (
        <MultiColorBorder
          multipleBar="bottom"
          skewXdeg={componentVariants?.multipleBar?.skewX?.value}
        />
      )}
    </div>
  );
};

export default PullQuote;
