// Global
import React, { useEffect, useRef } from 'react';

// Lib
import { ComponentProps } from 'lib/component-props';
import { CardComponents, SearchComponents } from 'lib/templates/Feature.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import { sendGTMEvent } from '@next/third-parties/google';

export type RelatedArticleCardListingwithSearchProps = ComponentProps &
  SearchComponents.RelatedArticleCardListingWithSearch.RelatedArticleCardListingWithSearch;

export type Badge = ItemEx & CardComponents.ProductCard.Badge;

export const RelatedArticleCardListingwithSearch = (
  props: RelatedArticleCardListingwithSearchProps
): JSX.Element => {
  // Add ref for the GTM Event
  const hasFired = useRef(false);

  useEffect(() => {
    // Function to send the GTM event
    sendGTMEvent({ event: 'switcher', type: 'click', value: 'grid' });
    hasFired.current = true;
  }, []); // Empty dependency array ensures it runs only once on mount

  if (!props?.fields) return <></>;

  // Unique id for component
  const id = props?.params?.RenderingIdentifier;

  return (
    <Container>
      <div
        data-component="layout/productresultslistwithsearch"
        id={id ? id : undefined}
        tabIndex={id ? -1 : 1}
      >
        <div>Hello</div>
      </div>
    </Container>
  );
};

export default RelatedArticleCardListingwithSearch;
