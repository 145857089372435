// Global
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import React, { useEffect, useState, forwardRef, ForwardedRef } from 'react';

// Lib
import { ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';

// Local
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Button from 'helpers/Button/Button';
import SVG from 'helpers/SVG/SVG';
import Video from 'helpers/Video/Video';
import { tailwindconstiants } from './ProductCarouselTailwind';
import { ComponentVariants } from 'lib/context/ComponentVariants';

export type ProductInformationProps =
  ProductAndArticleComponent.ProductInformation.ProductInformation;

export type SecondaryImages = ProductAndArticleComponent.ProductInformation.SecondaryImage &
  ProductAndArticleComponent.ProductInformation.SecondaryVideo;

interface productCarouselProps {
  mainSliderRef?: React.RefObject<Splide>;
  thumbnailSliderRef?: React.RefObject<Splide>;
  props?: ProductInformationProps;
  mainSlider?: Options;
  thumbSlider?: Options;
}

type Props = productCarouselProps;

const ProductCarousel = forwardRef(
  (
    { mainSliderRef, thumbnailSliderRef, props, mainSlider, thumbSlider }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const { primaryImage, secondaryImages } = props?.fields || {};
    const [progressWidth, setProgressWidth] = useState('0%');
    const [firstSlideActive, setFirstSlideActive] = useState<boolean>(true);
    const [lastSlideActive, setLastSlideActive] = useState<boolean>(false);

    useEffect(() => {
      if (mainSliderRef?.current && thumbnailSliderRef?.current) {
        mainSliderRef.current.sync(thumbnailSliderRef?.current?.splide as never);
      }
    }, [mainSliderRef, thumbnailSliderRef]);

    const componentVariants = ComponentVariants();

    const {
      ProductCarousel,
      productInformationTopSlider,
      productInformationBottomSlider,
      productInformationProgressBar,
      productInformationProgress,
      productInformationGradient,
      productRoundedImages,
      productInformationImages,
      productInformationVideoThumbnail,
      productInformationProgressbarWrapper,
      productInformationSplideControls,
      productInformationVideo,
      btn,
    } = tailwindconstiants({});

    return (
      <div className={ProductCarousel()} ref={ref}>
        <Splide
          ref={mainSliderRef}
          options={mainSlider}
          className={productInformationTopSlider()}
          onMounted={(splide) => {
            const end = splide?.Components?.Controller?.getEnd() + 1;
            const rate = Math.min((splide?.index + 1) / end, 1);
            setProgressWidth(`${100 * rate}%`);
            splide?.index === 0 ? setFirstSlideActive(true) : setFirstSlideActive(false);
            splide?.index === end - 1 ? setLastSlideActive(true) : setLastSlideActive(false);
          }}
          onMove={(splide) => {
            const end = splide?.Components?.Controller?.getEnd() + 1;
            const rate = Math.min((splide?.index + 1) / end, 1);
            setProgressWidth(`${100 * rate}%`);
            splide?.index === 0 ? setFirstSlideActive(true) : setFirstSlideActive(false);
            splide?.index === end - 1 ? setLastSlideActive(true) : setLastSlideActive(false);
          }}
        >
          {primaryImage && primaryImage?.value && (
            <SplideSlide>
              <ImageWrapper field={primaryImage} className={productRoundedImages()}></ImageWrapper>
            </SplideSlide>
          )}

          {secondaryImages?.map((item: SecondaryImages, index: number) =>
            item?.fields?.videoId?.value ? (
              <SplideSlide key={index}>
                <Video field={item?.fields?.videoId} class={productInformationVideo()} />
              </SplideSlide>
            ) : (
              item?.fields?.image && (
                <SplideSlide key={index}>
                  <ImageWrapper
                    field={item?.fields?.image}
                    className={productRoundedImages()}
                  ></ImageWrapper>
                </SplideSlide>
              )
            )
          )}
        </Splide>
        {secondaryImages && secondaryImages.length >= 1 && (
          <>
            <Splide
              ref={thumbnailSliderRef}
              options={thumbSlider}
              className={productInformationBottomSlider()}
            >
              {primaryImage && primaryImage?.value && (
                <SplideSlide>
                  <ImageWrapper
                    field={primaryImage}
                    className={productInformationImages()}
                  ></ImageWrapper>
                </SplideSlide>
              )}
              {secondaryImages &&
                secondaryImages?.map((item: SecondaryImages, index: number) => {
                  if (
                    item?.fields?.videoId?.value &&
                    item?.fields?.videoThumbnail?.value &&
                    !item?.fields?.videoThumbnail?.value.src
                  ) {
                    item.fields.videoThumbnail.value.src = `https://img.youtube.com/vi/${item.fields?.videoId?.value}/hqdefault.jpg`;
                  }

                  return item?.fields?.videoId ? (
                    <SplideSlide key={index}>
                      <ImageWrapper
                        field={item?.fields?.videoThumbnail}
                        layout="fill"
                        className={productInformationImages()}
                      ></ImageWrapper>
                      <div className={productInformationGradient()}></div>
                      <SVG
                        className={productInformationVideoThumbnail()}
                        svg={`play-icon/playicon`}
                      />
                    </SplideSlide>
                  ) : (
                    item?.fields?.image && (
                      <SplideSlide key={index}>
                        <ImageWrapper
                          field={item?.fields?.image}
                          className={productInformationImages()}
                        ></ImageWrapper>
                      </SplideSlide>
                    )
                  );
                })}
            </Splide>
            <div className={productInformationProgressbarWrapper()}>
              <div className={productInformationProgressBar()}>
                <div
                  className={productInformationProgress()}
                  style={{ width: progressWidth }}
                ></div>
              </div>
              <div className={productInformationSplideControls()}>
                <div className={`${btn()}`}>
                  <Button
                    onClick={() => mainSliderRef?.current?.splide?.go('-1')}
                    color={componentVariants?.productCarousel?.navigationBtnColor || 'brand'}
                    type={'outline'}
                    iconLeft="chevron_left"
                    disabled={firstSlideActive}
                    title="Previous"
                  ></Button>
                </div>
                <div className={`${btn()} ${lastSlideActive ? '[&>*]:disable' : ''}`}>
                  <Button
                    onClick={() => mainSliderRef?.current?.splide?.go('+1')}
                    color={componentVariants?.productCarousel?.navigationBtnColor || 'brand'}
                    type={'outline'}
                    iconLeft="chevron_right"
                    disabled={lastSlideActive}
                    title="Next"
                  ></Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default ProductCarousel;

ProductCarousel.displayName = 'ProductCarousel';
